import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { authApi } from './features/auth/authApi';
import { lendaApi } from './features/lenda/lendaApi';
import { universityApi } from './features/universiteti/universityApi';
import { profiliApi } from './features/profili/profiliApi';
import { profili_viti_konkurrimitApi } from './features/profili-viti-konkurrimit/profili-viti-konkurrimitApi';
import { konkurrentiApi } from './features/konkurrenti/konkurrenti';
import { verifikimeApi } from './features/konkurrenti/verifikime';
import { vitiAkademikApi } from './features/vitiKonkurrimit/vitiAkademikApi';
import { kontrolloPerfundimRegjistrimeApi } from './features/profili-viti-konkurrimit/kontrollo-perfundim-regjistrimeApi';
import { fazaApi } from './features/fazat/fazaApi';
import { trajnimetApi } from './features/trajnimet/trajnimet-api';
import { rankimetApi } from './features/rankimet/rankimetApi';
import { studentiApi } from './features/studenti/studentiApi';
import { institucioniApi } from './features/institucioni/institucioni-api';
import { pedagoguApi } from './features/pedagoget/pedagogetApi';
import { klasaApi } from './features/klasa/klasa-api';
import { orariMesimorApi } from './features/orariMesimor/orariMesimorAPi';
import { lendeVitiAkademikApi } from './features/lenda/lende-viti-akademikApi';
import { pjesmarresApi } from './features/pjesemarres/pjesemarres-api';
import { trajnuesApi } from './features/trajnues/trajnues-api';
import { evidencaApi } from './features/evidenca/evidencaApi';
import { listePrezencaApi } from './features/liste-prezenca/listePrezencaApi';
import { processShmApi } from './features/proces-shm/process-shm-api';
import { dokumentTrajnimiApi } from './features/dokument-trajnimi/dokument-trajnimi-api';
import { detyraStudentApi } from './features/detyra/detyra-student';
import { vleresimeApi } from './features/vleresimi/vleresimiApi';
import { pjesmarresNeTrajnimeApi } from './features/pjesemarres-ne-trajnime/pjesemarres-ne-trajnime-api';
import { trajnimPrezencaApi } from './features/trajnim-prezenca/trajnim-prezenca-api';
import { lendeGjyqApi } from './features/lenda/lende-gjyqApi';
import { auditApi } from './features/auth/auditApi';
import { reportsApi } from './features/reports/reports';
import { vleresimeGjyqApi } from './features/vleresimi/vleresimiGjyqApi';
import { vleresimeKlinikaApi } from './features/vleresimi/vleresimiKlinikaApi';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      universityApi.middleware,
      profiliApi.middleware,
      profili_viti_konkurrimitApi.middleware,
      lendaApi.middleware,
      konkurrentiApi.middleware,
      verifikimeApi.middleware,
      vitiAkademikApi.middleware,
      kontrolloPerfundimRegjistrimeApi.middleware,
      fazaApi.middleware,
      trajnimetApi.middleware,
      rankimetApi.middleware,
      studentiApi.middleware,
      institucioniApi.middleware,
      pedagoguApi.middleware,
      klasaApi.middleware,
      orariMesimorApi.middleware,
      lendeVitiAkademikApi.middleware,
      pjesmarresApi.middleware,
      trajnuesApi.middleware,
      evidencaApi.middleware,
      listePrezencaApi.middleware,
      processShmApi.middleware,
      dokumentTrajnimiApi.middleware,
      vleresimeApi.middleware,
      pjesmarresNeTrajnimeApi.middleware,
      trajnimPrezencaApi.middleware,
      detyraStudentApi.middleware,
      lendeGjyqApi.middleware,
      auditApi.middleware,
      reportsApi.middleware,
      vleresimeGjyqApi.middleware,
      vleresimeKlinikaApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
