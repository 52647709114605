import { VitiStudimit } from '../../../constants/enums/vitiStudimit';
import { LendeGjyqKategoria } from '../../../utilities/enums';

export const getVitiStudimitLabel = (viti?: VitiStudimit): string => {
  switch (viti) {
    case VitiStudimit.vitiPare:
      return 'Viti I';
    case VitiStudimit.vitiDyte:
      return 'Viti II';
    case VitiStudimit.vitiTrete:
      return 'Viti III';
    case VitiStudimit.diplomuar:
      return 'Diplomuar';
    default:
      return '';
  }
};

export const getKategoriaLendesLabel = (
  kategoria: LendeGjyqKategoria,
): string => {
  switch (kategoria) {
    case LendeGjyqKategoria.gjyqCivil:
      return 'Gjyq Civil';
    case LendeGjyqKategoria.gjyqPenal:
      return 'Gjyq Penal';
    case LendeGjyqKategoria.klinikeAdministrative:
      return 'Klinikë Administrative';
    case LendeGjyqKategoria.klinikeCivile:
      return 'Klinikë Civile';
    case LendeGjyqKategoria.klinikeFamiljare:
      return 'Klinikë Familjare';
    case LendeGjyqKategoria.klinikeKushtetuese:
      return 'Klinikë Kushtetuese';
    case LendeGjyqKategoria.klinikePenale:
      return 'Klinikë Penale';
    default:
      return '';
  }
};
