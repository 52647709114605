import { UserForPedagogProps } from '../../../../interfaces/Auth';
import { PedagoguDetailsProps } from '../../../../interfaces/pedagoget-interface';

export const getPedagoguUrl = (
  params: PedagoguDetailsProps,
  url = 'pedagog/simplified',
) => {
  const { size, name, page, llojiPedagog } = params;
  let queryString = `/${url}?size=${size}&page=${page}&sort=personiEmri:asc`;

  if (name) {
    queryString += `&filter=personiEmri:ilike:${name}`;
  }
  if (llojiPedagog) {
    queryString += `&filter=pedagogLloji:eq:${llojiPedagog}`;
  }

  return queryString;
};

export const getPerdoruesPerPedagog = (params: UserForPedagogProps) => {
  const { size, page, emri } = params;
  let queryString = `/perdorues/per-pedagog?size=${size}&page=${page}&sort=emriPersoni:asc`;

  if (emri) {
    queryString += `&filter=emriPersoni:ilike:${emri}`;
  }

  return queryString;
};
