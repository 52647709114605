import React from 'react';
import { resetPasswordFields } from '../../constants/form-fields/reset-password-fields';
import FormFieldRender from '../FormRender/FormFieldRender';

type WidthType = {
  width: string;
};

export default function ResetPasswordRender({ width }: WidthType) {
  return (
    <div className={`flex ${width} flex-col`}>
      {resetPasswordFields.map((data, i) => {
        return (
          <React.Fragment key={i}>
            <div className="input-with-placeholder my-3 border">
              <span
                className="form-item-label font-sans text-sm font-medium tracking-wide"
                style={{
                  color: '#003666',
                }}
              >
                {data.label}
              </span>
              <FormFieldRender data={data} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
