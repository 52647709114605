import { UsersParams } from '../../../../redux/features/auth/authApi';

export const getUsersUrl = (params: UsersParams) => {
  const { email, aktiv, page, size, sort } = params;
  let queryString = `/perdorues?size=${size}&page=${page}&sort=emriPersoni:asc`;

  if (aktiv !== undefined) {
    queryString += `&filter=aktiv:eq:${aktiv}`;
  }
  if (email) {
    queryString += `&filter=emriPersoni:ilike:${email}`;
  }
  if (sort) {
    queryString += `&sort=dataKrijimit:${sort}`;
  }

  return queryString;
};
