import React, { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import { tokenExpiry } from './helpers/getUserInfo';
import { Sidebar } from './layouts/Sidebar/Sidebar';
import { useDispatch } from 'react-redux';
import { konkurrentiApi } from './redux/features/konkurrenti/konkurrenti';
import { authApi } from './redux/features/auth/authApi';
import { universityApi } from './redux/features/universiteti/universityApi';
import { profiliApi } from './redux/features/profili/profiliApi';
import { profili_viti_konkurrimitApi } from './redux/features/profili-viti-konkurrimit/profili-viti-konkurrimitApi';
import { lendaApi } from './redux/features/lenda/lendaApi';
import { verifikimeApi } from './redux/features/konkurrenti/verifikime';
import { kontrolloPerfundimRegjistrimeApi } from './redux/features/profili-viti-konkurrimit/kontrollo-perfundim-regjistrimeApi';
import { fazaApi } from './redux/features/fazat/fazaApi';
import { trajnimetApi } from './redux/features/trajnimet/trajnimet-api';
import { rankimetApi } from './redux/features/rankimet/rankimetApi';
import { vitiAkademikApi } from './redux/features/vitiKonkurrimit/vitiAkademikApi';
import { lendeVitiAkademikApi } from './redux/features/lenda/lende-viti-akademikApi';
import { klasaApi } from './redux/features/klasa/klasa-api';
import { orariMesimorApi } from './redux/features/orariMesimor/orariMesimorAPi';
import { setActiveUser } from './redux/features/activeUser/activeUser';
import { evidencaApi } from './redux/features/evidenca/evidencaApi';
import { processShmApi } from './redux/features/proces-shm/process-shm-api';
import { dokumentTrajnimiApi } from './redux/features/dokument-trajnimi/dokument-trajnimi-api';
import { trajnuesApi } from './redux/features/trajnues/trajnues-api';
import { pjesmarresApi } from './redux/features/pjesemarres/pjesemarres-api';
import { vleresimeApi } from './redux/features/vleresimi/vleresimiApi';
import { trajnimPrezencaApi } from './redux/features/trajnim-prezenca/trajnim-prezenca-api';
import { pjesmarresNeTrajnimeApi } from './redux/features/pjesemarres-ne-trajnime/pjesemarres-ne-trajnime-api';
import { detyraStudentApi } from './redux/features/detyra/detyra-student';
import { lendeGjyqApi } from './redux/features/lenda/lende-gjyqApi';
import { auditApi } from './redux/features/auth/auditApi';
import { vleresimeGjyqApi } from './redux/features/vleresimi/vleresimiGjyqApi';
import { reportsApi } from './redux/features/reports/reports';
import { vleresimeKlinikaApi } from './redux/features/vleresimi/vleresimiKlinikaApi';

function App() {
  const navigate = useNavigate();
  const expire = tokenExpiry();
  const renderRoutes = useRoutes(routes);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (expire! * 1000 < new Date().getTime()) {
      localStorage.clear();
      dispatch(konkurrentiApi.util.resetApiState());
      dispatch(authApi.util.resetApiState());
      dispatch(universityApi.util.resetApiState());
      dispatch(profiliApi.util.resetApiState());
      dispatch(profili_viti_konkurrimitApi.util.resetApiState());
      dispatch(lendaApi.util.resetApiState());
      dispatch(verifikimeApi.util.resetApiState());
      dispatch(kontrolloPerfundimRegjistrimeApi.util.resetApiState());
      dispatch(fazaApi.util.resetApiState());
      dispatch(trajnimetApi.util.resetApiState());
      dispatch(rankimetApi.util.resetApiState());
      dispatch(vitiAkademikApi.util.resetApiState());
      dispatch(lendeVitiAkademikApi.util.resetApiState());
      dispatch(klasaApi.util.resetApiState());
      dispatch(orariMesimorApi.util.resetApiState());
      dispatch(evidencaApi.util.resetApiState());
      dispatch(processShmApi.util.resetApiState());
      dispatch(dokumentTrajnimiApi.util.resetApiState());
      dispatch(trajnuesApi.util.resetApiState());
      dispatch(pjesmarresApi.util.resetApiState());
      dispatch(vleresimeApi.util.resetApiState());
      dispatch(pjesmarresNeTrajnimeApi.util.resetApiState());
      dispatch(trajnimPrezencaApi.util.resetApiState());
      dispatch(detyraStudentApi.util.resetApiState());
      dispatch(lendeGjyqApi.util.resetApiState());
      dispatch(auditApi.util.resetApiState());
      dispatch(reportsApi.util.resetApiState());
      dispatch(vleresimeGjyqApi.util.resetApiState());
      dispatch(vleresimeKlinikaApi.util.resetApiState());
      navigate('/login');
      dispatch(setActiveUser(''));
    }
  }, [window.location.pathname]);

  return (
    <div className="App">
      <Sidebar>{renderRoutes}</Sidebar>
    </div>
  );
}

export default App;
