import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { test } = getConfig();

export const kontrolloPerfundimRegjistrimeApi = createApi({
  reducerPath: 'kontrollo_perfundim_regjistrime',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
  }),
  tagTypes: ['kontrolloRegjistrime'],
  endpoints: builder => ({
    kontrolloPerfundimRegjistrime: builder.query<boolean, void>({
      query: () => ({
        url: `/viti-konkurrimit/kontrollo-perfundim-regjistrime`,
        method: 'GET',
      }),
      providesTags: ['kontrolloRegjistrime'],
    }),
  }),
});

export const { useKontrolloPerfundimRegjistrimeQuery } =
  kontrolloPerfundimRegjistrimeApi;
