import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  KonkurrentiData,
  KonkurrentiAplicationData,
  KonkurrentiDataActive,
  PranoAplikiminProps,
  UpdateSpecifikaProps,
  CreateVerifikimeProps,
  KonkurrentiAktualVerifikime,
  VerifikimPermbledhesReturn,
  UpdateVerifikimeProps,
} from '../../../interfaces/konkurrenti-interface';
import { getKonkurrimeUrl } from '../../../components/common/common-functions/commonApiUrl/get-konkurrime-url';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { RezultateFazaProps } from '../../../interfaces/fazat-interface';
import { getPrepareHeaders } from '../get-prepare-headers';
import { UpdateProps } from '../../../interfaces/common-interfaces';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';
const config = getConfig();

const { test } = config;

export interface KonkurrentiParams extends PaginationProps {
  personiEmri: string;
  statusKonkurrent: string;
  vitiKonkurrimitId: number;
  sort?: 'asc' | 'desc';
}

export interface DocumentsProps {
  id: number;
  llojiDokumentKandidati: string;
  dokument: {
    id: number;
    emri: string;
    data: string;
  };
}

export const konkurrentiApi = createApi({
  reducerPath: 'konkurrenti',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: [
    'Konkurrenti',
    'GetAllKonkurrenti',
    'konkurimeAktive',
    'konkurimeHistorike',
    'konkurrentiAktual',
    'Dokumente',
  ],
  endpoints: builder => ({
    getKonkurimeAktive: builder.query<
      PaginationReturn<KonkurrentiData>,
      KonkurrentiParams
    >({
      query: args => ({
        url: getKonkurrimeUrl(args, 'lista-aktiv'),
        method: 'GET',
      }),
      providesTags: ['konkurimeAktive', 'GetAllKonkurrenti'],
    }),
    getKonkurimeHistorike: builder.query<
      PaginationReturn<KonkurrentiData>,
      KonkurrentiParams
    >({
      query: args => ({
        url: getKonkurrimeUrl(args, 'historik'),
        method: 'GET',
      }),
      providesTags: ['konkurimeHistorike', 'GetAllKonkurrenti'],
    }),
    getActiveKonkurrenti: builder.query<KonkurrentiDataActive, void>({
      query: () => ({
        url: `/konkurrenti/aktual`,
        method: 'GET',
      }),
      providesTags: ['GetAllKonkurrenti', 'konkurimeAktive'],
    }),
    getKonkurrentiVerifikime: builder.query<KonkurrentiAktualVerifikime, void>({
      query: () => ({
        url: `/konkurrenti/aktual/verifikime`,
        method: 'GET',
      }),
      providesTags: ['GetAllKonkurrenti', 'konkurimeAktive'],
    }),
    getSingleKonkurrenti: builder.query<KonkurrentiData, number>({
      query: id => ({
        url: `/konkurrenti/${id}`,
        method: 'GET',
      }),
      providesTags: ['Konkurrenti'],
    }),
    getKonkurrentiAktual: builder.query<RezultateFazaProps, void>({
      query: () => ({
        url: `/rezultate-faza/konkurrenti-aktual`,
        method: 'GET',
      }),
      providesTags: ['konkurrentiAktual'],
    }),
    saveKonkurrentiAplication: builder.mutation<
      number,
      KonkurrentiAplicationData
    >({
      query: data => ({
        url: `/konkurrenti`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'Konkurrenti',
        'GetAllKonkurrenti',
        'konkurimeAktive',
        'konkurimeHistorike',
      ],
    }),
    dorezoAplikimin: builder.mutation<void, number>({
      query: data => ({
        url: '/konkurrenti/dorezo-aplikimin',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti'],
    }),
    updateAplication: builder.mutation<void, KonkurrentiAplicationData>({
      query: data => ({
        url: '/konkurrenti/perditeso-specifika',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    pranoAplikim: builder.mutation<number, PranoAplikiminProps>({
      query: data => {
        const { id, komenti, statusKonkurrent } = data;
        return {
          url: `konkurrenti/prano-aplikim/${id}`,
          method: 'PUT',
          body: { komenti, statusKonkurrent },
        };
      },
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    perditesoSpecifikaPerKonkurrent: builder.mutation<
      number,
      UpdateSpecifikaProps
    >({
      query: args => ({
        url: `/konkurrenti/perditeso-specifika-per-konkurrent/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    createVerifikime: builder.mutation<
      number,
      UpdateProps<CreateVerifikimeProps>
    >({
      query: args => ({
        url: `/verifikimi/${args.id}`,
        method: 'POST',
        body: args.data,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    updateVerifikimeStatus: builder.mutation<number, UpdateVerifikimeProps>({
      query: args => ({
        url: `/verifikimi/perditeso-status/${args.id}?status=${args.status}&koment=${args.koment}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    mbyllAplikime: builder.mutation({
      query: () => ({
        url: '/faza/mbyll-verifikime',
        method: 'POST',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti', 'konkurimeAktive'],
    }),
    shtoVerifikimPermbledhes: builder.mutation<
      number,
      VerifikimPermbledhesReturn
    >({
      query: args => ({
        url: `/konkurrenti/shto-verifikim-permbledhes/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['GetAllKonkurrenti', 'Konkurrenti'],
    }),
    getAllDocuments: builder.query<PaginationReturn<DocumentsProps>, void>({
      query: () => ({
        url: '/konkurrenti/dokument?page=0&size=50',
        method: 'GET',
      }),
      providesTags: ['Dokumente'],
    }),
    getAllDocumentsAdmin: builder.query<
      PaginationReturn<DocumentsProps>,
      number
    >({
      query: id => ({
        url: `/konkurrenti/dokumente-konkurrenti/${id}?size=500&page=0`,
        method: 'GET',
      }),
      providesTags: ['Dokumente'],
    }),
    getDocumentById: builder.query<
      Blob,
      { dokumentKandidatiPerKonkurimId: number }
    >({
      query: args => ({
        url: `/konkurrenti/dokument/${args.dokumentKandidatiPerKonkurimId}`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['Dokumente'],
    }),
    getDocumentAdminById: builder.query<
      Blob,
      { dokumentKandidatiPerKonkurimId: number }
    >({
      query: args => ({
        url: `/konkurrenti/dokumente-konkurrenti/dokument/${args.dokumentKandidatiPerKonkurimId}`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['Dokumente'],
    }),
    uploadNewDocument: builder.mutation<void, unknown>({
      query: body => ({
        url: '/konkurrenti/dokument',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Dokumente'],
    }),
    deleteDocumentById: builder.mutation<number, unknown>({
      query: id => ({
        url: `/konkurrenti/dokument/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Dokumente'],
    }),
  }),
});
export const {
  useGetActiveKonkurrentiQuery,
  useGetKonkurrentiVerifikimeQuery,
  useGetSingleKonkurrentiQuery,
  useGetKonkurimeAktiveQuery,
  useGetKonkurimeHistorikeQuery,
  useGetKonkurrentiAktualQuery,
  useSaveKonkurrentiAplicationMutation,
  useDorezoAplikiminMutation,
  useUpdateAplicationMutation,
  usePranoAplikimMutation,
  usePerditesoSpecifikaPerKonkurrentMutation,
  useCreateVerifikimeMutation,
  useUpdateVerifikimeStatusMutation,
  useMbyllAplikimeMutation,
  useShtoVerifikimPermbledhesMutation,
  useGetAllDocumentsQuery,
  useGetAllDocumentsAdminQuery,
  useLazyGetDocumentByIdQuery,
  useLazyGetDocumentAdminByIdQuery,
  useUploadNewDocumentMutation,
  useDeleteDocumentByIdMutation,
} = konkurrentiApi;
