import { DetyraParams } from '../../../../interfaces/detyra-interface';

export const getDetyratUrl = (
  params: DetyraParams,
  url = `/detyra-student/${params.id}/sipas-detyres`,
) => {
  const { size, page, studentiId, klasaId, dorezuarNeKohe, studentEmri } =
    params;
  let queryString = `${url}?size=${size}&page=${page}`;
  if (studentEmri) {
    queryString += `&filter=studentEmri:ilike:${studentEmri}`;
  }
  if (studentiId) {
    queryString += `&filter=studentiId:eq:${studentiId}`;
  }
  if (klasaId) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (dorezuarNeKohe !== undefined) {
    queryString += `&filter=dorezuarNeKohe:eq:${dorezuarNeKohe}`;
  }

  return queryString;
};
