import React from 'react';
import { Button, Form } from 'antd';
import { useResetPasswordMutation } from '../../redux/features/auth/authApi';
import { Fjalekalimi } from '../../interfaces/Auth';
import '../../styles/index.css';
import {
  NotificationError,
  NotificationSucces,
} from '../common/common-componnets/Notifications';
import ResetPasswordRender from './ResetPasswordRender';

const RessetPassword = () => {
  const [resetPassword] = useResetPasswordMutation();
  const handleSubmit = ({ fjalekalimiAktual, fjalekalimiRi }: Fjalekalimi) => {
    resetPassword({
      fjalekalimiAktual,
      fjalekalimiRi,
    })
      .unwrap()
      .then(() => {
        NotificationSucces('Fjalekalimi u ndryshua me sukses');
      })
      .catch(error => {
        NotificationError(error.data.message);
      });
  };

  return (
    <Form
      requiredMark={false}
      className="m-3"
      name="resetPassword"
      onFinish={handleSubmit}
      layout="vertical"
    >
      <ResetPasswordRender width="w-[100%]" />
      <div className="flex justify-end">
        <Button
          name="Resset Password"
          className="submitButton block h-11 w-full rounded text-base font-semibold text-white"
          htmlType="submit"
          style={{ backgroundColor: '#FF731D' }}
        >
          Ruaj
        </Button>
      </div>
    </Form>
  );
};

export default RessetPassword;
