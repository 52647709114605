import React from 'react';
import { Form, Input } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const InputTextArea = ({
  name,
  rules,
  placeholder,
  otherprops,
  disabled,
  value,
  autoSize,
  defaultValue,
}: FormField) => {
  return (
    <Form.Item name={name} rules={rules}>
      <Input.TextArea
        placeholder={placeholder}
        {...otherprops}
        disabled={disabled}
        value={value}
        rows={otherprops.rows || 4}
        autoSize={autoSize}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );
};

export default InputTextArea;
