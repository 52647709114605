import React from 'react';
import { notification } from 'antd';

export function NotificationSucces(message: string) {
  return notification.success({
    message: message,
    placement: 'bottomRight',
  });
}

export function NotificationError(message: string) {
  return notification.error({
    message: message,
    placement: 'bottomRight',
  });
}

export function NotificationInfo(message: string) {
  return notification.info({
    message: message,
    placement: 'bottomRight',
  });
}
