import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UniversityData } from '../../../interfaces/university-interface';
import { getUniversityUrl } from '../../../components/common/common-functions/commonApiUrl/get-university-url';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';

const { test } = getConfig();

export interface ParamsProps extends Partial<PaginationProps> {
  emri?: string;
  aprovuar?: boolean;
  llojiUniversiteti?: string;
}
export const universityApi = createApi({
  reducerPath: 'universiteti',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['University', 'GetAllUniversities'],
  endpoints: builder => ({
    getAllUniversities: builder.query<
      PaginationReturn<UniversityData>,
      ParamsProps
    >({
      query: args => getUniversityUrl(args),
      providesTags: ['GetAllUniversities'],
    }),
    createUniversity: builder.mutation<{ id: number }, UniversityData>({
      query: data => ({
        url: `/universiteti`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllUniversities'],
    }),
    getSingleUniversity: builder.query<UniversityData, { id: number }>({
      query: id => ({
        url: `/universiteti/${id}`,
        method: 'GET',
      }),
      providesTags: ['University'],
    }),
    updateUniversity: builder.mutation<
      { id: number },
      { id: number; data: UniversityData }
    >({
      query: args => ({
        url: `/universiteti/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['GetAllUniversities', 'University'],
    }),
    deleteUniversity: builder.mutation<string, number>({
      query: id => ({
        url: `/universiteti/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllUniversities', 'University'],
    }),
  }),
});

export const {
  useGetAllUniversitiesQuery,
  useLazyGetAllUniversitiesQuery,
  useCreateUniversityMutation,
  useUpdateUniversityMutation,
  useLazyGetSingleUniversityQuery,
  useDeleteUniversityMutation,
} = universityApi;
