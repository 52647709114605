import { Spin } from 'antd';
import React, { Suspense, useMemo } from 'react';
import routesWithPermissions from '../../../routes/routesWithPermissions';
import { useSelector } from 'react-redux';
import { UserStateProps } from '../../../interfaces/modules.interface';
import { ROLES } from '../../../constants/enums/roles';
import { getUserRole } from '../../../helpers/getUserInfo';

interface CostumLoadableProps {
  loader: any;
  path: string;
}

export default function CustomLoadable({ loader, path }: CostumLoadableProps) {
  const selectorRole = useSelector((state: UserStateProps) => state.activeUser);
  const userRole = getUserRole();

  const role = useMemo(
    () =>
      (selectorRole.length > 0
        ? selectorRole
        : (localStorage.getItem('activeUser') as ROLES | undefined)) ??
      userRole,
    [selectorRole, userRole],
  );

  const routePermissions = routesWithPermissions[path];
  const LazyComponent = React.lazy(loader);

  const isChildren = useMemo(
    () =>
      (role && routePermissions.includes(role)) || routePermissions.length == 0,
    [role, routePermissions],
  );

  return (
    <Suspense
      fallback={
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(30, 34, 40, 0.4)',
          }}
        >
          <div className="loader" key={0}>
            <Spin />
          </div>
        </div>
      }
    >
      {isChildren ? <LazyComponent /> : <div>not allowed</div>}
    </Suspense>
  );
}
