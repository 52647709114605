import React, { useMemo, useState } from 'react';
import { Avatar, Space, Dropdown, MenuProps } from 'antd';
import { ReactComponent as Vector } from '../../assets/svgIcons/vector.svg';
import { useNavigate } from 'react-router';
import SettingsModal from '../SettingsModal/SettingsModal';
import {
  authApi,
  useGetLoggedInUserQuery,
} from '../../redux/features/auth/authApi';
import { konkurrentiApi } from '../../redux/features/konkurrenti/konkurrenti';
import { useDispatch, useSelector } from 'react-redux';
import { profiliApi } from '../../redux/features/profili/profiliApi';
import { universityApi } from '../../redux/features/universiteti/universityApi';
import { profili_viti_konkurrimitApi } from '../../redux/features/profili-viti-konkurrimit/profili-viti-konkurrimitApi';
import { lendaApi } from '../../redux/features/lenda/lendaApi';
import { verifikimeApi } from '../../redux/features/konkurrenti/verifikime';
import { vitiAkademikApi } from '../../redux/features/vitiKonkurrimit/vitiAkademikApi';
import { kontrolloPerfundimRegjistrimeApi } from '../../redux/features/profili-viti-konkurrimit/kontrollo-perfundim-regjistrimeApi';
import { fazaApi } from '../../redux/features/fazat/fazaApi';
import { trajnimetApi } from '../../redux/features/trajnimet/trajnimet-api';
import { rankimetApi } from '../../redux/features/rankimet/rankimetApi';
import { getModuleByRole } from '../../helpers/get-module-by-role';
import { setActiveUser } from '../../redux/features/activeUser/activeUser';
import { UserStateProps } from '../../interfaces/modules.interface';
import { ROLES } from '../../constants/enums/roles';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { lendeVitiAkademikApi } from '../../redux/features/lenda/lende-viti-akademikApi';
import { klasaApi } from '../../redux/features/klasa/klasa-api';
import { orariMesimorApi } from '../../redux/features/orariMesimor/orariMesimorAPi';
import { listePrezencaApi } from '../../redux/features/liste-prezenca/listePrezencaApi';
import { evidencaApi } from '../../redux/features/evidenca/evidencaApi';
import { vleresimeApi } from '../../redux/features/vleresimi/vleresimiApi';
import { detyraStudentApi } from '../../redux/features/detyra/detyra-student';
import { lendeGjyqApi } from '../../redux/features/lenda/lende-gjyqApi';
import { auditApi } from '../../redux/features/auth/auditApi';
import { reportsApi } from '../../redux/features/reports/reports';
import { vleresimeGjyqApi } from '../../redux/features/vleresimi/vleresimiGjyqApi';
import { vleresimeKlinikaApi } from '../../redux/features/vleresimi/vleresimiKlinikaApi';

const Account = () => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectorRole = useSelector((state: UserStateProps) => state.activeUser);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const modules = getModuleByRole(loggedInUser?.rolet ?? []);

  const handleSignOut = () => {
    localStorage.clear();
    dispatch(konkurrentiApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(universityApi.util.resetApiState());
    dispatch(profiliApi.util.resetApiState());
    dispatch(profili_viti_konkurrimitApi.util.resetApiState());
    dispatch(lendaApi.util.resetApiState());
    dispatch(verifikimeApi.util.resetApiState());
    dispatch(kontrolloPerfundimRegjistrimeApi.util.resetApiState());
    dispatch(fazaApi.util.resetApiState());
    dispatch(trajnimetApi.util.resetApiState());
    dispatch(rankimetApi.util.resetApiState());
    dispatch(vitiAkademikApi.util.resetApiState());
    dispatch(lendeVitiAkademikApi.util.resetApiState());
    dispatch(klasaApi.util.resetApiState());
    dispatch(orariMesimorApi.util.resetApiState());
    dispatch(listePrezencaApi.util.resetApiState());
    dispatch(evidencaApi.util.resetApiState());
    dispatch(vleresimeApi.util.resetApiState());
    dispatch(detyraStudentApi.util.resetApiState());
    dispatch(lendeGjyqApi.util.resetApiState());
    dispatch(auditApi.util.resetApiState());
    dispatch(reportsApi.util.resetApiState());
    dispatch(vleresimeGjyqApi.util.resetApiState());
    dispatch(vleresimeKlinikaApi.util.resetApiState());
    navigate('/login');
    dispatch(setActiveUser(''));
  };

  const moduleAttributes: MenuProps['items'] = useMemo(
    () =>
      modules
        .filter(module => module.activeRole)
        .map((item, index) => ({
          label: (
            <div
              onClick={() => {
                dispatch(setActiveUser(item.activeRole ?? ''));
                localStorage.setItem('activeUser', item.activeRole ?? '');
              }}
              style={{
                backgroundColor: item.bgColor,
                color: item.color,
                borderRadius: 5,
              }}
              className="flex h-full w-full items-center p-2"
            >
              {item.label}
            </div>
          ),
          key: `${item.activeRole}${index}`,
        })),
    [modules],
  );

  const role = useMemo(
    () =>
      selectorRole.length > 0
        ? selectorRole
        : (localStorage.getItem('activeUser') as ROLES | undefined),
    [selectorRole],
  );

  const switchRoles = useMemo(() => {
    if (role) {
      return [
        {
          label: (
            <a
              href="#"
              className="flex items-center gap-1"
              onClick={e => {
                e.stopPropagation();
                setShowMenuItems(state => !state);
              }}
            >
              <p>Ndryshoni modulin</p>
              {showMenuItems ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </a>
          ),
          key: '4',
        },
      ];
    }
    return [];
  }, [selectorRole, showMenuItems]);

  const accountSettings: MenuProps['items'] = [
    {
      label: <a onClick={() => setOpenSettingsModal(true)}>Account settings</a>,
      key: '2',
    },
    ...switchRoles,
    ...(showMenuItems ? moduleAttributes : []),
    {
      type: 'divider',
    },
  ];

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="grid">
          <p className="font-normal">Signed in as</p>
          <p className="font-medium">{loggedInUser?.email}</p>
        </div>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    ...(!loggedInUser?.firstLogIn ? accountSettings : []),
    {
      label: <a onClick={handleSignOut}>Sign out</a>,
      key: '5',
    },
  ];

  return (
    <>
      {openSettingsModal && (
        <SettingsModal
          openSettingsModal={openSettingsModal}
          setOpenSettingsModal={setOpenSettingsModal}
        />
      )}
      <div className="flex flex-wrap items-center">
        <Avatar
          style={{ backgroundColor: '#f56a00' }}
          size="large"
          className="mr-4"
        >
          {loggedInUser?.personi &&
            loggedInUser?.personi?.emri?.charAt(0).toLocaleUpperCase() +
              loggedInUser?.personi?.mbiemri?.charAt(0).toUpperCase()}
        </Avatar>
        <div className="mr-4">
          <a className="text-base font-normal ">
            {loggedInUser?.personi.emri} {loggedInUser?.personi.mbiemri}
          </a>
        </div>

        <div className="flex items-center gap-2">
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            className="flex justify-center"
          >
            <Space>
              <a onClick={e => e.preventDefault()}>
                <Vector />
              </a>
            </Space>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Account;
