import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaginationReturn } from '../../../interfaces/pagination';
import {
  UpdatePedagogProps,
  PedagoguDetailsProps,
  PedagoguDataProps,
  CreatePedagog,
  PedagoguAktualData,
} from '../../../interfaces/pedagoget-interface';
import { getPedagoguUrl } from '../../../components/common/common-functions/commonApiUrl/get-pedagogu-url';

const { test } = getConfig();

export const pedagoguApi = createApi({
  reducerPath: 'pedagoget',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders(headers) {
      const token = localStorage.getItem('magjistraturaAppToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['pedagogu', 'personi'],
  endpoints: builder => ({
    getAllPedagogetSimplified: builder.query<
      PaginationReturn<PedagoguDataProps>,
      PedagoguDetailsProps
    >({
      query: args => getPedagoguUrl(args),
      providesTags: ['pedagogu'],
    }),

    getAllPedagoget: builder.query<
      PaginationReturn<PedagoguDataProps>,
      PedagoguDetailsProps
    >({
      query: args => getPedagoguUrl(args, 'pedagog'),
      providesTags: ['pedagogu'],
    }),

    createPedagog: builder.mutation<number, CreatePedagog>({
      query: data => ({
        url: `/pedagog`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['pedagogu'],
    }),
    updatePedagog: builder.mutation<number, UpdatePedagogProps>({
      query: data => ({
        url: `/pedagog/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['pedagogu'],
    }),
    deletePedagog: builder.mutation<string, number>({
      query: id => ({
        url: `/pedagog/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['pedagogu'],
    }),
    getPedagoguAktual: builder.query<PedagoguAktualData, void>({
      query: args => ({
        url: `/pedagog/aktual`,
        method: 'GET',
        body: args,
      }),
    }),
  }),
});

export const {
  useGetAllPedagogetQuery,
  useLazyGetAllPedagogetSimplifiedQuery,
  useCreatePedagogMutation,
  useDeletePedagogMutation,
  useUpdatePedagogMutation,
  useGetPedagoguAktualQuery,
} = pedagoguApi;
