import {
  allAnetarKomisioni,
  allFormimiFillestar,
} from '../constants/all-anetar-komisioni';
import { ROLES } from '../constants/enums/roles';

interface RoutesProps {
  [key: string]: string[];
}

const {
  admin,
  konkurrent,
  anetarShm,
  specialistFv,
  pjesmarres,
  pedagog,
  specialistFF,
  student,
} = ROLES;

const routesWithPermissions: RoutesProps = {
  '/login': [],
  '/signup': [],
  '/redirect': [],
  '/pjesemarresit': [admin],
  '/administrim-konkurrim': [admin, anetarShm],
  '/administrim-formimiFillestar': [admin, specialistFF],
  '/administrim-formimiVazhdues': [admin, specialistFv],
  '/konkurrime': [konkurrent],
  '/program-tematik': [pjesmarres],
  '/propozo-trajnim': [pjesmarres],
  '/kalendari': [pjesmarres],
  '/roli-trajnues': [pjesmarres],
  '/listeprezenca-per-pjesemarres': [pjesmarres],
  '/shiko-trajnim/:id': [pjesmarres],
  '/rezultateFaza': [konkurrent],
  '/verifikimKonkurrime': [...allAnetarKomisioni, admin],
  '/verifikimAplikimi': [...allAnetarKomisioni, admin],
  '/trajnime-kalendari': [specialistFv],
  '/propozime': [specialistFv],
  '/trajnime-program-tematik': [specialistFv],
  '/rankime': [anetarShm, admin],
  '/orari': [admin, pedagog, specialistFF, student],
  '/ndryshoFjalekalimin': [
    ...allFormimiFillestar,
    ...allAnetarKomisioni,
    ...specialistFv,
    student,
    pjesmarres,
    konkurrent,
  ],
  '/faza1:': [admin, anetarShm],
  '/modulet': [],
  '/lende-akademike': [...allFormimiFillestar, student],
  '/lende-mesimore': [...allFormimiFillestar, student],
  '/evidenca': [pedagog, specialistFF],
  '/list-prezenca': [...allFormimiFillestar, student],
  '/vleresime': [pedagog, specialistFF],
  '/vleresime-lende-mesimore': [pedagog, specialistFF],
  '/vleresime-gjyqe-imituese': [pedagog, admin],
  '/liste-prezenca/:id': [specialistFv],
  '/gjyqe-imituese': [...allFormimiFillestar, student],
  '/klinike-ligjore': [...allFormimiFillestar, student],
  '/liste-prezenca-trajnimet': [specialistFv],
  '/audit': [admin],
  '/raporte': [pedagog, specialistFF],
  '/vleresime-klinike-ligjore': [...allFormimiFillestar],
};

export default routesWithPermissions;
