import { LendaApiProps } from '../../../../redux/features/lenda/lendaApi';

export const getLendaApiUrl = (params: LendaApiProps, url = 'lenda') => {
  const {
    page,
    size,
    lendeKonkurimi,
    emri,
    vitiAkademikId,
    lendaAkademikeId,
    lendeGjyqKlinikaId,
  } = params;
  let queryString = `/${url}?size=${size}&page=${page}&sort=emri:asc`;

  if (emri) {
    queryString += `&filter=emri:ilike:${emri}`;
  }
  if (lendeKonkurimi !== undefined) {
    queryString += `&filter=lendeKonkurimi:eq:${lendeKonkurimi}`;
  }
  if (vitiAkademikId) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (lendaAkademikeId && lendaAkademikeId > -1) {
    queryString += `&filter=lendaAkademikeId:eq:${lendaAkademikeId}`;
  }
  if (lendeGjyqKlinikaId && lendeGjyqKlinikaId > -1) {
    queryString += `&filter=lendeGjyqId:eq:${lendeGjyqKlinikaId}`;
  }

  return queryString;
};
