import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { AcademicYear } from '../../../interfaces/CompetitionYear';
import { PaginationReturn } from '../../../interfaces/pagination';
const { test } = getConfig();

export interface ProcessShmProps {
  id: number;
  llojiProcesShm: string;
  statusProcesShm: string;
  vitiAkademik: AcademicYear;
}
export const processShmApi = createApi({
  reducerPath: 'processShmApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    getShmProcess: builder.query<PaginationReturn<ProcessShmProps>, void>({
      query: () => {
        return {
          url: '/proces-shm',
          method: 'Get',
        };
      },
    }),
  }),
});

export const { useGetShmProcessQuery } = processShmApi;
