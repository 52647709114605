import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RankimetResponse } from '../../../interfaces/rankimet-interface';
import {
  FazaProps,
  RankimetFileReturn,
} from '../../../interfaces/fazat-interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();
export interface UpdateFileParams {
  body: FormData;
}
export const rankimetApi = createApi({
  reducerPath: 'rankimetApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders(headers) {
      const token = localStorage.getItem('magjistraturaAppToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['rankimet'],

  endpoints: builder => ({
    merrRankimet: builder.query<RankimetResponse[], number>({
      query: id => ({
        url: `/rezultate-profili?filter=vitiKonkurrimitId:eq:${id}`,
        method: 'GET',
      }),
      providesTags: ['rankimet'],
    }),
    gjeneroRankimet: builder.mutation<string, void>({
      query: () => ({
        url: '/rezultate-profili/gjenero-rankim',
        method: 'POST',
        responseHandler: response => response.text(),
      }),
    }),
    exportInputFileRankimet: builder.query<Blob, void>({
      query: () => ({
        url: `/rezultate-profili/export-final-input-file`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['rankimet'],
    }),
    exportInitialRankings: builder.query<Blob, void>({
      query: () => ({
        url: `/rezultate-profili/export-rezultate-paraprake`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
    }),
    ngarkoRankimeFinale: builder.mutation<
      RankimetFileReturn[],
      UpdateFileParams
    >({
      query: args => ({
        url: `/rezultate-profili/import-rezultate-finale`,
        method: 'POST',
        body: args.body,
      }),
      invalidatesTags: ['rankimet'],
    }),
    merrFazaAktive: builder.query<PaginationReturn<FazaProps>, void>({
      query: () => ({
        url: 'faza/aktive',
        method: 'GET',
      }),
      providesTags: ['rankimet'],
    }),
  }),
});

export const {
  useMerrRankimetQuery,
  useGjeneroRankimetMutation,
  useLazyExportInputFileRankimetQuery,
  useLazyExportInitialRankingsQuery,
  useNgarkoRankimeFinaleMutation,
  useMerrFazaAktiveQuery,
} = rankimetApi;
