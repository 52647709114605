import { LendeGjyqParams } from '../../../../interfaces/lende-gjyqe.interface';
import { PaginationProps } from '../../../../interfaces/pagination';

export interface LegalClinicMockTrialParams extends PaginationProps {
  emri?: string;
  pedagogPergjegjesId?: number;
}
export const getMockTrialApi = (
  params: LendeGjyqParams,
  url = 'lende-gjyq',
) => {
  const {
    page,
    size,
    sort,
    emri,
    vitiAkademikId,
    kategoria,
    klasaId,
    pedagogPergjegjesId,
    llojLenda,
  } = params;
  let queryString = `/${url}?size=${size}&page=${page}`;

  if (emri) {
    queryString += `&filter=emri:ilike:${emri}`;
  }
  if (sort) {
    queryString += `&sort=vitiAkademikAktiv:${sort}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (pedagogPergjegjesId && pedagogPergjegjesId > -1) {
    queryString += `&filter=pedagogPergjegjesId:eq:${pedagogPergjegjesId}`;
  }
  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (kategoria) {
    queryString += `&filter=kategoria:eq:${kategoria}`;
  }
  if (llojLenda) {
    queryString += `&filter=llojLenda:eq:${llojLenda}`;
  }

  return queryString;
};

export const getLegalClinicMockTrialApi = (
  params: LegalClinicMockTrialParams,
  url = 'lende-gjyq-klinika/simplified',
) => {
  const { page, size, emri, pedagogPergjegjesId } = params;
  let queryString = `/${url}?size=${size}&page=${page}`;

  if (emri) {
    queryString += `&filter=emri:ilike:${emri}`;
  }
  if (pedagogPergjegjesId && pedagogPergjegjesId > -1) {
    queryString += `&filter=pedagogPergjegjesId:eq:${pedagogPergjegjesId}`;
  }

  return queryString;
};
