import React from 'react';
import RessetPassword from '../../SettingsModal/RessetPassword';
import UserInformation from '../../SettingsModal/UserInformation';

export const settingsModalItemsData = [
  {
    key: '1',
    label: (
      <div className="text-base font-medium	tracking-wide">
        <span
          style={{
            color: '#003666',
          }}
        >
          Perdoruesi
        </span>
      </div>
    ),
    children: <UserInformation />,
  },
  {
    key: '2',
    label: (
      <div className="text-base	font-medium tracking-wide">
        <span
          style={{
            color: '#003666',
          }}
        >
          Ndrysho fjalekalimin
        </span>
      </div>
    ),
    children: <RessetPassword />,
  },
];
