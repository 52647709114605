import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';
import {
  AddLendeGjyqeProps,
  LendeGjyqParams,
  LendeGjyqResponse,
  LendeGjyqTema,
} from '../../../interfaces/lende-gjyqe.interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import {
  LegalClinicMockTrialParams,
  getLegalClinicMockTrialApi,
  getMockTrialApi,
} from '../../../components/common/common-functions/commonApiUrl/get-lende-gjyqe-api';
import { LendaTematProps } from '../../../interfaces/lenda-akademike.interface';
import { getLegalClinicUrl } from '../../../components/common/common-functions/commonApiUrl/get-klinike-ligjore-url';

const { test } = getConfig();

export type MockTrialType = PaginationReturn<LendeGjyqResponse>;

export const lendeGjyqApi = createApi({
  reducerPath: 'lendeGjyqApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['allMockTrials', 'allLegalClinics'],
  endpoints: builder => ({
    getAllLendeGjyq: builder.query<MockTrialType, LendeGjyqParams>({
      query: getMockTrialApi,
      providesTags: ['allMockTrials'],
    }),
    getLendeGjyqPedagog: builder.query<MockTrialType, LendeGjyqParams>({
      query: args => getMockTrialApi(args, 'lende-gjyq-klinika/per-pedagog'),
      providesTags: ['allMockTrials'],
    }),
    getLendeGjyqStudent: builder.query<MockTrialType, LendeGjyqParams>({
      query: args => getMockTrialApi(args, 'lende-gjyq-klinika/per-student'),
      providesTags: ['allMockTrials'],
    }),
    getAllLendeGjyqPedagog: builder.query<MockTrialType, LendeGjyqParams>({
      query: args =>
        getMockTrialApi(args, 'lende-gjyq-klinika/per-pedagog/simplified'),
      providesTags: ['allMockTrials'],
    }),
    getSingleLendeGjyqTema: builder.query<LendeGjyqTema, number>({
      query: id => ({
        url: `/lende-gjyq-klinika/${id}/lende-tema`,
        method: 'GET',
      }),
      providesTags: ['allMockTrials'],
    }),
    getSimplifiedLegalClinicMockTrial: builder.query<
      MockTrialType,
      LegalClinicMockTrialParams
    >({
      query: args => ({
        url: getLegalClinicMockTrialApi(args, 'lende-gjyq-klinika/simplified'),
        method: 'GET',
      }),
      providesTags: ['allMockTrials', 'allLegalClinics'],
    }),
    getTopicsPerGjyqStudent: builder.query<LendeGjyqTema, number>({
      query: id => ({
        url: `/lenda-tema/gjyq-klinike/${id}/per-student`,
        method: 'GET',
      }),
      providesTags: ['allMockTrials'],
    }),
    getTopicsPerGjyqPedagog: builder.query<LendeGjyqTema, number>({
      query: id => ({
        url: `/lende-gjyq-klinika/${id}/lende-tema/per-pedagog`,
        method: 'GET',
      }),
      providesTags: ['allMockTrials'],
    }),
    getLendeById: builder.query<MockTrialType, number>({
      query: id => `/lende-gjyq/sipas-grup/${id}`,
      providesTags: ['allMockTrials'],
    }),
    getLendeGjyqByGroup: builder.query<MockTrialType, number>({
      query: id => ({
        url: `/lende-gjyq/sipas-grup/${id}`,
        method: 'GET',
      }),
      providesTags: ['allMockTrials'],
    }),
    addMockTrial: builder.mutation<number, AddLendeGjyqeProps>({
      query: body => ({
        url: '/lende-gjyq',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    updateMockTrial: builder.mutation<
      number,
      UpdateKlasaStudent<AddLendeGjyqeProps>
    >({
      query: ({ body, id }) => ({
        url: `/lende-gjyq/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    deleteMockTrial: builder.mutation<number, number>({
      query: id => ({
        url: `/lende-gjyq-klinika/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['allMockTrials', 'allLegalClinics'],
    }),
    addTemaMockTrial: builder.mutation<
      number,
      UpdateKlasaStudent<LendaTematProps>
    >({
      query: ({ id, body }) => ({
        url: `/lenda-tema/gjyq-klinike/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    updateTemaMockTrial: builder.mutation<
      number,
      UpdateKlasaStudent<LendaTematProps>
    >({
      query: ({ id, body }) => ({
        url: `/lenda-tema/gjyq-klinike/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    deleteTemaMockTrial: builder.mutation<number, number>({
      query: id => ({
        url: `/lenda-tema/gjyq-klinike/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    closeEvaluations: builder.mutation<number, number>({
      query: id => ({
        url: `/lende-gjyq/${id}/perfundo-vleresim`,
        method: 'PUT',
      }),
      invalidatesTags: ['allMockTrials'],
    }),
    getAllLegalClinics: builder.query<MockTrialType, LendeGjyqParams>({
      query: getLegalClinicUrl,
      providesTags: ['allLegalClinics'],
    }),
    getLegalClinicByGroup: builder.query<MockTrialType, number>({
      query: id => ({
        url: `/lende-klinike/sipas-grup/${id}`,
        method: 'GET',
      }),
      providesTags: ['allLegalClinics'],
    }),
    addLegalClinic: builder.mutation<number, AddLendeGjyqeProps>({
      query: body => ({
        url: '/lende-klinike',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allLegalClinics'],
    }),
    updateLegalClinic: builder.mutation<
      number,
      UpdateKlasaStudent<AddLendeGjyqeProps>
    >({
      query: ({ body, id }) => ({
        url: `/lende-klinike/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allLegalClinics'],
    }),
  }),
});

export const {
  useGetAllLendeGjyqQuery,
  useLazyGetAllLendeGjyqQuery,
  useGetLendeGjyqPedagogQuery,
  useLazyGetLendeGjyqPedagogQuery,
  useLazyGetAllLegalClinicsQuery,
  useGetLendeGjyqStudentQuery,
  useGetTopicsPerGjyqStudentQuery,
  useLazyGetAllLendeGjyqPedagogQuery,
  useAddMockTrialMutation,
  useUpdateMockTrialMutation,
  useDeleteMockTrialMutation,
  useGetLendeGjyqByGroupQuery,
  useGetLendeByIdQuery,
  useAddTemaMockTrialMutation,
  useGetSingleLendeGjyqTemaQuery,
  useGetTopicsPerGjyqPedagogQuery,
  useLazyGetTopicsPerGjyqPedagogQuery,
  useDeleteTemaMockTrialMutation,
  useUpdateTemaMockTrialMutation,
  useCloseEvaluationsMutation,
  useGetAllLegalClinicsQuery,
  useAddLegalClinicMutation,
  useUpdateLegalClinicMutation,
  useGetLegalClinicByGroupQuery,
  useLazyGetSimplifiedLegalClinicMockTrialQuery,
} = lendeGjyqApi;
