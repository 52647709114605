import jwt_decode from 'jwt-decode';
import { ROLES } from '../constants/enums/roles';
import { useSelector } from 'react-redux';
import { UserStateProps } from '../interfaces/modules.interface';
import { useMemo } from 'react';

const token = localStorage.getItem('magjistraturaAppToken');

interface DecodedToken {
  exp: number;
  iat: number;
  role: Array<ROLES>;
  sub: string;
}
export const getUserRole = () => {
  const token = localStorage.getItem('magjistraturaAppToken');
  if (token) {
    const decoded = jwt_decode<DecodedToken>(token);
    return decoded.role[0];
  }
};
export const getUserRoles = () => {
  const token = localStorage.getItem('magjistraturaAppToken');
  if (token) {
    const decoded = jwt_decode<DecodedToken>(token);
    return decoded.role;
  }
};

export const tokenExpiry = () => {
  if (token) {
    const decoded = jwt_decode<DecodedToken>(token);
    return decoded.exp;
  }
};

export const useRoleGetter = () => {
  const selectorRole = useSelector((state: UserStateProps) => state.activeUser);

  const role = useMemo(
    () =>
      selectorRole.length > 0
        ? selectorRole
        : (localStorage.getItem('activeUser') as ROLES | undefined),
    [selectorRole],
  );

  const userRole = role ?? getUserRole();
  return userRole;
};
