export enum ROLES {
  admin = 'ADMIN',
  konkurrent = 'KONKURRENT',
  pjesmarres = 'PJESEMARRES',
  anetarShm = 'ANETAR_KOMISIONI_SHM',
  anetarKlgj = 'ANETAR_KOMISIONI_KLGJ',
  anetarKlp = 'ANETAR_KOMISIONI_KLP',
  specialistFv = 'SPECIALIST_FV',
  specialistFF = 'SPECIALIST_FF',
  pedagog = 'PEDAGOG',
  student = 'STUDENT',
}

export enum StaffROLES {
  anetarShm = 'ANETAR_KOMISIONI_SHM',
  anetarKlgj = 'ANETAR_KOMISIONI_KLGJ',
  anetarKlp = 'ANETAR_KOMISIONI_KLP',
  specialistFv = 'SPECIALIST_FV',
  specialistFF = 'SPECIALIST_FF',
}
