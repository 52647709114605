import React from 'react';
import CostumLoadable from '../components/Loaders/CostumLoadable/CustomLoadable';

type Route = {
  caseSensitive?: boolean;
  children?: Route[];
  element?: React.ReactNode;
  path?: string;
};

const Administration = () =>
  import('../pages/Administration/Competitions/Administration');
const FormimiFillestar = () =>
  import('../pages/Administration/PrimaryEducation/PrimaryEducation');
const FormimiVazhdues = () =>
  import('../pages/Administration/ContinuousEducation/ContinuousEducation');

const LoginSignup = () => import('../pages/LoginSignup/LoginSignup');
const Redirect = () => import('../pages/Redirect');
const Konkurrime = () => import('../pages/Konkurrim/Konkurrime');
const KonkurrimeShm = () => import('../pages/KonkurrimeShm/KonkurrimeShm');
const ApplicationMenuShm = () =>
  import('../pages/AnetarShm/ApplicationMenuShm');
const RezultateFazaKonkurrenti = () =>
  import('../pages/RezultateFaza/RezultateFazaKonkurrenti');
const CalendarYearTraining = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/calendar-year.component'
  );
const ActiveTrainingYear = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/trajnimi-per-pjesemarres/kalendari-trainings'
  );
const ProgramTematik = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/trajnimi-per-pjesemarres/program-tematik'
  );

const TrainingRole = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/trajnimi-per-pjesemarres/roli-trajnim'
  );

const FazaAktive = () => import('../pages/Fazat/Fazat');
const Rankings = () => import('../pages/Rankings/Rankimet');
const Modulet = () => import('../pages/Modules/Modulet');
const Orari = () => import('../pages/Orari/Orari');
const ThematicProgram = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/thematic-program-component'
  );
const Propozime = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/trajnimi-per-pjesemarres/propozo-trajnim.component'
  );

const ChangePassword = () => import('../pages/ChangePassword/ChangePassword');
const LendeAkademike = () =>
  import('../pages/LendaAkademikeMenu/LendeAkademikeMenu');

const Evidenca = () => import('../pages/Evidenca/Evidenca');
const ListPrezenca = () => import('../pages/ListePrezenca/ListePrezenca');
const AttendanceViewPage = () =>
  import('../pages/AdministrationOfContinuousEducation/ViewAttendancePage');
const ProposedTrainings = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/proposed-trainings.component'
  );
const Vleresime = () => import('../pages/Vleresime/Vleresimi');
const VleresimiGjyqe = () => import('../pages/Vleresime/VleresimiGjyqe');
const VleresimiKlinike = () =>
  import('../pages/Vleresime/VleresimiKlinikeLigjore');
const LendeMesimore = () => import('../pages/LendaAkademikeMenu/LendeMesimore');
const GjyqeImituese = () => import('../pages/LendaAkademikeMenu/GjyqeImituese');
const KlinikeLigjore = () =>
  import('../pages/LendaAkademikeMenu/KlinikeLigjore');
const ListePrezencaForTrainings = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/liste-prezenca-for-trainings.component'
  );
const Audit = () => import('../pages/Audit/Audit');
const TrainingViewPage = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/shiko-trajnim.component'
  );
const Reports = () =>
  import('../pages/Administration/PrimaryEducation/Reports');

const ListeprezencaPerPjesemarres = () =>
  import(
    '../pages/AdministrationOfContinuousEducation/trajnimi-per-pjesemarres/liste-prezenca-per-pjesemarres'
  );

const routes: Route[] = [
  {
    path: '/redirect',
    element: <CostumLoadable loader={Redirect} path="/redirect" />,
  },
  {
    path: '/login',
    element: <CostumLoadable loader={LoginSignup} path="/login" />,
  },

  {
    path: '/administrim-konkurrim',
    element: (
      <CostumLoadable loader={Administration} path="/administrim-konkurrim" />
    ),
  },
  {
    path: '/administrim-formimiFillestar',
    element: (
      <CostumLoadable
        loader={FormimiFillestar}
        path="/administrim-formimiFillestar"
      />
    ),
  },
  {
    path: '/administrim-formimiVazhdues',
    element: (
      <CostumLoadable
        loader={FormimiVazhdues}
        path="/administrim-formimiVazhdues"
      />
    ),
  },
  {
    path: '/konkurrime/:id?',
    element: <CostumLoadable loader={Konkurrime} path="/konkurrime" />,
  },
  {
    path: '/verifikimKonkurrime',
    element: (
      <CostumLoadable loader={KonkurrimeShm} path="/verifikimKonkurrime" />
    ),
  },
  {
    path: '/verifikimAplikimi/:id?',
    element: (
      <CostumLoadable loader={ApplicationMenuShm} path="/verifikimAplikimi" />
    ),
  },
  {
    path: '/rezultateFaza',
    element: (
      <CostumLoadable loader={RezultateFazaKonkurrenti} path="/rezultateFaza" />
    ),
  },
  {
    path: '/trajnime-program-tematik',
    element: (
      <CostumLoadable
        loader={ThematicProgram}
        path="/trajnime-program-tematik"
      />
    ),
  },
  {
    path: '/propozime',
    element: <CostumLoadable loader={ProposedTrainings} path="/propozime" />,
  },
  {
    path: '/trajnime-kalendari',
    element: (
      <CostumLoadable
        loader={CalendarYearTraining}
        path="/trajnime-kalendari"
      />
    ),
  },
  {
    path: '/ndryshoFjalekalimin',
    element: (
      <CostumLoadable loader={ChangePassword} path="/ndryshoFjalekalimin" />
    ),
  },
  {
    path: '/program-tematik',
    element: (
      <CostumLoadable loader={ProgramTematik} path={'/program-tematik'} />
    ),
  },
  {
    path: '/propozo-trajnim',
    element: <CostumLoadable loader={Propozime} path="/propozo-trajnim" />,
  },
  {
    path: '/faza1',
    element: <CostumLoadable loader={FazaAktive} path="/verifikimAplikimi" />,
  },

  {
    path: '/kalendari',
    element: <CostumLoadable loader={ActiveTrainingYear} path={'/kalendari'} />,
  },
  {
    path: '/roli-trajnues',
    element: <CostumLoadable loader={TrainingRole} path={'/roli-trajnues'} />,
  },
  {
    path: '/rankime',
    element: <CostumLoadable loader={Rankings} path="/rankime" />,
  },
  {
    path: '/modulet',
    element: <CostumLoadable loader={Modulet} path="/modulet" />,
  },
  {
    path: '/orari',
    element: <CostumLoadable loader={Orari} path="/orari" />,
  },
  {
    path: '/lende-akademike',
    element: <CostumLoadable loader={LendeAkademike} path="/lende-akademike" />,
  },
  {
    path: '/evidenca',
    element: <CostumLoadable loader={Evidenca} path="/evidenca" />,
  },
  {
    path: '/list-prezenca',
    element: <CostumLoadable loader={ListPrezenca} path="/list-prezenca" />,
  },
  {
    path: '/liste-prezenca/:id',
    element: (
      <CostumLoadable loader={AttendanceViewPage} path="/liste-prezenca/:id" />
    ),
  },
  {
    path: '/shiko-trajnim/:id',
    element: (
      <CostumLoadable loader={TrainingViewPage} path="/shiko-trajnim/:id" />
    ),
  },
  {
    path: '/shiko-trajnim/:id',
    element: (
      <CostumLoadable loader={TrainingViewPage} path="/shiko-trajnim/:id" />
    ),
  },
  {
    path: '/vleresime-lende-mesimore',
    element: (
      <CostumLoadable loader={Vleresime} path="/vleresime-lende-mesimore" />
    ),
  },
  {
    path: '/vleresime-gjyqe-imituese',
    element: (
      <CostumLoadable
        loader={VleresimiGjyqe}
        path="/vleresime-gjyqe-imituese"
      />
    ),
  },
  {
    path: '/vleresime-klinike-ligjore',
    element: (
      <CostumLoadable
        loader={VleresimiKlinike}
        path="/vleresime-klinike-ligjore"
      />
    ),
  },
  {
    path: '/lende-mesimore',
    element: <CostumLoadable loader={LendeMesimore} path="/lende-mesimore" />,
  },
  {
    path: '/gjyqe-imituese',
    element: <CostumLoadable loader={GjyqeImituese} path="/gjyqe-imituese" />,
  },
  {
    path: '/klinike-ligjore',
    element: <CostumLoadable loader={KlinikeLigjore} path="/klinike-ligjore" />,
  },
  {
    path: '/liste-prezenca-trajnimet',
    element: (
      <CostumLoadable
        loader={ListePrezencaForTrainings}
        path="/liste-prezenca-trajnimet"
      />
    ),
  },
  {
    path: '/listeprezenca-per-pjesemarres',
    element: (
      <CostumLoadable
        loader={ListeprezencaPerPjesemarres}
        path="/listeprezenca-per-pjesemarres"
      />
    ),
  },
  {
    path: '/audit',
    element: <CostumLoadable loader={Audit} path="/audit" />,
  },
  {
    path: '/raporte',
    element: <CostumLoadable loader={Reports} path="/raporte" />,
  },
];

export default routes;
