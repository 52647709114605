import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { PaginationReturn } from '../../../interfaces/pagination';
import { InstitutionProps } from '../institucioni/institucioni-api';
import { ParticipantProps } from '../pjesemarres-ne-trajnime/pjesemarres-ne-trajnime-api';
import { TrainingConfigurationData } from '../trajnimet/trajnimet-api';
import { getTrajnimiPrezencaUrl } from '../../../components/common/common-functions/commonApiUrl/get-trajnimi-prezenca-url';
const { test } = getConfig();

export interface TrainingAttendanceRequest {
  id: string | undefined;
  emri: string | undefined;
  mbiemri: string | undefined;
  page: number;
  size: number;
}

export interface TrainingAttendanceResponse {
  prezenca: PaginationReturn<ParticipantInfo>;
  trajnimi: {
    dataFillimit: string;
    dataMbarimit: string;
    id: number;
    kategoria: string;
    tema: string;
  };
}

export interface ParticipantInfo extends TrainingAttendanceQueryProp {
  data: string;
  pjesemarres: {
    pjesemarresId: number;
    emri: string;
    mbiemri: string;
    institucioni: InstitutionProps;
  };
}

export interface TrainingAttendanceQueryProp {
  idTrajnimPrezenca: number;
  ditePrezent: number;
}

export interface TrainingAttendance {
  pjesemarresNeTrajnim: ParticipantProps & {
    trajnim: TrainingConfigurationData;
  };
  id: number;
  data: string;
  ditePrezent: number;
}

export interface TrainingAttendanceQueryProps {
  page: number;
  size: number;
  vitiAkademikId?: number;
  trajnimiId?: number;
  pjesemarresId?: number;
  dataFilterFillimi?: string;
  dataFilterMbarimi?: string;
  sort?: string;
}

export interface TrainingAttendanceForParticipantProps
  extends TrainingAttendanceQueryProp {
  data: string;
  trajnimi: TrainingConfigurationData;
}

export const trajnimPrezencaApi = createApi({
  reducerPath: 'trajnimPrezencaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Prezenca'],
  endpoints: builder => ({
    getAllTrainingsAttendance: builder.query<
      PaginationReturn<TrainingAttendance>,
      TrainingAttendanceQueryProps
    >({
      query: args => {
        let url = `/trajnim-prezenca?page=${args.page}`;

        if (args) {
          url += getTrajnimiPrezencaUrl(args);
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Prezenca'],
    }),
    getTrainingAttendanceById: builder.query<
      TrainingAttendanceResponse,
      TrainingAttendanceRequest
    >({
      query: args => {
        let url = `/trajnimi/${args.id}/prezenca?page=${args.page}`;

        if (args.size) {
          url += `&size=${args.size}`;
        }

        if (args.emri) {
          url += `&filter=emri:ilike:${args.emri}`;
        }
        if (args.mbiemri) {
          url += `&filter=mbiemri:ilike:${args.mbiemri}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Prezenca'],
    }),
    getTrainingAttendanceForExpert: builder.query<
      TrainingAttendanceResponse,
      TrainingAttendanceRequest
    >({
      query: args => {
        let url = `/trajnimi/${args.id}/prezenca/per-ekspert?page=${args.page}`;

        if (args.size) {
          url += `&size=${args.size}`;
        }

        if (args.emri) {
          url += `&filter=emri:ilike:${args.emri}`;
        }
        if (args.mbiemri) {
          url += `&filter=mbiemri:ilike:${args.mbiemri}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    updateTrainingAttendance: builder.mutation<
      void,
      TrainingAttendanceQueryProp
    >({
      query: args => {
        return {
          url: `/trajnim-prezenca/${args.idTrajnimPrezenca}?ditePrezent=${args.ditePrezent}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Prezenca'],
    }),
    getTrainingAttendanceForParticipant: builder.query<
      PaginationReturn<TrainingAttendanceForParticipantProps>,
      TrainingAttendanceQueryProps
    >({
      query: args => {
        let url = `/trajnim-prezenca/per-pjesemarres?page=${args.page}`;

        if (args) {
          url += getTrajnimiPrezencaUrl(args);
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetAllTrainingsAttendanceQuery,
  useGetTrainingAttendanceByIdQuery,
  useGetTrainingAttendanceForExpertQuery,
  useUpdateTrainingAttendanceMutation,
  useGetTrainingAttendanceForParticipantQuery,
} = trajnimPrezencaApi;
