import getConfig from '../../../helpers/config';
import {
  DetyraStudentReturn,
  DetyraParams,
} from '../../../interfaces/detyra-interface';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getDetyratUrl } from '../../../components/common/common-functions/commonApiUrl/get-detyrat-url';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();

export const detyraStudentApi = createApi({
  reducerPath: 'detyra-student',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['detyraStudent'],
  endpoints: builder => ({
    getDetyraStudent: builder.query<DetyraStudentReturn, DetyraParams>({
      query: args => ({
        url: getDetyratUrl(args, `/detyra-student/${args.id}/sipas-detyres`),
        method: 'GET',
      }),
      providesTags: ['detyraStudent'],
    }),
    exportDetyraDocumentStudent: builder.query<Blob, number>({
      query: id => ({
        url: `/detyra-student/${id}/dokument`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['detyraStudent'],
    }),
  }),
});

export const {
  useGetDetyraStudentQuery,
  useLazyExportDetyraDocumentStudentQuery,
} = detyraStudentApi;
