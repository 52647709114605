import { FazatParams } from '../../../../interfaces/fazat-interface';

export const getFazatUrl = (params: FazatParams, url = 'faza') => {
  const { size, llojiFaza, sort, statusFaza, page, vitiKonkurrimit } = params;
  let queryString = `/${url}?size=${size}&page=${page}`;

  if (llojiFaza) {
    queryString += `&filter=llojiFaza:ilike:${llojiFaza}`;
  }
  if (statusFaza) {
    queryString += `&filter=statusFaza:ilike:${statusFaza}`;
  }

  if (sort) {
    queryString += `&sort=vitiKonkurrimitVitiFillimit:${sort}`;
  }

  if (vitiKonkurrimit) {
    queryString += `&filter=vitiKonkurrimitVitiFillimit:eq:${vitiKonkurrimit}`;
  }

  return queryString;
};

export const merrSipasFazesUrl = (
  params: FazatParams,
  url = `/rezultate-faza/merr-sipas-fazes/${params.id}`,
) => {
  const { size, sort, page, totali } = params;
  let queryString = `${url}?size=${size}&page=${page}`;

  if (sort && totali) {
    queryString += `&sort=totali:${totali},kodiScantron:${sort} `;
  } else {
    if (sort) {
      queryString += `&sort=kodiScantron:${sort}`;
    }
    if (totali) {
      queryString += `&sort=totali:${totali}`;
    }
  }

  return queryString;
};
