import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ProfilesAccordingToCompetitionYear,
  ProfilesPerCompetitionYear,
  ProfilesPerCompetitionYearRequiredDataToCreate,
} from '../../../interfaces/CompetitionYear';
import { PaginationReturn } from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';

const { test } = getConfig();

export const profili_viti_konkurrimitApi = createApi({
  reducerPath: 'profili_viti_konkurrimit',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['ProfilesPerCompetitionYear', 'GetAllProfilesPerCompetitionYears'],
  endpoints: builder => ({
    getAllProfilesPerCompetitionYear: builder.query<
      PaginationReturn<ProfilesPerCompetitionYear>,
      void
    >({
      query: () => ({
        url: `/profili-viti-konkurrimit`,
        method: 'GET',
      }),
      providesTags: ['GetAllProfilesPerCompetitionYears'],
    }),
    getSingleProfilePerCompetitionYear: builder.query<
      ProfilesPerCompetitionYear,
      { id: number }
    >({
      query: id => ({
        url: `/viti-konkurrimit/${id}`,
        method: 'GET',
      }),
      providesTags: ['ProfilesPerCompetitionYear'],
    }),
    createProfilePerCompetitionYear: builder.mutation<
      void,
      ProfilesPerCompetitionYearRequiredDataToCreate
    >({
      query: data => ({
        url: `/profili-viti-konkurrimit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'GetAllProfilesPerCompetitionYears',
        'ProfilesPerCompetitionYear',
      ],
    }),
    updateProfilePerCompetitionYear: builder.mutation<
      { id: number },
      ProfilesPerCompetitionYear
    >({
      query: args => ({
        url: `/profili-viti-konkurrimit/${args.id}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: [
        'GetAllProfilesPerCompetitionYears',
        'ProfilesPerCompetitionYear',
      ],
    }),
    deleteProfilePerCompetitionYear: builder.mutation<string, number>({
      query: id => ({
        url: `/profili-viti-konkurrimit/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetAllProfilesPerCompetitionYears',
        'ProfilesPerCompetitionYear',
      ],
    }),
    getProfileAccordingToCompetitionYear: builder.query<
      ProfilesAccordingToCompetitionYear,
      number
    >({
      query: id => ({
        url: `/profili-viti-konkurrimit/merr-sipas-viti-konkurrimit/${id}`,
        method: 'GET',
      }),
      providesTags: ['GetAllProfilesPerCompetitionYears'],
    }),
    mbyllRegjistrime: builder.mutation({
      query: () => ({
        url: `/viti-konkurrimit/mbyll-regjistrime`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllProfilesPerCompetitionYearQuery,
  useCreateProfilePerCompetitionYearMutation,
  useUpdateProfilePerCompetitionYearMutation,
  useGetSingleProfilePerCompetitionYearQuery,
  useDeleteProfilePerCompetitionYearMutation,
  useGetProfileAccordingToCompetitionYearQuery,
  useMbyllRegjistrimeMutation,
} = profili_viti_konkurrimitApi;
