import { kontrolloPerfundimRegjistrimeApi } from './features/profili-viti-konkurrimit/kontrollo-perfundim-regjistrimeApi';
import { combineReducers } from '@reduxjs/toolkit';
import { authApi } from './features/auth/authApi';
import { universityApi } from './features/universiteti/universityApi';
import { profiliApi } from './features/profili/profiliApi';
import { lendaApi } from './features/lenda/lendaApi';
import { profili_viti_konkurrimitApi } from './features/profili-viti-konkurrimit/profili-viti-konkurrimitApi';
import { konkurrentiApi } from './features/konkurrenti/konkurrenti';
import { verifikimeApi } from './features/konkurrenti/verifikime';
import { vitiAkademikApi } from './features/vitiKonkurrimit/vitiAkademikApi';
import { fazaApi } from './features/fazat/fazaApi';
import { trajnimetApi } from './features/trajnimet/trajnimet-api';
import { rankimetApi } from './features/rankimet/rankimetApi';
import { institucioniApi } from './features/institucioni/institucioni-api';
import activeUser from './features/activeUser/activeUser';
import { klasaApi } from './features/klasa/klasa-api';
import { studentiApi } from './features/studenti/studentiApi';
import { pedagoguApi } from './features/pedagoget/pedagogetApi';
import { orariMesimorApi } from './features/orariMesimor/orariMesimorAPi';
import { lendeVitiAkademikApi } from './features/lenda/lende-viti-akademikApi';
import { pjesmarresApi } from './features/pjesemarres/pjesemarres-api';
import { trajnuesApi } from './features/trajnues/trajnues-api';
import { evidencaApi } from './features/evidenca/evidencaApi';
import { listePrezencaApi } from './features/liste-prezenca/listePrezencaApi';
import { processShmApi } from './features/proces-shm/process-shm-api';
import { dokumentTrajnimiApi } from './features/dokument-trajnimi/dokument-trajnimi-api';
import { detyraStudentApi } from './features/detyra/detyra-student';
import { vleresimeApi } from './features/vleresimi/vleresimiApi';
import { pjesmarresNeTrajnimeApi } from './features/pjesemarres-ne-trajnime/pjesemarres-ne-trajnime-api';
import { trajnimPrezencaApi } from './features/trajnim-prezenca/trajnim-prezenca-api';
import { lendeGjyqApi } from './features/lenda/lende-gjyqApi';
import { auditApi } from './features/auth/auditApi';
import { reportsApi } from './features/reports/reports';
import { vleresimeGjyqApi } from './features/vleresimi/vleresimiGjyqApi';
import { vleresimeKlinikaApi } from './features/vleresimi/vleresimiKlinikaApi';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [profili_viti_konkurrimitApi.reducerPath]:
    profili_viti_konkurrimitApi.reducer,
  [profiliApi.reducerPath]: profiliApi.reducer,
  [lendaApi.reducerPath]: lendaApi.reducer,
  [universityApi.reducerPath]: universityApi.reducer,
  [konkurrentiApi.reducerPath]: konkurrentiApi.reducer,
  [verifikimeApi.reducerPath]: verifikimeApi.reducer,
  [vitiAkademikApi.reducerPath]: vitiAkademikApi.reducer,
  [kontrolloPerfundimRegjistrimeApi.reducerPath]:
    kontrolloPerfundimRegjistrimeApi.reducer,
  [studentiApi.reducerPath]: studentiApi.reducer,
  [fazaApi.reducerPath]: fazaApi.reducer,
  [trajnimetApi.reducerPath]: trajnimetApi.reducer,
  [rankimetApi.reducerPath]: rankimetApi.reducer,
  activeUser,
  [institucioniApi.reducerPath]: institucioniApi.reducer,
  [pedagoguApi.reducerPath]: pedagoguApi.reducer,
  [klasaApi.reducerPath]: klasaApi.reducer,
  [orariMesimorApi.reducerPath]: orariMesimorApi.reducer,
  [lendeVitiAkademikApi.reducerPath]: lendeVitiAkademikApi.reducer,
  [pjesmarresApi.reducerPath]: pjesmarresApi.reducer,
  [trajnuesApi.reducerPath]: trajnuesApi.reducer,
  [evidencaApi.reducerPath]: evidencaApi.reducer,
  [listePrezencaApi.reducerPath]: listePrezencaApi.reducer,
  [processShmApi.reducerPath]: processShmApi.reducer,
  [dokumentTrajnimiApi.reducerPath]: dokumentTrajnimiApi.reducer,
  [detyraStudentApi.reducerPath]: detyraStudentApi.reducer,
  [vleresimeApi.reducerPath]: vleresimeApi.reducer,
  [pjesmarresNeTrajnimeApi.reducerPath]: pjesmarresNeTrajnimeApi.reducer,
  [trajnimPrezencaApi.reducerPath]: trajnimPrezencaApi.reducer,
  [lendeGjyqApi.reducerPath]: lendeGjyqApi.reducer,
  [auditApi.reducerPath]: auditApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [vleresimeGjyqApi.reducerPath]: vleresimeGjyqApi.reducer,
  [vleresimeKlinikaApi.reducerPath]: vleresimeKlinikaApi.reducer,
});

export default rootReducer;
