import React from 'react';
import { Form, Checkbox } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const CheckboxField = (props: FormField) => (
  <Form.Item
    name={props.name}
    rules={props?.rules}
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 24 }}
    style={{ display: 'flex', alignItems: 'center' }}
    valuePropName="checked"
  >
    <Checkbox
      {...props.otherprops}
      label={props.label}
      disabled={props.disabled}
    />
  </Form.Item>
);
export default CheckboxField;
