import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';
import { getStudentUrl } from '../../../components/common/common-functions/commonApiUrl/get-student-url';
import {
  StudentData,
  StudentDataToUpdate,
  StudentiAktualData,
} from '../../../interfaces/student-interface';
import {
  PaginationReturn,
  PaginationProps,
} from '../../../interfaces/pagination';

const { test } = getConfig();
export interface StudentParamsProps extends Partial<PaginationProps> {
  personiNumriPersonal?: string;
  personiEmri?: string;
  vitiStudimit?: string;
  konfirmuar?: boolean;
  vitiAkademikId?: number;
  profili?: string;
  sort?: string;
}
export const studentiApi = createApi({
  reducerPath: 'studenti',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['GetAllStudents'],
  endpoints: builder => ({
    getAllStudents: builder.query<
      PaginationReturn<StudentData>,
      StudentParamsProps
    >({
      query: args => getStudentUrl(args),
      providesTags: ['GetAllStudents'],
    }),
    updateStudent: builder.mutation<number, StudentDataToUpdate>({
      query: args => ({
        url: `/studenti/${args.id}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: ['GetAllStudents'],
    }),
    deleteStudent: builder.mutation<string, number>({
      query: id => ({
        url: `/studenti/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllStudents'],
    }),
    getStudentiAktual: builder.query<StudentiAktualData, void>({
      query: args => ({
        url: `/studenti/aktual`,
        method: 'GET',
        body: args,
      }),
    }),
  }),
});
export const {
  useGetAllStudentsQuery,
  useLazyGetAllStudentsQuery,
  useDeleteStudentMutation,
  useUpdateStudentMutation,
  useGetStudentiAktualQuery,
} = studentiApi;
