import React from 'react';
import { Form, Input } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const InputPassword = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props?.rules}>
      <Input.Password
        placeholder={props?.placeholder}
        {...props.otherprops}
        disabled={props.disabled}
        autoComplete="off"
        className="flex !h-[42px]"
      />
    </Form.Item>
  );
};

export default InputPassword;
