type TabsType = {
  [key: string]: string[];
};

const getActiveTab = ({ location }: { location: string }) => {
  const tabs: TabsType = {
    'administrim-konkurrim': ['/administrim-konkurrim'],
    'administrim-formimiFillestar': ['/administrim-formimiFillestar'],
    'administrim-formimiVazhdues': ['/administrim-formimiVazhdues'],
    faza1: ['/faza1'],
    konkurrime: ['/konkurrime'],
    verifikimKonkurrime: ['/verifikimKonkurrime'],
    rezultateFaza: ['/rezultate-faza'],
    trajnime: ['/trajnime'],
    'program-tematik': ['/program-tematik'],
    'propozo-trajnim': ['/propozo-trajnim'],
    kalendari: ['/kalendari'],
    'roli-trajnues': ['/roli-trajnues'],
    'listeprezenca-per-pjesemarres': ['/listeprezenca-per-pjesemarres'],
    ndryshoFjalekalimin: ['/ndryshoFjalekalimin'],
    modules: ['/modules'],
    propozime: ['/propozime'],
    'trajnime-program-tematik': ['/trajnime-program-tematik'],
    'trajnime-kalendari': ['/trajnime-kalendari'],
    'liste-prezenca/:id': ['/liste-prezenca/:id'],
    'liste-prezenca-trajnimet': ['/liste-prezenca-trajnimet'],
    'shiko-trajnim/:id': ['/shiko-trajnim/:id'],
  };

  for (const key in tabs) {
    if (location.includes(key)) {
      return tabs[key];
    }
  }
  return ['/'];
};

export default getActiveTab;
