import React from 'react';
import { Form, DatePicker } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const AntdDatePicker = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props?.rules}>
      <DatePicker
        {...props.otherprops}
        label={props.label}
        placeholder="Zgjidhni daten"
        format="DD-MM-YYYY"
        disabled={props.disabled}
      />
    </Form.Item>
  );
};

export default AntdDatePicker;
