import { ActiveTrainingFiltersProps } from '../../../../redux/features/trajnimet/trajnimet-api';

export function valueUrlFormatter(args: ActiveTrainingFiltersProps) {
  let url = '';
  if (args.size) {
    url += `&size=${args.size}`;
  }
  if (args.tema) {
    url += `&filter=tema:ilike:${args.tema}`;
  }
  if (args.kategori) {
    url += `&filter=kategoria:eq:${args.kategori}`;
  }

  if (args.statusTrajnimi) {
    url += `&filter=statusTrajnimi:eq:${args.statusTrajnimi}`;
  }

  if (args.vitiAkademikId) {
    url += `&filter=vitiAkademikId:eq:${args.vitiAkademikId}`;
  }
  if (args.regjistruar) {
    url += `&regjistruar=${args.regjistruar}`;
  }
  if (args.eshteTrajnues) {
    url += `&eshteTrajnues=${args.eshteTrajnues}`;
  }
  if (args.dataFilterFillimi) {
    url += `&filter=dataFillimit:gte:${args.dataFilterFillimi}`;
  }
  if (args.dataFilterMbarimi) {
    url += `&filter=dataFillimit:lte:${args.dataFilterMbarimi}`;
  }

  return url;
}
