import { LendaApiProps } from '../../../../redux/features/lenda/lendaApi';

export const getAcademikSubjectUrl = ({
  page,
  size,
  sort,
  emriLenda,
  llojiLenda,
  vitiAkademikId,
  vitiStudimit,
  pedagogu,
}: LendaApiProps) => {
  let queryString = `/lenda-akademike/te-gjitha?size=${size}&page=${page}&sort=emriLenda:asc`;

  if (emriLenda) {
    queryString += `&filter=emriLenda:ilike:${emriLenda}`;
  }
  if (sort) {
    queryString += `&sort=vitiAkademikId:${sort}`;
  }
  if (llojiLenda) {
    queryString += `&filter=llojiLenda:eq:${llojiLenda}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:in:${vitiAkademikId}`;
  }
  if (vitiStudimit) {
    queryString += `&filter=vitiStudimit:eq:${vitiStudimit}`;
  }
  if (pedagogu && pedagogu > -1) {
    queryString += `&filter=pedagogId:eq:${pedagogu}`;
  }

  return queryString;
};
export const getAcademicSubjectPerPedagogUrl = ({
  page,
  size,
  emriLenda,
  llojiLenda,
  vitiStudimit,
  vitiAkademikId,
  roli,
}: LendaApiProps) => {
  let queryString = `/lenda-akademike/per-${roli}?size=${size}&page=${page}&sort=emriLenda:asc`;
  if (emriLenda) {
    queryString += `&filter=emriLenda:ilike:${emriLenda}`;
  }
  if (llojiLenda) {
    queryString += `&filter=llojiLenda:eq:${llojiLenda}`;
  }
  if (vitiAkademikId) {
    queryString += `&filter=vitiAkademikId:in:${vitiAkademikId}`;
  }
  if (vitiStudimit) {
    queryString += `&filter=vitiStudimit:eq:${vitiStudimit}`;
  }
  return queryString;
};
