import React from 'react';
import { Form, TimePicker } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const AntdTimePicker = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props?.rules}>
      <TimePicker
        {...props.otherprops}
        label={props.label}
        placeholder="Zgjidhni një orë"
        format="HH:mm"
        disabled={props.disabled}
      />
    </Form.Item>
  );
};

export default AntdTimePicker;
