import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { Key } from 'react';
const { test } = getConfig();

export interface InstitutionProps {
  id?: Key;
  key?: Key;
  emri?: string;
  kategoriaInstitucionit?: InstitutionCategory;
  qyteti?: string;
}

export interface InstitutionDataProps {
  currentPage: number;
  totalPages: number;
  totalElements: number;
  data: Array<InstitutionProps>;
}

export interface InstitutionFiltersProps extends InstitutionProps {
  page?: number;
  size?: number;
  statusi?: string;
}

export enum InstitutionCategory {
  Prokurori = 'PROKURORI',
  Gjykate = 'GJYKATE',
  'Avokatura e shtetit' = 'AVOKATURA_SHTETIT',
  Tjeter = 'TJETER',
}

export const institucioniApi = createApi({
  reducerPath: 'institucioniApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Institucioni'],
  endpoints: builder => ({
    createInstitutions: builder.mutation<void, InstitutionProps>({
      query: body => {
        return {
          url: `/institucioni`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Institucioni'],
    }),
    getAllInstitutions: builder.query<
      InstitutionDataProps,
      InstitutionFiltersProps
    >({
      query: args => {
        let url = `/institucioni?page=${args.page}`;

        if (args.size) {
          url += `&size=${args.size}`;
        }
        if (args.emri) {
          url += `&filter=emri:ilike:${args.emri}`;
        }
        if (args.kategoriaInstitucionit) {
          url += `&filter=kategoria:eq:${args.kategoriaInstitucionit}`;
        }
        if (args.qyteti) {
          url += `&filter=qyteti:eq:${args.qyteti}`;
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Institucioni'],
    }),
    updateInstitutions: builder.mutation<void, InstitutionProps>({
      query: ({ id, ...body }) => {
        return {
          url: `/institucioni/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Institucioni'],
    }),
    deleteInstitutions: builder.mutation<string, number>({
      query: arg => ({
        url: `/institucioni/${arg}`,
        method: 'Delete',
      }),
      invalidatesTags: ['Institucioni'],
    }),
  }),
});

export const {
  useCreateInstitutionsMutation,
  useGetAllInstitutionsQuery,
  useLazyGetAllInstitutionsQuery,
  useUpdateInstitutionsMutation,
  useDeleteInstitutionsMutation,
} = institucioniApi;
