import React, { useEffect } from 'react';
import { Form } from 'antd';
import '../../styles/index.css';
import FormFieldRender from '../FormRender/FormFieldRender';
import { useGetLoggedInUserQuery } from '../../redux/features/auth/authApi';
import { UserInformationInitialValues } from '../../constants/initial-values/user-information-initial-values';
import { useForm } from 'antd/es/form/Form';
import { useGetStudentiAktualQuery } from '../../redux/features/studenti/studentiApi';
import { useGetPedagoguAktualQuery } from '../../redux/features/pedagoget/pedagogetApi';
import { ROLES } from '../../constants/enums/roles';
import { replaceUnderscore } from '../common/common-functions/form-items';
import { getVitiStudimitLabel } from '../common/common-functions/getVitiStudimitLabel';

const UserInformation = () => {
  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const shouldSkipStudentiAktualQuery =
    !loggedInUser || !loggedInUser.rolet.includes(ROLES.student);
  const shouldSkipPedagoguAktualQuery =
    !loggedInUser || !loggedInUser.rolet.includes(ROLES.pedagog);
  const { data: studentiAktual } = useGetStudentiAktualQuery(undefined, {
    skip: shouldSkipStudentiAktualQuery,
  });
  const { data: pedagoguAktual } = useGetPedagoguAktualQuery(undefined, {
    skip: shouldSkipPedagoguAktualQuery,
  });
  const [form] = useForm();
  const userInformation = UserInformationInitialValues(
    studentiAktual,
    pedagoguAktual,
  );

  const initialValues = {
    emri: loggedInUser?.personi.emri,
    mbiemri: loggedInUser?.personi.mbiemri,
    numriPersonal: loggedInUser?.personi.numriPersonal,
    email: loggedInUser?.email,
    pedagogLloji: replaceUnderscore(pedagoguAktual?.pedagogLloji),
    aktiv: pedagoguAktual?.aktiv,
    vitiStudimit: getVitiStudimitLabel(studentiAktual?.vitiStudimit),
    konfirmuar: studentiAktual?.konfirmuar,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      form={form}
      requiredMark={false}
      className="team-member-form-render m-3 font-semibold"
      name="userInfoForm"
      layout="vertical"
      initialValues={initialValues}
    >
      <div className="grid">
        {userInformation.map((data, i) => {
          return (
            <React.Fragment key={i}>
              <div
                className={`${
                  data.type === 'checkbox'
                    ? 'checkbox-placeholder'
                    : 'input-with-placeholder'
                } m-3`}
              >
                <span className="form-item-label text-sm font-medium text-gray-400">
                  {data?.label}
                </span>
                <FormFieldRender data={data} />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </Form>
  );
};

export default UserInformation;
