import { LendeGjyqParams } from '../../../../interfaces/lende-gjyqe.interface';

export const getLegalClinicUrl = (params: LendeGjyqParams) => {
  const {
    page,
    size,
    sort,
    emri,
    vitiAkademikId,
    kategoria,
    klasaId,
    pedagogPergjegjesId,
  } = params;
  let queryString = `/lende-klinike?size=${size}&page=${page}`;
  if (emri) {
    queryString += `&filter=emri:ilike:${emri}`;
  }
  if (sort) {
    queryString += `&sort=vitiAkademikAktiv:${sort}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (pedagogPergjegjesId && pedagogPergjegjesId > -1) {
    queryString += `&filter=pedagogPergjegjesId:eq:${pedagogPergjegjesId}`;
  }
  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (kategoria) {
    queryString += `&filter=kategoria:eq:${kategoria}`;
  }
  return queryString;
};
