import { OrariParams } from '../../../../interfaces/orari-interface';

export const getOrariUrl = ({
  start,
  end,
  klasaId,
  grupGjyqId,
  pedagogId,
  studentId,
  size,
  page,
}: OrariParams) => {
  let url = `/orari-mesimor?size=${size}&page=${page}&filter=data:gte:${start},data:lte:${end}`;

  if (klasaId) {
    url += `,klasaId:eq:${klasaId}`;
  }
  if (grupGjyqId) {
    url += `,grupGjyqId:eq:${grupGjyqId}`;
  }
  if (pedagogId) {
    url += `,pedagogId:eq:${pedagogId}`;
  }
  if (studentId) {
    url += `,studentId:eq:${studentId}`;
  }
  return { url, method: 'GET' };
};
