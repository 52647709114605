import { StudentParamsProps } from '../../../../redux/features/studenti/studentiApi';

export const getStudentUrl = (params: StudentParamsProps) => {
  const {
    size,
    personiNumriPersonal,
    personiEmri,
    vitiStudimit,
    konfirmuar,
    vitiAkademikId,
    page,
    profili,
  } = params;
  let queryString = `/studenti?size=${size}&page=${page}&sort=personiEmri:asc`;
  if (personiNumriPersonal) {
    queryString += `&filter=personiNumriPersonal:ilike:${personiNumriPersonal}`;
  }
  if (personiEmri) {
    queryString += `&filter=personiEmri:ilike:${personiEmri}`;
  }
  if (vitiStudimit) {
    queryString += `&filter=vitiStudimit:ilike:${vitiStudimit}`;
  }
  if (vitiAkademikId) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (konfirmuar !== undefined) {
    queryString += `&filter=konfirmuar:eq:${konfirmuar}`;
  }
  if (profili) {
    queryString += `&filter=profiliEmri:eq:${profili}`;
  }
  return queryString;
};
