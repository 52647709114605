import { FormField } from '../../interfaces/FormInterfaces';
import { PedagoguAktualData } from '../../interfaces/pedagoget-interface';
import { StudentiAktualData } from '../../interfaces/student-interface';
import { FORM_FIELD } from '../../utilities/enums';

export const UserInformationInitialValues = (
  studentiAktual?: StudentiAktualData,
  pedagoguAktual?: PedagoguAktualData,
) => {
  const inputStyling =
    'text-sm rounded block w-full p-2.5 border-blue-900 font-normal';

  const userInformation: FormField[] = [
    {
      name: 'emri',
      type: FORM_FIELD.INPUT,
      label: `Emri`,
      otherprops: {
        className: `${inputStyling}`,
      },
      disabled: true,
    },
    {
      name: 'mbiemri',
      type: FORM_FIELD.INPUT,
      label: `Mbiemri`,
      otherprops: {
        className: `${inputStyling}`,
      },
      disabled: true,
    },
    {
      name: 'numriPersonal',
      type: FORM_FIELD.INPUT,
      label: `Numri Personal`,
      otherprops: {
        className: `${inputStyling}`,
      },
      disabled: true,
    },
    {
      name: 'email',
      type: FORM_FIELD.INPUT,
      label: `Email`,
      otherprops: {
        className: `${inputStyling}`,
      },
      disabled: true,
    },
  ];

  if (pedagoguAktual) {
    userInformation.push(
      {
        name: 'pedagogLloji',
        type: FORM_FIELD.INPUT,
        label: `Lloji Pedagog`,
        otherprops: {
          className: `${inputStyling}`,
        },
        disabled: true,
      },
      {
        name: 'aktiv',
        type: FORM_FIELD.CHECKBOX,
        label: `Aktiv`,
        disabled: true,
      },
    );
  }
  if (studentiAktual) {
    userInformation.push(
      {
        name: 'vitiStudimit',
        type: FORM_FIELD.INPUT,
        label: `Viti i studimit`,
        otherprops: {
          className: `${inputStyling}`,
        },
        disabled: true,
      },
      {
        name: 'konfirmuar',
        type: FORM_FIELD.CHECKBOX,
        label: `Konfirmuar`,
        disabled: true,
      },
    );
  }
  return userInformation;
};
