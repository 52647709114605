import { ProfiliApiProps } from '../../../../redux/features/profili/profiliApi';

export const getProfiliApiUrl = (params: ProfiliApiProps) => {
  const { page, size, sort, name } = params;
  let queryString = `/profili`;

  if (size) {
    queryString += `?size=${size}&page=${page}`;
    if (name) {
      queryString += `&filter=emri:ilike:${name}`;
    }
    if (sort) {
      queryString += `&sort=emri:${sort}`;
    }
  } else {
    if (name) {
      const separator = sort ? '&' : '?';
      queryString += `${separator}filter=emri:ilike:${name}`;
    }
    if (sort) {
      const separator = name ? '&' : '?';
      queryString += `${separator}sort=emri:${sort}`;
    }
  }

  return queryString;
};
