import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';
import {
  AuditResponse,
  AuditUrlType,
} from '../../../interfaces/audit-interface';
import { getAuditUrl } from '../../../components/common/common-functions/commonApiUrl/get-audit-url';

const { test } = getConfig();
export interface UsersParams extends Partial<PaginationProps> {
  email: string;
  aktiv?: boolean;
  sort: 'asc' | 'desc';
  vitiFillimit?: string;
}

export const auditApi = createApi({
  reducerPath: 'audit',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + '/',
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['allAudits'],
  keepUnusedDataFor: 0,
  endpoints: builder => ({
    getAudits: builder.query<PaginationReturn<AuditResponse>, AuditUrlType>({
      query: args => ({
        url: getAuditUrl(args),
        method: 'GET',
      }),
      providesTags: ['allAudits'],
    }),
  }),
});

export const { useGetAuditsQuery } = auditApi;
