import { VleresimeKlinikaParams } from './../../../../interfaces/vleresime.interface';
import {
  AllStudentsParams,
  ContinousEvaluationParams,
  VleresimeGjyqParams,
  VleresimeParams,
} from '../../../../interfaces/vleresime.interface';

export const getVleresimeApiUrl = (
  { page, size, id, emri, mbiemri, klasaId }: VleresimeParams,
  url = 'lendes',
) => {
  let queryString = `/klasa-student/sipas-${url}/${id}/te-pavleresuar?size=${size}&page=${page}&sort=emriStudenti:asc`;

  if (emri) {
    queryString += `&filter=emriStudenti:ilike:${emri}`;
  }
  if (mbiemri) {
    queryString += `&filter=emriStudenti:ilike:${mbiemri}`;
  }
  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }

  return queryString;
};

export const getAllStudentUrl = (
  {
    page,
    size,
    emri,
    mbiemri,
    klasaStudentId,
    lendaAkademikeId,
    vitiAkademikId,
    klasaId,
    lendeGjyqId,
    llojiKlasa,
  }: AllStudentsParams,
  url = '',
) => {
  const addedUrl = url.length > 0 ? url : '';
  let queryString = `/klasa-student/${addedUrl}?size=${size}&page=${page}&filter=llojiKlasa:eq:${llojiKlasa}`;

  if (emri) {
    queryString += `&filter=emriStudenti:ilike:${emri}`;
  }
  if (mbiemri) {
    queryString += `&filter=emriStudenti:ilike:${mbiemri}`;
  }
  if (klasaStudentId && klasaStudentId > -1) {
    queryString += `&filter=klasaStudentId:eq:${klasaStudentId}`;
  }
  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (lendaAkademikeId && lendaAkademikeId > -1) {
    queryString += `&filter=lendaAkademikeId:eq:${lendaAkademikeId}`;
  }
  if (lendeGjyqId && lendeGjyqId > -1) {
    queryString += `&filter=lendeGjyqId:eq:${lendeGjyqId}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (llojiKlasa) {
    queryString += `&filter=llojiKlasa:eq:${llojiKlasa}`;
  }

  return queryString;
};

export const getContinousEvaluationUrl = (
  {
    klasaId,
    page,
    size,
    emri,
    id,
    vitiAkademikId,
    studentiId,
  }: ContinousEvaluationParams,
  url = 'vazhdues-sipas-lendes/per-pedagog',
) => {
  let queryString = `/vleresime-student/${id}/${url}?size=${size}&page=${page}&sort=emriStudenti:asc`;

  if (emri) {
    queryString += `&filter=emriStudenti:ilike:${emri}`;
  }

  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (studentiId && studentiId > -1) {
    queryString += `&filter=klasaStudentId:eq:${studentiId}`;
  }

  return queryString;
};

export const getVleresimeGjyqApiUrl = (
  {
    page,
    size,
    klasaId,
    vitiAkademikId,
    klasaStudentId,
    lendeGjyqId,
  }: VleresimeGjyqParams,
  url = '',
  sort = '&sort=emriStudenti:asc',
) => {
  let queryString = `/vleresime-gjyq${url}?size=${size}&page=${page}${sort}&filter=vitiAkademikId:eq:${vitiAkademikId}`;

  if (klasaId && klasaId > -1) {
    queryString += `&filter=klasaId:eq:${klasaId}`;
  }
  if (klasaStudentId && klasaStudentId > -1) {
    queryString += `&filter=klasaStudentId:eq:${klasaStudentId}`;
  }
  if (lendeGjyqId && lendeGjyqId > -1) {
    queryString += `&filter=lendeGjyqId:eq:${lendeGjyqId}`;
  }

  return queryString;
};

export const getVleresimeKlinika = (
  {
    page,
    size,
    lendeGjyqKlinikaId,
    studentiId,
    vitiAkademikId,
  }: VleresimeKlinikaParams,
  role = 'mentor',
) => {
  let queryString = `/vleresime-klinika-ligjore/${role}?size=${size}&page=${page}`;

  if (studentiId && studentiId > -1) {
    queryString += `&filter=studentiId:eq:${studentiId}`;
  }
  if (vitiAkademikId && vitiAkademikId > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }
  if (lendeGjyqKlinikaId && lendeGjyqKlinikaId > -1) {
    queryString += `&filter=lendeGjyqKlinikaId:eq:${lendeGjyqKlinikaId}`;
  }

  return queryString;
};
