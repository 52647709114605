import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  LoginData,
  SignUpData,
  UserData,
  Fjalekalimi,
  UserForPedagogProps,
  FjalekalimiUser,
  editUserAttributes,
  CreateStaffProps,
} from '../../../interfaces/Auth';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { getUsersUrl } from '../../../components/common/common-functions/commonApiUrl/get-users-url';
import { getPrepareHeaders } from '../get-prepare-headers';
import {
  Perdorues,
  PerdoruesPerPedagog,
} from '../../../interfaces/pedagoget-interface';
import { getPerdoruesPerPedagog } from '../../../components/common/common-functions/commonApiUrl/get-pedagogu-url';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';

const { test } = getConfig();
export interface UsersParams extends Partial<PaginationProps> {
  email: string;
  aktiv?: boolean;
  sort: 'asc' | 'desc';
  vitiFillimit?: string;
}

type ParticipantsQueryType = {
  page: number;
  size: number;
  emriPersoni: string | undefined;
};

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/auth`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['User', 'GetAllUsers', 'loggedInUser'],
  endpoints: builder => ({
    login: builder.mutation<{ jwt: string }, LoginData>({
      query: data => ({
        url: `/login`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    signUp: builder.mutation<{ jwt: string }, SignUpData>({
      query: data => ({
        url: `/signup`,
        method: 'POST',
        body: data,
      }),
    }),
    createStaf: builder.mutation<{ jwt: string }, CreateStaffProps>({
      query: body => ({
        url: `/perdorues/staf`,
        method: 'POST',
        body,
      }),
    }),
    getAllUsers: builder.query<PaginationReturn<UserData>, UsersParams>({
      query: args => ({
        url: getUsersUrl(args),
        method: 'GET',
      }),
      providesTags: ['GetAllUsers'],
    }),
    getSingleUser: builder.query<UserData, { id: number }>({
      query: id => ({
        url: `/perdorues/${id}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    activateUser: builder.mutation<{ id: number }, UsersParams>({
      query: data => ({
        url: 'aktivizo-perdorues',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllUsers'],
    }),
    resetPassword: builder.mutation<number, Fjalekalimi>({
      query: data => ({
        url: `/ndrysho-fjalekalimin`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllUsers', 'loggedInUser'],
    }),
    getLoggedInUser: builder.query<UserData, void>({
      query: () => ({
        url: `/merr-perdoruesin-e-loguar`,
        method: 'GET',
      }),
      providesTags: ['loggedInUser'],
    }),
    merrPerdoruesPerPedagog: builder.query<
      PaginationReturn<PerdoruesPerPedagog>,
      UserForPedagogProps
    >({
      query: args => getPerdoruesPerPedagog(args),
    }),
    getUserForParticipants: builder.query<
      PaginationReturn<Perdorues>,
      ParticipantsQueryType
    >({
      query: args => {
        let url = `/perdorues/per-pjesemarres?page=${args.page}&sort=emriPersoni:asc`;

        if (args.size) {
          url += `&size=${args.size}`;
        }
        if (args.emriPersoni) {
          url += `&filter=emriPersoni:ilike:${args.emriPersoni}`;
        }
        return { url, method: 'GET' };
      },
    }),
    resetPasswordUser: builder.mutation<
      number,
      UpdateKlasaStudent<Partial<FjalekalimiUser>>
    >({
      query: ({ body, id }) => ({
        url: `/perdorues/reset-password/${id}/per-perdorues`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['GetAllUsers', 'loggedInUser'],
    }),
    editUserAttributes: builder.mutation<
      number,
      UpdateKlasaStudent<Partial<editUserAttributes>>
    >({
      query: ({ body, id }) => ({
        url: `/perditeso-perdorues/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['GetAllUsers'],
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGetAllUsersQuery,
  useActivateUserMutation,
  useResetPasswordMutation,
  useGetSingleUserQuery,
  useGetLoggedInUserQuery,
  useLazyMerrPerdoruesPerPedagogQuery,
  useLazyGetUserForParticipantsQuery,
  useResetPasswordUserMutation,
  useEditUserAttributesMutation,
  useCreateStafMutation,
} = authApi;
