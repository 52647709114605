export enum FORM_FIELD {
  INPUT = 'input',
  PASSWORD = 'password',
  ANTD_SELECT = 'antd-select',
  TEXT_AREA = 'text-area',
  INPUT_NUMBER = 'input-number',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  TIME = 'time',
  UPLOAD = 'upload',
}

export enum Qytetet {
  TIRANE = 'Tirane',
  DURRES = 'Durres',
  ELBASAN = 'Elbasan',
  SHKODER = 'Shkoder',
  KORCE = 'Korce',
  VLORE = 'Vlore',
  DIBER = 'Diber',
  LUSHNJE = 'Lushnje',
  GJIROKASTER = 'Gjirokaster',
  FIER = 'Fier',
  BERAT = 'Berat',
  POGRADEC = 'Pogradec',
  SARANDE = 'Sarande',
  PESHKOPI = 'Peshkopi',
  KUKES = 'Kukes',
  LEZHE = 'Lezhe',
  BURREL = 'Burrel',
  KAVAJE = 'Kavaje',
  PERMET = 'Permet',
  GRAMSH = 'Gramsh',
  LIBRAZHD = 'Librazhd',
  DEVOLL = 'Devoll',
  KUCOVE = 'Kucove',
  MALLAKASTER = 'Mallakaster',
  MALESI_E_MADHE = 'Malesi e Madhe',
  DELIVE = 'Delvine',
  MIRDITE = 'Mirdite',
  LAC = 'Lac',
  BALLSH = 'Ballsh',
  BILISHT = 'Bilisht',
  BULQIZE = 'Bulqize',
  ERSEKE = 'Erseke',
  HAS = 'Has',
  KOPLIK = 'Koplik',
  KRUJE = 'Kruje',
  PEQIN = 'Peqin',
  PUKE = 'Puke',
  SKRAPAR = 'Skrapar',
  TEPELENE = 'Tepelene',
  TROPOJE = 'Tropoje',
}

export enum StatusRezultateFaza {
  plotesuar = 'PLOTESUAR',
  filluar = 'FILLUAR',
  hedhurRezultate = 'HEDHUR_REZULTATE',
  perfunduar = 'PERFUNDUAR',
}

export enum PedagoguLloji {
  iBrendshem = 'I_BRENDSHEM',
  iJashtem = 'I_JASHTEM',
  specialist = 'SPECIALIST',
  asistent = 'ASISTENT',
  neDispozicion = 'NE_DISPOZICION',
}

export enum TipologjiaDetyres {
  detyreMeVleresim = 'DETYRE_ME_VLERESIM',
  detyreKurrikulare = 'DETYRE_KURRIKULARE',
}

export enum LlojiKlasa {
  klase = 'KLASE',
  grupGjyq = 'GRUP_GJYQ',
  grupKlinikeLigjore = 'GRUP_KLINIKE_LIGJORE',
}

export enum LendeGjyqKategoria {
  gjyqCivil = 'GJYQ_CIVIL',
  gjyqPenal = 'GJYQ_PENAL',
  klinikeCivile = 'KLINIKE_CIVILE',
  klinikePenale = 'KLINIKE_PENALE',
  klinikeAdministrative = 'KLINIKE_ADMINISTRATIVE',
  klinikeKushtetuese = 'KLINIKE_KUSHTETUESE',
  klinikeFamiljare = 'KLINIKE_FAMILJARE',
}

export enum ActionName {
  lendaAkademike = 'LENDA_AKADEMIKE',
  lenda_gjyq_imitues = 'LENDA_GJYQ_IMITUES',
  lenda_klinike_ligjore = 'LENDA_KLINIKA_LIGJORE',
  lendaTema = 'LENDA_TEMA',
  temaLendeAkademike = 'TEMA_LENDE_AKADEMIKE',
  temaGjyqImitues = 'TEMA_GJYQ_IMITUES',
  temaKlinikeLigjore = 'TEMA_KLINIKE_LIGJORE',
  evidenca = 'EVIDENCA',
  evidencaLendeAkademike = 'EVIDENCA_LENDE_AKADEMIKE',
  evidencaGjyqImitues = 'EVIDENCA_GJYQ_IMITUES',
  evidencaKlinikeLigjore = 'EVIDENCA_KLINIKE_LIGJORE',
  listePrezenca = 'LISTE_PREZENCA',
  pedagog = 'PEDAGOG',
  student = 'STUDENT',
  klasa = 'KLASA',
  klasaLendeAkademike = 'KLASA_LENDA_AKADAMIKE',
  grupGjyq = 'GRUP_GJYQ',
  grupKlinikaLigjore = 'GRUP_KLINIKA_LIGJORE',
  materialeLendeAkademike = 'MATERIALE_LENDE_AKADEMIKE',
  materialeGjyqImitues = 'MATERIALE_GJYQ_IMITUES',
  materialeKlinikeLigjore = 'MATERIALE_KLINIKE_LIGJORE',
  vleresimeStudent = 'VLERESIME_STUDENT',
  vleresimeGjyq = 'VLERESIME_GJYQ',
  vleresimeKlinikaLigjore = 'VLERESIME_KLINIKA_LIGJORE',
  pjesemarres = 'PJESEMARRES',
  institucioni = 'INSTITUCIONI',
  trajnuesi = 'TRAJNUES',
  trajnim = 'TRAJNIM',
  trajnimProgramTematik = 'TRAJNIM_PROGRAM_TEMATIK',
  trajnimKalendar = 'TRAJNIM_KALENDAR',
  verifikim = 'VERIFIKIM',
}

export enum ActionType {
  krijim = 'KRIJIM',
  perditesim = 'PERDITESIM',
  fshirje = 'FSHIRJE',
  updateListePrezence = 'PERDITESIMI_I_PREZENCAVE_TE_STUDENTEVE_PER_KLASE',
  updateTopics = 'PERDITESIMI_I_TEMAVE_PER_LENDE_AKADEMIKE',
  updateTeachers = 'PERDITESIMI_I_PEDAGOGEVE_PER_LENDE_AKADEMIKE',
  mbylljaVleresimitVazhdues = 'MBYLLJA_E_VLERESIMIT_VAZHDUES',
  mbylljaProvimitPerfundimtar = 'MBYLLJA_E_VLERESIMIT_TE_PROVIMIT_PERFUNDIMTAR',
  fshirjaTemave = 'FSHIRJA_E_TEMAVE_PER_LENDE_AKADEMIKE',
  fshirjaPedagogeve = 'FSHIRJA_E_PEDAGOGEVE_PER_LENDE_AKADEMIKE',
  krijimPedagoguPerPerdoruesEkzistues = 'KRIJIM_PEDAGOGU_PER_PERDORUES_EKZISTUES',
  krijimPedagoguPerPerdoruesTeRi = 'KRIJIM_PEDAGOGU_PER_PERDORUES_TE_RI',
  krijimKandidat = 'KRIJIM_I_KANDIDATEVE',
  mbylljaRegjistrimevePerKlase = 'MBYLLJA_E_REGJISTRIMEVE_PER_KLASE',
  shtimStudentPerKlase = 'SHTIM_I_STUDENTEVE_PER_KLASE',
  shtimLendePerKlase = 'SHTIM_I_LENDEVE_PER_KLASE',
  fshirjaStudentPerKlase = 'FSHIRJA_E_STUDENTEVE_PER_KLASE',
  fshirjaLendePerKlase = 'FSHIRJA_E_LENDEVE_PER_KLASE',
  krijimVleresimVazhdues = 'KRIJIM_VLERESIM_VAZHDUES',
  perditesimVleresimVazhdues = 'PERDITESIM_VLERESIM_VAZHDUES',
  krijimVleresimProvimi = 'KRIJIM_VLERESIM_PROVIMI',
  perditesimVleresimProvimi = 'PERDITESIM_VLERESIM_PROVIMI',
  updateListePrezenceGrupGjyq = 'PERDITESIMI_I_PREZENCAVE_TE_STUDENTEVE_PER_GRUP_GJYQ',
  updateListePrezenceKlinikeLigjore = 'PERDITESIMI_I_PREZENCAVE_TE_STUDENTEVE_PER_GRUP_KLINIKE_LIGJORE',
  updateStudentPerKlase = 'PERDITESIM_I_STUDENTEVE_PER_KLASE',
  updateStudentPerGrupGjyq = 'PERDITESIM_I_STUDENTEVE_PER_GRUP_GJYQ',
  updateStudentPerKlinikeLigjore = 'PERDITESIM_I_STUDENTEVE_PER_GRUP_KLINIKE_LIGJORE',
  mbylljaVleresimitLendeGjyq = 'MBYLLJA_E_VLERESIMIT_LENDA_GJYQ',
  mbylljaRegjistrimevePerGrupGjyq = 'MBYLLJA_E_REGJISTRIMEVE_PER_GRUP_GJYQ',
  mbyllaRegjistrimeveKlinikeLigjore = 'MBYLLJA_E_REGJISTRIMEVE_PER_GRUP_KLINIKE_LIGJORE',
  updateLendePerKlase = 'PERDITESIM_I_LENDEVE_PER_KLASE',
  fshirjaStudentPerGrup = 'FSHIRJA_E_STUDENTEVE_PER_GRUP',
  fshirjaStudentPerGrupGjyq = 'FSHIRJA_E_STUDENTEVE_PER_GRUP_GJYQ',
  fshirjaStudentPerKlinikeLigjore = 'FSHIRJA_E_STUDENTEVE_PER_GRUP_KLINIKE_LIGJORE',
  krijimVleresimMentor = 'KRIJIM_VLERESIM_MENTOR',
  perditesimVleresimMentor = 'PERDITESIM_VLERESIM_MENTOR',
  krijimPerditesimVleresimKomisioni = 'KRIJIM_PERDITESIM_VLERESIM_KOMISIONI',
  krijimPjesemarresiPerPerdoruesEkzistues = 'KRIJIM_PJESEMARRESI_PER_PERDORUES_EKZISTUES',
  krijimPjesemarresiPerPerdoruesTeRi = 'KRIJIM_PJESEMARRESI_PER_PERDORUES_TE_RI',
  pergatitProgramTematik = 'PERGATIT_PROGRAM_TEMATIK',
  krijimProcesShm = 'KRIJIM_PROCES_SHM',
  aprovimProgramTematik = 'APROVIM_PROGRAM_TEMATIK',
  krijimKalendari = 'KRIJIM_KALENDARI',
  publikoKalendar = 'PUBLIKO_KALENDAR',
  aplikimSiPjesemarresNeTrajnim = 'APLIKIM_SI_PJESEMARRES_NE_TRAJNIM',
  anullimRegjistrimi = 'ANULLIM_REGJISTRIMI',
  aplikimsiTrajnuesNeTrajnim = 'APLIKIM_SI_TRAJNUES_NE_TRAJNIM',
  fshirjeAplikimiPerTrajnuesNeTrajnim = 'FSHIRJE_APLIKIMI_PER_TRAJNUES_NE_TRAJNIM',
  propozimTrajnimi = 'PROPOZIM_TRAJNIMI',
  aprovoTrajnim = 'APROVO_TRAJNIM',
  shtoTrajnimPrezenca = 'SHTO_TRAJNIM_PREZENCA',
  perditesoTrajnimPrezenca = 'PERDITESO_TRAJNIM_PREZENCA',
  krijimDokumentTrajnimi = 'KRIJIM_DOKUMENT_TRAJNIMI',
  fshirjeDokumentTrajnimi = 'FSHIRJE_DOKUMENT_TRAJNIMI',
}

export enum MuajViti {
  'Janar',
  'Shkurt',
  'Mars',
  'Prill',
  'Maj',
  'Qershor',
  'Korrik',
  'Gusht',
  'Shtator',
  'Tetor',
  'Nëntor',
  'Dhjetor',
}
