import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';
import {
  CreateNewEvidenceProps,
  EvidenceBody,
  UpdateEvidenca,
} from '../../../interfaces/evidenca-interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import { EvidenceProps } from '../../../interfaces/evidenca-interface';
import { ListePrezencaParams } from '../../../interfaces/liste-prezenca.interface';
import {
  eksportEvidencaUrl,
  getListePrezenceUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-liste-prezence-url';
import { UpdateProps } from '../../../interfaces/common-interfaces';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();

export const evidencaApi = createApi({
  reducerPath: 'evidencaApi',
  tagTypes: ['OrariEvidenca', 'getAllEvidenca'],
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    getEvidenca: builder.query<
      PaginationReturn<EvidenceProps>,
      ListePrezencaParams
    >({
      query: args => getListePrezenceUrl(args, 'evidenca'),
      providesTags: ['getAllEvidenca', 'OrariEvidenca'],
    }),
    getOrariEvidencaById: builder.query<EvidenceBody[], number>({
      query: id => ({
        url: `/evidenca/${id}/orari-mesimor`,
        method: 'GET',
      }),
      providesTags: ['OrariEvidenca'],
    }),
    createEvidence: builder.mutation<number, CreateNewEvidenceProps>({
      query: body => ({
        url: '/evidenca',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OrariEvidenca'],
    }),
    updateEvidence: builder.mutation<number, UpdateProps<UpdateEvidenca>>({
      query: ({ data, id }) => ({
        url: `/evidenca/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['OrariEvidenca', 'getAllEvidenca'],
    }),
    deleteEvidenca: builder.mutation<string, number>({
      query: id => ({
        url: `/evidenca/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['OrariEvidenca', 'getAllEvidenca'],
    }),
    getEvidencaPerPedagog: builder.query<
      PaginationReturn<EvidenceProps>,
      ListePrezencaParams
    >({
      query: args => getListePrezenceUrl(args, 'evidenca/per-pedagog'),
      providesTags: ['getAllEvidenca', 'OrariEvidenca'],
    }),
    exportEvidenca: builder.query<Blob, any>({
      query: args => ({
        url: eksportEvidencaUrl(args, 'evidenca/eksport'),
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
    }),
  }),
});

export const {
  useGetOrariEvidencaByIdQuery,
  useCreateEvidenceMutation,
  useUpdateEvidenceMutation,
  useGetEvidencaQuery,
  useDeleteEvidencaMutation,
  useGetEvidencaPerPedagogQuery,
  useLazyExportEvidencaQuery,
} = evidencaApi;
