import React from 'react';
import { Form, Input } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const InputField = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props.rules}>
      <Input
        placeholder={props.placeholder}
        {...props.otherprops}
        disabled={props.disabled}
        autoComplete="none"
      />
    </Form.Item>
  );
};

export default InputField;
