import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';
import getConfig from '../../../helpers/config';
import { RaporteParams } from '../../../interfaces/reports-interface';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
const { test } = getConfig();

export const reportsApi = createApi({
  reducerPath: 'report',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    getAttendanceReportStudent: builder.query<Blob, number>({
      query: id => ({
        url: `/klasa-student/${id}/raportim-per-prezence`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
    }),
    getAttendancePerClassReport: builder.query<Blob, RaporteParams>({
      query: ({ klasaId, lendaAkademikeId }) => ({
        url: `/klasa-student/${klasaId}/raportim-per-klase?lendaAkademikeId=${lendaAkademikeId}`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
    }),
  }),
});

export const {
  useLazyGetAttendancePerClassReportQuery,
  useLazyGetAttendanceReportStudentQuery,
} = reportsApi;
