import { FormField } from '../../interfaces/FormInterfaces';
import { FORM_FIELD } from '../../utilities/enums';

export const resetPasswordFields: FormField[] = [
  {
    name: 'fjalekalimiAktual',
    type: FORM_FIELD.PASSWORD,
    rules: [
      {
        required: true,
        message: `Ju lutem vendosni fjalëkalimin!`,
      },
    ],
    label: 'Fjalëkalimi aktual',
    otherprops: {
      className: `border-blue-900 p-2.5 loginStyle rounded`,
    },
  },
  {
    name: 'fjalekalimiRi',
    type: FORM_FIELD.PASSWORD,
    rules: [
      {
        required: true,
        message: 'Ju lutem vendosni fjalekalimin e ri!',
      },
      {
        pattern:
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,\/#(){}:^-])[A-Za-z\d@$!%*?&.,\/#(){}:^-]{8,}$/,
        message:
          'Fjalekalimi duhet te permbaje te pakten 8 karaktere, ku ndodhet te pakten 1 shkronje e vogel, 1 shkronje kapitale, 1 numer dhe 1 karakter special!',
      },
    ],
    label: 'Fjalëkalimi i ri',
    otherprops: {
      className: 'border-blue-900 p-2.5 loginStyle rounded',
    },
  },
  {
    name: 'fjalekalimiPerseritur',
    type: FORM_FIELD.PASSWORD,
    rules: [
      {
        required: true,
        message: 'Ju lutem përsërisni fjalëkalimin!',
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('fjalekalimiRi') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Fjalëkalimi nuk përputhet!'));
        },
      }),
    ],
    label: 'Konfirmo fjalëkalimin',
    otherprops: {
      className: 'border-blue-900 p-2.5 loginStyle rounded',
    },
  },
];
