import { UsersParams } from '../../../../redux/features/auth/authApi';

export const getAcademicApiUrl = (params: Partial<UsersParams>) => {
  const { page, size, aktiv, vitiFillimit } = params;
  let queryString = `/viti-akademik?size=${size}&page=${page}&sort=vitiFillimit:desc`;

  if (aktiv) {
    queryString += `&filter=aktiv:eq:${aktiv}`;
  }
  if (vitiFillimit) {
    queryString += `&filter=vitiFillimit:gte:${vitiFillimit}`;
  }
  return queryString;
};
