import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';

const { test } = getConfig();

export const verifikimeApi = createApi({
  reducerPath: 'verifikimi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Verifikimi', 'GetAllVerifikime'],
  endpoints: builder => ({
    createVerifikime: builder.mutation<
      { id: number },
      { id: number; data: { statusVerifikimi: string; komente: string } }
    >({
      query: args => ({
        url: `/verifikimi/${args.id}`,
        method: 'POST',
        body: args.data,
      }),
      invalidatesTags: ['Verifikimi'],
    }),
  }),
});
export const { useCreateVerifikimeMutation } = verifikimeApi;
