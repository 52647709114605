import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';
import {
  ListPrezencaProps,
  ListePrezencaByIDProps,
  ListePrezencaParams,
} from '../../../interfaces/liste-prezenca.interface';
import { getListePrezenceUrl } from '../../../components/common/common-functions/commonApiUrl/get-liste-prezence-url';
import { PaginationReturn } from '../../../interfaces/pagination';

const { test } = getConfig();

type ListePrezenca = PaginationReturn<ListPrezencaProps>;

export const listePrezencaApi = createApi({
  reducerPath: 'listePrezencaApi',
  tagTypes: ['OrariListePrezenca', 'ListePrezenca'],
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    getListPrezenca: builder.query<ListePrezenca, ListePrezencaParams>({
      query: args => getListePrezenceUrl(args),
      providesTags: ['ListePrezenca'],
    }),
    getListPrezencaStudents: builder.query<ListePrezenca, ListePrezencaParams>({
      query: args => getListePrezenceUrl(args, 'liste-prezenca/per-student'),
      providesTags: ['ListePrezenca'],
    }),
    getListePrezencaById: builder.query<ListePrezencaByIDProps, number>({
      query: id => ({
        url: `/liste-prezenca/${id}`,
        method: 'GET',
      }),
      providesTags: ['OrariListePrezenca'],
    }),
    createListpezenca: builder.mutation<any, any>({
      query: ({ id, postData }) => ({
        url: `/liste-prezenca/${id}/klasa-student`,
        method: 'PUT',
        body: postData,
      }),
      invalidatesTags: ['OrariListePrezenca', 'ListePrezenca'],
    }),
    getListePrezencaPerPedagog: builder.query<
      ListePrezenca,
      ListePrezencaParams
    >({
      query: args => ({
        url: getListePrezenceUrl(args, 'liste-prezenca/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['ListePrezenca'],
    }),
  }),
});

export const {
  useGetListPrezencaQuery,
  useGetListPrezencaStudentsQuery,
  useGetListePrezencaByIdQuery,
  useCreateListpezencaMutation,
  useGetListePrezencaPerPedagogQuery,
} = listePrezencaApi;
