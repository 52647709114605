import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';
import {
  AllStudentsParams,
  CreateVleresimeParams,
  AllStudentsProps,
  VleresimeParams,
  UpdateFinalExamParams,
  VleresimeResponse,
  ContinousEvaluationParams,
} from '../../../interfaces/vleresime.interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import {
  getAllStudentUrl,
  getContinousEvaluationUrl,
  getVleresimeApiUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-vleresime-url';

const { test } = getConfig();

export type StudentsResponse = PaginationReturn<AllStudentsProps>;
type VleresimeType = PaginationReturn<VleresimeResponse>;

export const vleresimeApi = createApi({
  reducerPath: 'vleresime',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['getAllVleresime', 'getAllEvalStudents'],
  endpoints: builder => ({
    getAllUnevaluatedStudents: builder.query<StudentsResponse, VleresimeParams>(
      {
        query: args => ({
          url: getVleresimeApiUrl(args),
          method: 'GET',
        }),
        providesTags: ['getAllEvalStudents'],
      },
    ),
    getAllContinousEvaluations: builder.query<
      VleresimeType,
      ContinousEvaluationParams
    >({
      query: args => ({
        url: getContinousEvaluationUrl(args),
        method: 'GET',
      }),
      providesTags: ['getAllVleresime'],
    }),
    getAllContinousEvaluationsForFF: builder.query<
      VleresimeType,
      ContinousEvaluationParams
    >({
      query: args => ({
        url: getContinousEvaluationUrl(args, 'vazhdues-sipas-lendes'),
        method: 'GET',
      }),
      providesTags: ['getAllVleresime'],
    }),
    getAllExamEvaluations: builder.query<
      VleresimeType,
      ContinousEvaluationParams
    >({
      query: args => ({
        url: getContinousEvaluationUrl(args, 'provim-sipas-lendes/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['getAllVleresime'],
    }),
    getAllExamEvaluationsFF: builder.query<
      VleresimeType,
      ContinousEvaluationParams
    >({
      query: args => ({
        url: getContinousEvaluationUrl(args, 'provim-sipas-lendes'),
        method: 'GET',
      }),
      providesTags: ['getAllVleresime'],
    }),
    getAllKlasaStudents: builder.query<StudentsResponse, AllStudentsParams>({
      query: args => ({
        url: getAllStudentUrl(args, 'te-gjithe/simplified'),
        method: 'GET',
      }),
      providesTags: ['getAllEvalStudents'],
    }),
    getAllKlasaStudentsForPedagog: builder.query<
      StudentsResponse,
      AllStudentsParams
    >({
      query: args => ({
        url: getAllStudentUrl(args, 'te-gjithe/simplified/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['getAllEvalStudents'],
    }),
    getAllMockTrialStudents: builder.query<StudentsResponse, AllStudentsParams>(
      {
        query: args => ({
          url: getAllStudentUrl(args, '/gjyq'),
          method: 'GET',
        }),
        providesTags: ['getAllEvalStudents'],
      },
    ),
    getAllMockTrialStudentsForPedagog: builder.query<
      StudentsResponse,
      AllStudentsParams
    >({
      query: args => ({
        url: getAllStudentUrl(args, '/te-gjithe/simplified/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['getAllEvalStudents'],
    }),
    createVleresime: builder.mutation<
      number,
      UpdateKlasaStudent<CreateVleresimeParams>
    >({
      query: ({ body, id }) => ({
        url: `/vleresime-student/${id}/vazhdues`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['getAllVleresime', 'getAllEvalStudents'],
    }),
    updateVleresime: builder.mutation<
      number,
      UpdateKlasaStudent<Partial<CreateVleresimeParams>>
    >({
      query: ({ body, id }) => ({
        url: `/vleresime-student/${id}/vazhdues`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['getAllVleresime'],
    }),
    updateFinalExam: builder.mutation<number, UpdateFinalExamParams>({
      query: ({ body, klasaStudentId, lendaAkademikeId }) => ({
        url: `vleresime-student/${klasaStudentId}/provim?id=${lendaAkademikeId}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['getAllVleresime'],
    }),
    closeFinalExam: builder.mutation<number, number>({
      query: lendaAkademikeId => ({
        url: `/vleresime-student/${lendaAkademikeId}/perfundo-provim`,
        method: 'PUT',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['getAllVleresime'],
    }),
    closeContinousEvaluations: builder.mutation<number, number>({
      query: lendaAkademikeId => ({
        url: `/vleresime-student/${lendaAkademikeId}/perfundo-vazhdues`,
        method: 'PUT',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['getAllVleresime'],
    }),
  }),
});

export const {
  useLazyGetAllUnevaluatedStudentsQuery,
  useGetAllContinousEvaluationsQuery,
  useGetAllContinousEvaluationsForFFQuery,
  useGetAllExamEvaluationsQuery,
  useGetAllExamEvaluationsFFQuery,
  useLazyGetAllKlasaStudentsQuery,
  useLazyGetAllKlasaStudentsForPedagogQuery,
  useCreateVleresimeMutation,
  useUpdateVleresimeMutation,
  useUpdateFinalExamMutation,
  useCloseFinalExamMutation,
  useCloseContinousEvaluationsMutation,
} = vleresimeApi;
