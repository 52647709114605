import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';
import getConfig from '../../../helpers/config';
import { Personi } from '../../../interfaces/Auth';
import { InstitutionProps } from '../institucioni/institucioni-api';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
const { test } = getConfig();

export interface TraineesProps {
  id: number;
  key: number;
  pjesemarres: {
    id: number;
    personi: Personi;
    institucioni: InstitutionProps;
    funksioni: string;
  };
  vitiAkademik: AcademicYearProp;
}

export interface AcademicYearProp {
  id: number;
  vitiFillimit: number;
  vitiMbarimit: number;
  aktiv: boolean;
}

export interface TraineesFiltersProps extends PaginationProps {
  personEmri: string;
  personMbiemri: string;
  vitiAkademikId?: number;
  filter?: string;
}
export const trajnuesApi = createApi({
  reducerPath: 'trajnuesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Trajnues'],
  endpoints: builder => ({
    getAllTrainees: builder.query<
      PaginationReturn<TraineesProps>,
      TraineesFiltersProps
    >({
      query: args => {
        let url = `/trajnues?page=${args.page}&sort=personEmri:asc`;

        if (args.size) {
          url += `&size=${args.size}`;
        }
        if (args.personEmri) {
          url += `&filter=personEmri:ilike:${args.personEmri}`;
        }
        if (args.personMbiemri) {
          url += `&filter=personMbiemri:ilike:${args.personMbiemri}`;
        }
        if (args.vitiAkademikId) {
          url += `&filter=vitiAkademikId:eq:${args.vitiAkademikId}`;
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Trajnues'],
    }),
    createTrainees: builder.mutation<void, { pjesemarres: number }>({
      query: body => {
        return {
          url: `/trajnues?pjesemarresId=${body.pjesemarres}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Trajnues'],
    }),
    deleteTrainees: builder.mutation<void, number>({
      query: id => {
        return {
          url: `/trajnues/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Trajnues'],
    }),
  }),
});

export const {
  useGetAllTraineesQuery,
  useCreateTraineesMutation,
  useDeleteTraineesMutation,
} = trajnuesApi;
