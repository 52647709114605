import React, { useState, useEffect, useMemo, CSSProperties } from 'react';
import { Layout, Menu, MenuProps, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import getActiveTab from '../../helpers/getActiveTab';
import Account from '../../components/Account/Account';
import logo from '../../assets/images/shmlogo-blue-2.png';
import logo2 from '../../assets/images/shmlogo-blue-2 2.png';
import { ReactComponent as Mail } from '../../assets/svgIcons/mail.svg';
import {
  SidebarProps,
  SiderItemsProps,
} from '../../interfaces/sidebar-interfaces';
import { allAnetarKomisioni } from '../../constants/all-anetar-komisioni';
import { sidebarMenuOptions } from '../../constants/SidebarMenuOptions';
import { RiLockPasswordFill } from 'react-icons/ri';
import { ROLES } from '../../constants/enums/roles';
import { MdViewModule } from 'react-icons/md';
import { useGetLoggedInUserQuery } from '../../redux/features/auth/authApi';
import { useSelector } from 'react-redux';
import { UserStateProps } from '../../interfaces/modules.interface';
import { useRoleGetter } from '../../helpers/getUserInfo';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
const { Header, Sider, Content } = Layout;

export const Sidebar = ({ children }: SidebarProps) => {
  const {
    admin,
    konkurrent,
    pedagog,
    pjesmarres,
    specialistFF,
    specialistFv,
    student,
  } = ROLES;
  const { pathname } = useLocation();
  const userRole = useRoleGetter();
  const selectorRole = useSelector((state: UserStateProps) => state.activeUser);
  const navigate = useNavigate();
  const {
    data: loggedInUser,
    isFetching,
    isLoading,
  } = useGetLoggedInUserQuery(undefined, { skip: !userRole });

  const [collapsed, setCollapsed] = useState(false);
  const [sidebarItems, setSidebarItems] = useState<SiderItemsProps[]>([]);

  const role = useMemo(
    () =>
      selectorRole.length > 0
        ? selectorRole
        : (localStorage.getItem('activeUser') as ROLES | undefined),
    [selectorRole],
  );

  const getWindowWidth = () => {
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;
    if (windowWidth < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };
  useEffect(() => {
    getWindowWidth();
    window.addEventListener('resize', getWindowWidth);
    return () => {
      window.removeEventListener('resize', getWindowWidth);
    };
  }, []);

  const reorderItems = () => {
    const sidebarOptions = sidebarMenuOptions(userRole);
    if (
      loggedInUser &&
      allAnetarKomisioni.includes(role ?? loggedInUser.rolet[0])
    ) {
      const foundIdx = sidebarOptions.findIndex(
        el => el.key === '/verifikimKonkurrime',
      );
      sidebarOptions.push(...sidebarOptions.splice(0, foundIdx));
      return sidebarOptions;
    }
    return sidebarOptions;
  };

  const style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: '#003666',
      overflow: 'auto',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      zIndex: 2,
    };
  }, []);

  const headerStyles: CSSProperties = {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    backgroundColor: 'rgb(255 255 255)',
    borderBottom: '2px solid #003666',
    borderImageSlice: '1',
  };

  const defaultOpenKeys = useMemo(() => {
    if (userRole === admin) {
      return ['#'];
    } else if (userRole === specialistFv || userRole === pjesmarres) {
      return ['*', 'trajnime'];
    }

    return ['/lende-akademike'];
  }, [userRole]);

  useEffect(() => {
    if (isFetching || isLoading) {
      <Spin />;
    } else if (loggedInUser) {
      const ffRoles = [specialistFF, pedagog, student];
      const allRoles = [
        admin,
        konkurrent,
        pjesmarres,
        specialistFv,
        ...allAnetarKomisioni,
        ...ffRoles,
      ];
      const loggedInUserRole = loggedInUser.rolet[0];
      const userRoles = [];
      if (loggedInUser.firstLogIn) {
        const firstLogin = {
          key: '/ndryshoFjalekalimin',
          label: 'Ndrysho fjalëkalimin',
          roles: allRoles,
          icon: <RiLockPasswordFill className="h-5 w-5" />,
        };
        setSidebarItems([firstLogin]);
        navigate('/ndryshoFjalekalimin');
        setCollapsed(false);
      } else {
        if (loggedInUser.rolet.length > 1 && !role) {
          const modulet = {
            key: '/modulet',
            label: 'Zgjidh Modulin',
            roles: [],
            icon: <MdViewModule className="h-5 w-5" />,
          };
          userRoles.push(modulet);
          setCollapsed(true);
        } else {
          for (let index = 0; index < reorderItems().length; index++) {
            const element = reorderItems()[index];
            if (element.roles.includes(role ?? loggedInUserRole)) {
              userRoles.push(element);
            }
          }
          setCollapsed(false);
        }
        navigate(userRoles[0]?.key);

        if (
          role === admin ||
          (loggedInUserRole === admin && loggedInUser.rolet.length === 1)
        ) {
          navigate('/administrim-konkurrim');
          setCollapsed(false);
        }

        if (
          role === specialistFv ||
          (loggedInUser.rolet.includes(specialistFv) &&
            loggedInUser.rolet.length === 1)
        ) {
          navigate('/administrim-formimiVazhdues');
          setCollapsed(false);
        }
        if (
          role === pjesmarres ||
          (loggedInUserRole === pjesmarres &&
            loggedInUser.rolet.length === 1 &&
            loggedInUser.firstLogIn === false)
        ) {
          navigate('/program-tematik');
          setCollapsed(false);
        }
        if (
          ffRoles.includes(role as ROLES) ||
          ([specialistFF, pedagog, student].includes(loggedInUserRole) &&
            loggedInUser.rolet.length === 1)
        ) {
          navigate('/lende-mesimore');
          setCollapsed(false);
        }

        setSidebarItems(
          userRoles.filter(item => item.key !== '/ndryshoFjalekalimin'),
        );
      }
    } else {
      navigate('/login');
      setSidebarItems([]);
      setCollapsed(false);
    }
  }, [role, loggedInUser, isFetching, isLoading]);

  if (pathname.includes('login') || pathname.includes('redirect')) {
    return children;
  }

  const onClick: MenuProps['onClick'] = () => {
    getActiveTab({
      location: pathname,
    });
  };

  return (
    <Layout style={{ backgroundColor: '#003666', minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        style={style}
        id="sidebar1"
      >
        <div className="h-8" />
        <div className="flex justify-center bg-white">
          {collapsed ? (
            <div className="w-14 py-1" style={{ height: '62px' }}>
              <img className="object-contain" src={logo2} />
            </div>
          ) : (
            <div className="h-15">
              <img className="z-[100] object-fill py-2" src={logo} />
            </div>
          )}
        </div>

        <Menu
          selectedKeys={[pathname]}
          theme="dark"
          defaultOpenKeys={defaultOpenKeys}
          defaultSelectedKeys={['trajnime']}
          style={{
            backgroundColor: '#003666',
            paddingTop: '36px',
            color: '#FFFFFF',
          }}
          mode="inline"
          items={sidebarItems as unknown as MenuItemType[]}
          onClick={e => {
            onClick(e);
            navigate(e.key);
          }}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200,
        }}
      >
        <div
          className={`fixed top-0 z-20 w-full ${
            collapsed ? 'right-0 pl-20' : 'left-6 pl-44'
          } `}
        >
          <div
            className="flex h-8 items-center justify-end gap-2 text-base text-white"
            style={{ backgroundColor: '#003666' }}
          >
            <Mail />
            <a className="mr-12">smvshm-support@magjistratura.edu.al</a>
          </div>
          <Header
            className="site-layout-background flex flex-row items-center justify-end"
            style={headerStyles}
          >
            <div className="flex flex-row">
              <Account />
            </div>
          </Header>
        </div>
        <Content
          style={{
            overflow: 'initial',
            background: 'white',
            marginTop: '100px',
          }}
        >
          <div className="site-layout-background" style={{ padding: 24 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
