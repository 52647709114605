import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { Personi } from '../../../interfaces/Auth';
import { InstitutionProps } from '../institucioni/institucioni-api';
import { PaginationReturn } from '../../../interfaces/pagination';
import { valueUrlFormatter } from '../../../components/common/common-functions/commonApiUrl/get-trajnime-url';
import { AcademicYear } from '../../../interfaces/CompetitionYear';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';
const { test } = getConfig();

export interface TrainingConfigurationData {
  id?: number;
  tema: string;
  kategoriaTrajnimit: string;
}
export interface ActiveTrainingConfigurationData {
  id?: string;
  currentPage: number;
  totalPages: number;
  totalElements: number;
  data: ActiveTrainingDataProps[];
}
export interface ActiveTrainingDataProps extends TrainingConfigurationData {
  vendzhvillimi?: string;
  burimiFinancimit?: string;
  id: number;
  dataFillimit: string;
  dataPerfundimit: string;
  statusTrajnimi: string;
  vitiAkademik: {
    id: number;
    vitiFillimit: number;
    vitiMbarimit: number;
    aktiv: boolean;
  };
  regjistruar?: boolean;
  eshteTrajnues: boolean;
}
export interface TraineeByIdProps extends ActiveTrainingDataProps {
  paneli: TraineesProps[];
}
export interface TrainingForParticipantInfo extends ActiveTrainingDataProps {
  llojTrajnimi: string;
  gjeneroCertifikate: boolean;
  aplikuar: boolean;
  paneli: Array<PanelPropsInfo>;
  materiale: Array<DocumentsInfo>;
  prezenca: Array<AttandanceInfo>;
}

export interface PanelPropsInfo {
  trajnuesId: number;
  roli: string;
  pjesemarres: {
    id: number;
    emri: string;
    mbiemri: string;
  };
}
export interface DocumentsInfo {
  id: number;
  dokument: {
    id: number;
    emri: string;
    kohaUpload: string | Date;
  };
  perdorues: {
    id: number;
    emri: string;
    mbiemri: string;
  };
  llojDokument: string;
}
export interface AttandanceInfo {
  idTrajnimPrezenca: number;
  data: string;
  ditePrezent: string;
}

export interface TraineesProps {
  id: string | number;
  emri: string;
  trajnuesId: number;
  mbiemri: string;
  roliTrajnues: string;
  key?: string | number;
}

export interface ActiveTrainingFiltersProps
  extends Omit<TrainingFiltersProps, 'nenKategori'> {
  page?: number;
  size?: number;
  statusi?: string;
  regjistruar?: boolean;
  eshteTrajnues?: boolean;
}

export interface TrainingFiltersProps {
  tema?: string;
  kategori?: string;
  nenKategori?: string;
  statusTrajnimi?: string;
  vitiAkademikId?: number;
  regjistruar?: boolean;
  eshteTrajnues?: boolean;
  dataFilterFillimi?: string;
  dataFilterMbarimi?: string;
}

export interface ProposedTrainingsProps extends ActiveTrainingDataProps {
  pjesemarres: {
    id: number;
    personi: Personi;
    institucioni: InstitutionProps;
    funksioni: string;
  };
}

export interface CreateCalendarProps
  extends Omit<ActiveTrainingDataProps, 'vitiAkademik' | 'regjistruar' | 'id'> {
  trajnues: TraineesOfTrainingProps[];
}
export interface TraineesOfTrainingProps {
  trajnuesId: number;
  roliTrajnues: string;
}

export interface AttendanceRequest {
  id: number;
  data: string;
  body: {
    id: number;
    ditePrezent: number;
  }[];
}

export interface TrainingExpertProps extends TrainingConfigurationData {
  dataFillimit: string;
  roliTrajnues: 'EKSPERT' | 'LEHTESUES' | 'MODERUES';
  statusTrajnimi: string;
  nenkategori?: string;
  vitiAkademik?: AcademicYear;
}
export const trajnimetApi = createApi({
  reducerPath: 'trajnimetApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Trajnime', 'Regjistrime', 'VitiAktual'],
  endpoints: builder => ({
    createTrainings: builder.mutation<void, TrainingConfigurationData>({
      query: body => {
        return {
          url: `/trajnimi/program-tematik`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Trajnime'],
    }),
    getAllTrainings: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/all-program-tematik?page=${args.page}`;

        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Trajnime'],
    }),
    getAllSimplifiedTrainings: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/simplified?page=${args.page}&sort=tema:rendRrites`;

        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Trajnime'],
    }),
    getAllSimplifiedTrainingForParticipant: builder.query<
      PaginationReturn<TrainingConfigurationData>,
      { page: number; size: number; tema: string | undefined }
    >({
      query: args => {
        let url = `/trajnimi/pjesemarres/simplified?page=${args.page}&sort=tema:rendRrites`;

        if (args.size) {
          url += `&size=${args.size}`;
        }
        if (args.tema) {
          url += `&filter=tema:ilike:${args.tema}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getAllCalendarTrainings: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/kalendar?page=${args.page}`;
        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Trajnime'],
    }),
    createCalendarTopics: builder.mutation<void, void>({
      query: () => ({
        url: `/trajnimi/krijo-kalendar`,
        method: 'PUT',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    createCalendarTrainings: builder.mutation<void, CreateCalendarProps>({
      query: body => {
        return {
          url: `/trajnimi/kalendar`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Trajnime'],
    }),
    updateTrainings: builder.mutation<
      { id: number },
      Partial<ActiveTrainingDataProps>
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/trajnimi/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Trajnime'],
    }),
    deleteTrainings: builder.mutation<string, number>({
      query: arg => ({
        url: `/trajnimi/${arg}`,
        method: 'Delete',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    prepareActiveYearProgramTopics: builder.mutation<void, void>({
      query: () => ({
        url: `/trajnimi/pergatit-program-tematik`,
        method: 'PUT',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    prepareActiveCalendarTopics: builder.mutation<void, void>({
      query: () => ({
        url: `/trajnimi/publiko-kalendar`,
        method: 'PUT',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    getAllTrainingsForParticipants: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/program-tematik/pjesemarres?page=${args.page}`;

        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Regjistrime'],
    }),
    getAllCalendarTrainingsForParticipants: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/kalendar/pjesemarres?page=${args.page}`;

        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Regjistrime', 'Trajnime'],
    }),
    updateSubscribeTrainings: builder.mutation<void, number>({
      query: id => {
        return {
          url: `/trajnimi/${id}/regjistrohu`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Regjistrime'],
    }),
    updateUnsubscribeTrainings: builder.mutation<void, number>({
      query: id => {
        return {
          url: `/trajnimi/${id}/anullo-regjistrimin`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Regjistrime'],
    }),
    applyAsTrainee: builder.mutation<
      void,
      { id: number; roliTrajnues: 'EKSPERT' | 'LEHTESUES' | null }
    >({
      query: args => {
        let url = `/trajnimi/${args.id}/apliko-si-trajnues`;

        if (args.roliTrajnues) {
          url += `?roliTrajnues=${args.roliTrajnues}`;
        }

        return { url, method: 'PUT' };
      },
      invalidatesTags: ['Regjistrime'],
    }),
    getTraineeById: builder.query<
      { id: number; roliTrajnues: 'EKSPERT' | 'LEHTESUES' },
      { id: number }
    >({
      query: args => ({
        url: `trajnimi/program-tematik/${args.id}/aplikuar-si-ekspert/sipas-perdoruesit-te-loguar`,
        method: 'GET',
      }),
      providesTags: ['Regjistrime'],
    }),
    getTrainingsById: builder.query<
      TraineeByIdProps,
      { id: number | undefined }
    >({
      query: args => {
        return {
          url: `/trajnimi/${args.id}`,
          method: 'GET',
        };
      },
      providesTags: ['VitiAktual'],
    }),
    getTrainingsByIdForParticipant: builder.query<
      TrainingForParticipantInfo,
      { id: number | undefined; role: string }
    >({
      query: args => {
        return {
          url: `/trajnimi/kalendar/${args.id}/${args.role}`,
          method: 'GET',
        };
      },
    }),
    updateTraineesOfTraining: builder.mutation<
      void,
      {
        id: number | undefined;
        body: TraineesOfTrainingProps[];
      }
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/trajnimi/${id}/trajnues`,
          method: 'PUT',
          body: body.body,
        };
      },
      invalidatesTags: ['VitiAktual'],
    }),
    proposeTrainings: builder.mutation<void, TrainingConfigurationData>({
      query: body => {
        return {
          url: `/trajnimi/propozo`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Trajnime'],
    }),
    getProposedTrainings: builder.query<
      PaginationReturn<ProposedTrainingsProps>,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/propozuar?page=${args.page}`;

        if (args) {
          url += valueUrlFormatter(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['Trajnime'],
    }),
    updateApprovalOfTraining: builder.mutation<void, number>({
      query: id => ({
        url: `/trajnimi/${id}/aprovo`,
        method: 'PUT',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    getProposedTrainingsForParticipant: builder.query<
      ActiveTrainingConfigurationData,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/propozuar-per-pjesemarres?page=${args.page}`;

        if (args) {
          url += valueUrlFormatter(args);
        }
        return { url, method: 'GET' };
      },
      providesTags: ['Trajnime'],
    }),
    createTrainingAttendance: builder.mutation<void, AttendanceRequest>({
      query: ({ id, data, body }) => {
        return {
          url: `/trajnimi/${id}/prezenca?data=${data}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getParticipantsAttendanceMaterial: builder.query<Blob, { id: number }>({
      query: arg => {
        return {
          url: `/trajnimi/${arg.id}/eksport-pjesemarres`,
          method: 'GET',
          responseHandler: handleDownloadResponse,
        };
      },
    }),
    updateTrainingParticipantsAttendance: builder.mutation<
      void,
      { id: number; body: Array<number> }
    >({
      query: ({ id, body }) => ({
        url: `/trajnimi/${id}/pjesemarres`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Trajnime'],
    }),
    finishTraining: builder.mutation<void, number>({
      query: id => ({
        url: `/trajnimi/${id}/aprovo`,
        method: 'PUT',
      }),
      invalidatesTags: ['Trajnime'],
    }),
    getTrainingsForExpert: builder.query<
      PaginationReturn<TrainingExpertProps>,
      ActiveTrainingFiltersProps
    >({
      query: args => {
        let url = `/trajnimi/kalendar/ekspert?page=${args.page}`;
        if (args) {
          url += valueUrlFormatter(args);
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetAllTrainingsQuery,
  useGetAllSimplifiedTrainingsQuery,
  useLazyGetAllSimplifiedTrainingsQuery,
  useLazyGetAllSimplifiedTrainingForParticipantQuery,
  useCreateTrainingsMutation,
  useUpdateTrainingsMutation,
  useDeleteTrainingsMutation,
  usePrepareActiveYearProgramTopicsMutation,
  useGetAllTrainingsForParticipantsQuery,
  useGetAllCalendarTrainingsForParticipantsQuery,
  useUpdateSubscribeTrainingsMutation,
  useUpdateUnsubscribeTrainingsMutation,
  useApplyAsTraineeMutation,
  useGetTraineeByIdQuery,
  useGetTrainingsByIdQuery,
  useGetTrainingsByIdForParticipantQuery,
  useUpdateTraineesOfTrainingMutation,
  useProposeTrainingsMutation,
  useGetProposedTrainingsForParticipantQuery,
  useGetAllCalendarTrainingsQuery,
  useCreateCalendarTopicsMutation,
  usePrepareActiveCalendarTopicsMutation,
  useCreateCalendarTrainingsMutation,
  useGetProposedTrainingsQuery,
  useUpdateApprovalOfTrainingMutation,
  useCreateTrainingAttendanceMutation,
  useLazyGetParticipantsAttendanceMaterialQuery,
  useUpdateTrainingParticipantsAttendanceMutation,
  useFinishTrainingMutation,
  useGetTrainingsForExpertQuery,
} = trajnimetApi;
