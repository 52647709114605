import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { TrainingConfigurationData } from '../trajnimet/trajnimet-api';
import { PaginationReturn } from '../../../interfaces/pagination';
import { InstitutionProps } from '../institucioni/institucioni-api';
const { test } = getConfig();

export interface ParticipantInTrainingsProps {
  trajnimi: TrainingConfigurationData;
  pjesemarresNeTrajnim: PaginationReturn<ParticipantProps>;
}

export interface ParticipantProps {
  id: number;
  pjesemarres: {
    id: number;
    personi: {
      id: number;
      emri: string;
      mbiemri: string;
    };
    institucioni: InstitutionProps;
    funksioni: string;
  };
  ditePrezent: number;
}
export interface ExpertsProps {
  id: number;
  emri: string;
  mbiemri: string;
  roliTrajnues: string;
  institucioni: InstitutionProps;
}

interface ParticipantQueryProps {
  trajnimiId: number;
  personiEmri: string | undefined;
  personMbiemri?: string | undefined;
  funksioni?: string;
  institucioniId?: string | undefined;
}

export const pjesmarresNeTrajnimeApi = createApi({
  reducerPath: 'pjesmarresNeTrajnimeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    getParticipantsInTrainingById: builder.query<
      ParticipantInTrainingsProps,
      ParticipantQueryProps
    >({
      query: args => {
        let url = `/pjesemarres-ne-trajnime/${args.trajnimiId}?size=300&sort=personiEmri:asc`;

        if (args?.personiEmri) {
          // Split the input name into parts
          const nameParts = args?.personiEmri?.split(' ');

          // Assign the first part to personEmri
          if (nameParts && nameParts.length > 0) {
            url += `&filter=personiEmri:ilike:${nameParts[0]}`;
          }

          // Assign the second part to personMbiemri
          if (nameParts && nameParts.length > 1) {
            url += `&filter=personiMbiemri:ilike:${nameParts
              ?.slice(1)
              .join(' ')}`;
          }
        }

        if (args.funksioni) {
          url += `&filter=funksioni:eq:${args.funksioni}`;
        }
        if (args.institucioniId) {
          url += `&filter=institucioniId:eq:${args.institucioniId}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getExpertInTraining: builder.query<
      PaginationReturn<ExpertsProps>,
      ParticipantQueryProps
    >({
      query: args => {
        let url = `trajnimi/program-tematik/${args.trajnimiId}/aplikuar-si-ekspert?size=300&sort=emriPjesemarres:asc`;

        if (args?.personiEmri) {
          url += `&filter=emriPjesemarres:ilike:${args?.personiEmri}`;
        }
        if (args.funksioni) {
          url += `&filter=funksioni:eq:${args.funksioni}`;
        }
        if (args.institucioniId) {
          url += `&filter=institucioniId:eq:${args.institucioniId}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetParticipantsInTrainingByIdQuery,
  useGetExpertInTrainingQuery,
} = pjesmarresNeTrajnimeApi;
