export enum StatusiKonkurrent {
  new = 'NEW',
  aplikant = 'APLIKANT',
  refuzuar = 'REFUZUAR',
  pranuar = 'PRANUAR',
  regjistruar = 'REGJISTRUAR',
  neVerifikim = 'NE_VERIFIKIM',
  neVerifikiKlgj = 'NE_VERIFIKIM_KLGJ',
  neVerifikimKlp = 'NE_VERIFIKIM_KLP',
  faza1 = 'FAZA_1',
  faza2 = 'FAZA_2',
  kerkesePerDokument = 'KERKESE_PER_DOKUMENT',
  pezulluar = 'PEZULLUAR',
  perjashtuar = 'PERJASHTUAR',
}
export enum statusVerifikimi {
  regjistruar = 'REGJISTRUAR',
  refuzuar = 'REFUZUAR',
}

export enum StatusiKonkurrentLabel {
  'NEW' = 'Aplikim i ri',
  'APLIKANT' = 'Aplikant',
  'REFUZUAR' = 'Refuzuar',
  'PRANUAR' = 'Pranuar',
  'REGJISTRUAR' = 'Regjistruar',
  'NE_VERIFIKIM' = 'Në verifikim',
  'NE_VERIFIKIM_KLGJ' = 'Në verifikim KLGJ',
  'NE_VERIFIKIM_KLP' = 'Në verifikim KLP',
  'FAZA_1' = 'Faza e parë',
  'FAZA_2' = 'Faza e dytë',
  'KERKESE_PER_DOKUMENT' = 'Kërkesë për dokument',
  'PEZULLUAR' = 'Pezulluar',
  'PERJASHTUAR' = 'Përjashtuar',
}
