import React from 'react';
import { Form, Select } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';
import allowClearConfig from '../../common/common-functions/commonApiUrl/allowClear';
const AntdSelect = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props.rules}>
      <Select
        {...props}
        allowClear={allowClearConfig}
        className="custom-select"
        style={props.styles}
      >
        {props.options?.map((data, i) => {
          return (
            <Select.Option key={i} value={data.value}>
              {data.label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default AntdSelect;
