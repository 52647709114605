import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';
import {
  CreateKlinikaVleresime,
  VleresimeKlinikaParams,
  VleresimiKlinikaResponse,
} from '../../../interfaces/vleresime.interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import { getVleresimeKlinika } from '../../../components/common/common-functions/commonApiUrl/get-vleresime-url';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();

export type KlinikaResponse = PaginationReturn<VleresimiKlinikaResponse>;
export type AddEvaluation = UpdateKlasaStudent<CreateKlinikaVleresime>;

export const vleresimeKlinikaApi = createApi({
  reducerPath: 'vleresimeKlinika',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['allVleresimeKlinika', 'finalEvaluations'],
  endpoints: builder => ({
    getAllVleresimeKlinika: builder.query<
      KlinikaResponse,
      VleresimeKlinikaParams
    >({
      query: args => ({
        url: getVleresimeKlinika(args),
        method: 'GET',
      }),
      providesTags: ['allVleresimeKlinika'],
    }),
    getAllVleresimeKlinikaKomision: builder.query<
      KlinikaResponse,
      VleresimeKlinikaParams
    >({
      query: args => ({
        url: getVleresimeKlinika(args, 'komision'),
        method: 'GET',
      }),
      providesTags: ['finalEvaluations'],
    }),
    downloadClinicFile: builder.query<Blob, void>({
      query: () => ({
        url: '/lende-klinike/eksporto-format-rezultate-perfundimtare',
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
    }),
    createVleresimeKlinike: builder.mutation<number, AddEvaluation>({
      query: ({ body, id }) => ({
        url: `/vleresime-klinika-ligjore/shto-sipas-klinikes/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allVleresimeKlinika'],
    }),
    importVleresimeKlinike: builder.mutation<number, FormData>({
      query: file => ({
        url: 'vleresime-klinika-ligjore/komision',
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['finalEvaluations'],
    }),
    updateVleresimeKlinike: builder.mutation<number, AddEvaluation>({
      query: ({ body, id }) => ({
        url: `/vleresime-klinika-ligjore/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allVleresimeKlinika'],
    }),
  }),
});

export const {
  useGetAllVleresimeKlinikaQuery,
  useGetAllVleresimeKlinikaKomisionQuery,
  useLazyDownloadClinicFileQuery,
  useCreateVleresimeKlinikeMutation,
  useUpdateVleresimeKlinikeMutation,
  useImportVleresimeKlinikeMutation,
} = vleresimeKlinikaApi;
