import { KonkurrentiParams } from '../../../../redux/features/konkurrenti/konkurrenti';

export const getKonkurrimeUrl = (params: KonkurrentiParams, url: string) => {
  const { size, page, personiEmri, statusKonkurrent, vitiKonkurrimitId, sort } =
    params;

  let queryString = `/konkurrenti/${url}?size=${size}&page=${page}`;

  if (personiEmri) {
    queryString += `&filter=personiEmri:ilike:${personiEmri}`;
  }
  if (statusKonkurrent) {
    queryString += `&filter=statusKonkurrent:ilike:${statusKonkurrent}`;
  }
  if (sort) {
    queryString += `&sort=personiEmri:${sort}`;
  }
  if (vitiKonkurrimitId) {
    queryString += `&filter=vitiKonkurrimitId:eq:${vitiKonkurrimitId}`;
  }

  return queryString;
};
