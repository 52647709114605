import React from 'react';
import { allAnetarKomisioni } from '../constants/all-anetar-komisioni';
import { ROLES } from '../constants/enums/roles';
import { ModulesProps } from '../interfaces/modules.interface';
import { ReactComponent as PodiumGold } from '../assets/svgIcons/mdi_podium-gold.svg';
import { ReactComponent as BooksAdd } from '../assets/svgIcons/books-add.svg';
import { ReactComponent as LibraryBooks } from '../assets/svgIcons/library-books.svg';
import { ReactComponent as ArrowRightGreen } from '../assets/svgIcons/arrowRight.svg';
import { ReactComponent as ArrowRightOrange } from '../assets/svgIcons/arrowRightOrange.svg';
import { ReactComponent as ArrowRightBlue } from '../assets/svgIcons/arrowRightBlue.svg';

export const getModuleByRole = (role: ROLES[]) => {
  const {
    admin,
    konkurrent,
    pjesmarres,
    specialistFv,
    specialistFF,
    pedagog,
    student,
  } = ROLES;

  const moduleFF = [specialistFF, pedagog, admin, student];
  const moduleFV = [specialistFv, pjesmarres];
  const moduleKonkurrime = [...allAnetarKomisioni, admin, konkurrent];

  const filteredModules: ModulesProps[] = [];

  const konkurrimeShm: ModulesProps = {
    label: 'KONKURRIMI NË SHM',
    bgColor: 'rgba(3, 201, 136, 0.2)',
    color: '#03C988',
    icon: <PodiumGold />,
    arrowIcon: <ArrowRightGreen className="h-24" />,
  };

  const formimiFillestar: ModulesProps = {
    label: 'FORMIMI FILLESTAR',
    bgColor: 'rgba(255, 115, 29, 0.2',
    color: 'rgba(255, 115, 29, 1)',
    icon: <BooksAdd />,
    arrowIcon: <ArrowRightOrange className="h-24" />,
  };

  const formimiVazhdues: ModulesProps = {
    label: 'FORMIMI VAZHDUES',
    bgColor: 'rgba(28, 130, 173, 0.2)',
    color: 'rgba(28, 130, 173, 1)',
    icon: <LibraryBooks />,
    arrowIcon: <ArrowRightBlue className="h-24" />,
  };

  const addModuleWithRoleCheck = (module: ROLES[], data: ModulesProps) => {
    const roleToUse = role.find(role => module.includes(role));
    filteredModules.push({ ...data, activeRole: roleToUse || undefined });
  };

  addModuleWithRoleCheck(moduleKonkurrime, konkurrimeShm);
  addModuleWithRoleCheck(moduleFF, formimiFillestar);
  addModuleWithRoleCheck(moduleFV, formimiVazhdues);

  return filteredModules;
};
