import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ProfiliData } from '../../../interfaces/profili-interface';
import { VerificationDropDown } from '../../../components/ProfiliModal/ProfiliModal';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { getProfiliApiUrl } from '../../../components/common/common-functions/commonApiUrl/get-profili-url';
import { getPrepareHeaders } from '../get-prepare-headers';
import { UpdateProps } from '../../../interfaces/common-interfaces';

const { test } = getConfig();

export interface ProfiliApiProps extends Partial<PaginationProps> {
  name?: string;
  sort?: 'asc' | 'desc';
}

export const profiliApi = createApi({
  reducerPath: 'profili',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Profili', 'GetAllProfiles', 'AnetareKomisioni'],
  endpoints: builder => ({
    getAllProfiles: builder.query<
      PaginationReturn<ProfiliData>,
      ProfiliApiProps
    >({
      query: getProfiliApiUrl,
      providesTags: ['GetAllProfiles'],
    }),
    createProfile: builder.mutation<number, ProfiliData>({
      query: data => ({
        url: `/profili`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllProfiles'],
    }),
    getSingleProfile: builder.query<ProfiliData, number>({
      query: id => ({
        url: `/profili/${id}`,
        method: 'GET',
      }),
      providesTags: ['Profili'],
    }),
    updateProfile: builder.mutation<number, UpdateProps<ProfiliData>>({
      query: args => ({
        url: `/profili/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['GetAllProfiles', 'Profili'],
    }),
    deleteProfile: builder.mutation<string, number>({
      query: id => ({
        url: `/profili/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllProfiles', 'Profili'],
    }),
    getListOfCommisionRoles: builder.query<VerificationDropDown[], void>({
      query: () => ({
        url: `/roli/merr-anetare-komisioni`,
        method: 'GET',
      }),
      transformResponse: (response: string[]) => {
        return (
          response?.map(item => {
            return {
              value: item,
              label: item,
            };
          }) ?? []
        );
      },
      providesTags: ['AnetareKomisioni'],
    }),
  }),
});

export const {
  useGetAllProfilesQuery,
  useLazyGetAllProfilesQuery,
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useGetSingleProfileQuery,
  useDeleteProfileMutation,
  useGetListOfCommisionRolesQuery,
} = profiliApi;
