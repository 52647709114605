import getConfig from '../../../helpers/config';
import { PaginationReturn } from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';
import { getLendaApiUrl } from '../../../components/common/common-functions/commonApiUrl/get-lenda-url';
import {
  AcademicSubjectClass,
  CreateGroups,
  KlasaBodyProps,
  KlasaByIdProps,
  KlasaProps,
  StudentInClassProps,
  UpdateKlasaStudent,
} from '../../../interfaces/klasa-interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { SubjectByClassData } from '../../../interfaces/lenda-akademike.interface';
import { LendaApiProps } from '../lenda/lendaApi';
import { UpdateProps } from '../../../interfaces/common-interfaces';

export type KlasaType = PaginationReturn<KlasaProps>;

const { test } = getConfig();

export const klasaApi = createApi({
  reducerPath: 'klasa',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: [
    'klasa',
    'GetAllClasses',
    'Students',
    'GetAllGroups',
    'GetAllClinics',
  ],
  endpoints: builder => ({
    getAllClasses: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa'),
        method: 'GET',
      }),
      providesTags: ['GetAllClasses'],
    }),
    getAllGroups: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/grup-gjyq'),
        method: 'GET',
      }),
      providesTags: ['GetAllGroups'],
    }),
    getAllGroupsSimplified: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/grup-gjyq/simplified'),
        method: 'GET',
      }),
      providesTags: ['GetAllGroups'],
    }),
    getAllGroupsPedagog: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/grup-gjyq-klinika/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['GetAllGroups'],
    }),
    getAllClassesGroups: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/te-gjitha-llojet'),
        method: 'GET',
      }),
      providesTags: ['GetAllGroups'],
    }),
    getAllClassesTeacher: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/te-gjitha-llojet/per-pedagog'),
        method: 'GET',
      }),
      providesTags: ['GetAllGroups'],
    }),
    getClassById: builder.query<KlasaByIdProps, number>({
      query: id => ({
        url: `klasa/${id}`,
        method: 'GET',
      }),
      providesTags: ['Students'],
    }),
    createClass: builder.mutation<number, KlasaBodyProps>({
      query: data => ({
        url: `/klasa`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllClasses'],
    }),
    updateClass: builder.mutation<number, UpdateProps<Partial<KlasaBodyProps>>>(
      {
        query: ({ data, id }) => ({
          url: `/klasa/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['GetAllClasses'],
      },
    ),
    updateStudentInClass: builder.mutation<
      StudentInClassProps[],
      UpdateKlasaStudent<number[]>
    >({
      query: ({ body, id }) => ({
        url: `klasa/${id}/studentet`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Students', 'GetAllClasses'],
    }),
    deleteClass: builder.mutation<string, number>({
      query: id => ({
        url: `/klasa/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['GetAllClasses'],
    }),
    closeAdmissions: builder.mutation<number, number>({
      query: id => ({
        url: `/klasa/${id}/mbyll-regjistrime`,
        method: 'PUT',
      }),
      invalidatesTags: ['GetAllClasses'],
    }),
    updateClassStudent: builder.mutation<
      string,
      UpdateKlasaStudent<{ perserites: boolean }>
    >({
      query: ({ body, id }) => ({
        url: `/klasa-student/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['GetAllClasses', 'Students'],
    }),
    updateClassAcademicSubject: builder.mutation<
      AcademicSubjectClass[],
      UpdateKlasaStudent<number[]>
    >({
      query: ({ body, id }) => ({
        url: `/klasa/${id}/lende-akademike`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['GetAllClasses'],
    }),
    getAcademicSubjectByClass: builder.query<SubjectByClassData, number>({
      query: id => ({
        url: `lenda-klasa/${id}?size=500`,
        method: 'GET',
      }),
      providesTags: ['GetAllClasses'],
    }),
    createMockGroup: builder.mutation<number, CreateGroups>({
      query: data => ({
        url: '/klasa/grup-gjyq',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllGroups'],
    }),
    updateMockGroup: builder.mutation<
      number,
      UpdateProps<Partial<CreateGroups>>
    >({
      query: ({ data, id }) => ({
        url: `/klasa/${id}/grup-gjyq`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllGroups'],
    }),
    addStudentPerMockGroup: builder.mutation<
      StudentInClassProps[],
      UpdateKlasaStudent<number[]>
    >({
      query: ({ body, id }) => ({
        url: `/klasa/${id}/grup-gjyq-klinike-student`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Students', 'GetAllGroups', 'GetAllClinics'],
    }),
    deleteGroup: builder.mutation<number, number>({
      query: id => ({
        url: `/klasa/grup-gjyq-klinika/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['GetAllGroups', 'GetAllClinics'],
    }),
    getAllLegalClinic: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/grup-klinika-ligjore'),
        method: 'GET',
      }),
      providesTags: ['GetAllClinics'],
    }),
    getSimplifiedLegalClinic: builder.query<KlasaType, LendaApiProps>({
      query: args => ({
        url: getLendaApiUrl(args, 'klasa/grup-klinika-ligjore/simplified'),
        method: 'GET',
      }),
      providesTags: ['GetAllClinics'],
    }),
    createLegalClinic: builder.mutation<number, CreateGroups>({
      query: data => ({
        url: '/klasa/grup-klinika-ligjore',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllClinics'],
    }),
    updateLegalClinic: builder.mutation<
      number,
      UpdateProps<Partial<CreateGroups>>
    >({
      query: ({ data, id }) => ({
        url: `/klasa/${id}/grup-klinika-ligjore`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetAllClinics'],
    }),
  }),
});

export const {
  useLazyGetAllClassesQuery,
  useGetAllGroupsQuery,
  useLazyGetAllGroupsSimplifiedQuery,
  useLazyGetAllGroupsPedagogQuery,
  useLazyGetAllClassesGroupsQuery,
  useLazyGetAllClassesTeacherQuery,
  useGetClassByIdQuery,
  useGetAllClassesQuery,
  useCreateClassMutation,
  useDeleteClassMutation,
  useUpdateClassMutation,
  useCloseAdmissionsMutation,
  useUpdateStudentInClassMutation,
  useUpdateClassStudentMutation,
  useUpdateClassAcademicSubjectMutation,
  useGetAcademicSubjectByClassQuery,
  useCreateMockGroupMutation,
  useUpdateMockGroupMutation,
  useAddStudentPerMockGroupMutation,
  useDeleteGroupMutation,
  useGetAllLegalClinicQuery,
  useCreateLegalClinicMutation,
  useUpdateLegalClinicMutation,
  useLazyGetSimplifiedLegalClinicQuery,
} = klasaApi;
