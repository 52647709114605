import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  FazaDyteResponse,
  FazaProps,
  FazatParams,
  RezultateSipasFazes,
  ShtoLendProps,
  UpdateFileReturn,
} from '../../../interfaces/fazat-interface';
import {
  getFazatUrl,
  merrSipasFazesUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-fazat-url';
import { PaginationReturn } from '../../../interfaces/pagination';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();

export interface UpdateFileParams {
  body: FormData;
  id: number;
}

export const fazaApi = createApi({
  reducerPath: 'fazaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders(headers) {
      const token = localStorage.getItem('magjistraturaAppToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['fazat', 'getByFaza'],

  endpoints: builder => ({
    getFazat: builder.query<PaginationReturn<FazaProps>, FazatParams>({
      query: args => ({
        url: getFazatUrl(args),
        method: 'GET',
      }),
      providesTags: ['fazat'],
    }),
    merrSipasFazes: builder.query<RezultateSipasFazes, FazatParams>({
      query: args => ({
        url: merrSipasFazesUrl(args),
        method: 'GET',
      }),
      providesTags: ['getByFaza'],
    }),
    merrFazaDyte: builder.query<
      PaginationReturn<FazaDyteResponse>,
      FazatParams
    >({
      query: args => ({
        url: merrSipasFazesUrl(args, `/rezultate-faza/${args.id}/faza_2`),
        method: 'GET',
      }),
      providesTags: ['getByFaza'],
    }),
    exportInputFile: builder.query<Blob, number>({
      query: id => ({
        url: `/faza/${id}/eksport-rezultate-faza-input-file`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['getByFaza'],
    }),

    merrFazaAktive: builder.query<PaginationReturn<FazaProps>, void>({
      query: () => ({
        url: 'faza/aktive',
        method: 'GET',
      }),
      providesTags: ['getByFaza'],
    }),
    shtoLendeKonkurrimi: builder.mutation<number, ShtoLendProps>({
      query: data => ({
        url: '/lende-konkurrim',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['fazat'],
    }),
    ngarkoFile: builder.mutation<UpdateFileReturn[], UpdateFileParams>({
      query: args => ({
        url: `/rezultate-faza/${args.id}`,
        method: 'POST',
        body: args.body,
      }),
      invalidatesTags: ['getByFaza', 'fazat'],
    }),
    updateNgarkoFile: builder.mutation<UpdateFileReturn[], UpdateFileParams>({
      query: args => ({
        url: `/rezultate-faza/${args.id}`,
        method: 'PUT',
        body: args.body,
      }),
      invalidatesTags: ['fazat', 'getByFaza'],
    }),
    perfundoFaza1: builder.mutation<string, number>({
      query: id => ({
        url: `/faza/perfundo-faza-1/${id}`,
        method: 'PUT',
        body: id,
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['fazat'],
    }),
    deleteLendeKonkurrimi: builder.mutation<string, number>({
      query: id => ({
        url: `/lende-konkurrim/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['fazat'],
    }),
  }),
});

export const {
  useGetFazatQuery,
  useMerrSipasFazesQuery,
  useLazyExportInputFileQuery,
  useMerrFazaAktiveQuery,
  useMerrFazaDyteQuery,
  usePerfundoFaza1Mutation,
  useNgarkoFileMutation,
  useUpdateNgarkoFileMutation,
  useShtoLendeKonkurrimiMutation,
  useDeleteLendeKonkurrimiMutation,
} = fazaApi;
