import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPrepareHeaders } from '../get-prepare-headers';
import dayjs from 'dayjs';
import { UpdateProps } from '../../../interfaces/common-interfaces';
import {
  EditOrariProps,
  OrariParams,
  OrariResponse,
  OrariEventProps,
} from '../../../interfaces/orari-interface';
import { getOrariUrl } from '../../../components/common/common-functions/commonApiUrl/get-orari.url';
import { PaginationReturn } from '../../../interfaces/pagination';

const { test } = getConfig();

type OrariType = PaginationReturn<OrariResponse>;

export const orariMesimorApi = createApi({
  reducerPath: 'orariMesimor',
  tagTypes: ['OrariEvents'],
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  endpoints: builder => ({
    createOrarMesimor: builder.mutation<number, EditOrariProps>({
      query: body => ({
        url: `/orari-mesimor`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OrariEvents'],
    }),
    editOrarMesimor: builder.mutation<number, UpdateProps<EditOrariProps>>({
      query: args => ({
        url: `/orari-mesimor/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['OrariEvents'],
    }),
    getOrariMesimor: builder.query<OrariEventProps[], OrariParams>({
      query: args => getOrariUrl(args),
      transformResponse: (response: OrariType) =>
        transformTimeSheetData(response.data),
      providesTags: ['OrariEvents'],
    }),
    getOrariPerPedagog: builder.query<OrariEventProps[], OrariParams>({
      query: args =>
        `/orari-mesimor/per-pedagog?size=${args.size}&page=${args.page}&filter=data:gte:${args.start},data:lte:${args.end}`,
      transformResponse: (response: OrariType) =>
        transformTimeSheetData(response.data),
      providesTags: ['OrariEvents'],
    }),
    deleteOrariMesimor: builder.mutation<string, number>({
      query: arg => ({
        url: `/orari-mesimor/${arg}`,
        method: 'Delete',
      }),
      invalidatesTags: ['OrariEvents'],
    }),
  }),
});

export const {
  useCreateOrarMesimorMutation,
  useEditOrarMesimorMutation,
  useGetOrariMesimorQuery,
  useDeleteOrariMesimorMutation,
  useGetOrariPerPedagogQuery,
} = orariMesimorApi;

const transformTimeSheetData = (data: OrariResponse[]) => {
  return data.map(item => {
    const start = dayjs(item.data)
      .hour(dayjs(item.oraFillimit).hour())
      .minute(dayjs(item.oraFillimit).minute())
      .second(0);
    const end = dayjs(item.data)
      .hour(dayjs(item.oraMbarimit).hour())
      .minute(dayjs(item.oraMbarimit).minute())
      .second(0);
    return {
      ...item,
      oraFillimit: new Date(start.toDate()),
      oraMbarimit: new Date(end.toDate()),
      color: getRandomColor(),
    };
  });
};

function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * cardColors.length);
  return cardColors[randomIndex];
}

const cardColors = [
  '#0074E4',
  '#12486B',
  '#A3C8FF',
  '#12486B',
  '#1450A3',
  '#57375D',
  '#5C5470',
  '#445069',
  '#7f1a3f',
  '#1D5D9B',
  '#3F2E3E',
  '#884A39',
  '#116D6E',
  '#643843',
  '#4C3D3D',
  '#394867',
];
