import { LendaTemaParams } from '../../../../interfaces/lenda-akademike.interface';

export const getTopicUrl = (params: LendaTemaParams, url = '') => {
  const { page, size, emertimi, vitiAkademikId, id } = params;
  let queryString = `/lenda-tema/${id}${url}?page=${page}&size=${size}&sort=emertimi:asc`;

  if (emertimi) {
    queryString += `&filter=emertimi:ilike:${emertimi}`;
  }

  if (vitiAkademikId) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademikId}`;
  }

  return queryString;
};
