import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { Key } from 'react';
import { Personi } from '../../../interfaces/Auth';
import { InstitutionProps } from '../institucioni/institucioni-api';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
const { test } = getConfig();

export interface ActiveResponseConfigurationData {
  id?: string;
  currentPage: number;
  totalPages: number;
  totalElements: number;
}
export interface ParticipantData {
  key: Key;
  id: number;
  emri: string;
  mbiemri: string;
  numriPersonal: string;
  funksioni: string;
  emriInstitucionit: string;
  institucioniLabel: string;
  institucioniId: number;
  personiId?: number;
}
interface ParticipantsResponseProps extends ActiveResponseConfigurationData {
  data: Array<ParticipantsProps>;
}

export interface ParticipantDataInfoProps {
  perdorues: {
    id?: number;
    personi: Personi;
    email: string;
    fjalekalimi: string;
    fjalekalimiPerseritur: string;
    aktiv?: boolean;
    firstLogIn?: boolean;
  };
  pjesemarres: Omit<PjesemarresProps, 'id'>;
}
export interface PjesemarresProps {
  id: number;
  institucioniId: number;
  funksioni: string;
}
export interface ParticipantsProps {
  id: number;
  personi: Personi;
  institucioni: InstitutionProps;
  funksioni: string;
}

export interface ParticipantsSubmitProps extends Personi {
  perdoruesId?: number;
  email: string;
  fjalekalimi: string;
  fjalekalimiPerseritur: string;
  funksioni: string;
  activ: boolean;
  institucioniId: number;
}

export interface ParticipantsFiltersProps extends PaginationProps {
  emri?: string | null;
  mbiemri?: string;
  funksioni?: string;
  institucioniId?: string;
}

export interface SimplifiedParticipantsProps {
  id: number;
  personi: Personi;
  institucioniEmri: string;
  institucioniQyteti: string;
}

export const pjesmarresApi = createApi({
  reducerPath: 'pjesmarresApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['pjesmarres'],
  endpoints: builder => ({
    createParticipants: builder.mutation<void, ParticipantDataInfoProps>({
      query: body => {
        return {
          url: '/pjesemarres',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['pjesmarres'],
    }),
    getaAllParticipants: builder.query<
      ParticipantsResponseProps,
      ParticipantsFiltersProps
    >({
      query: args => {
        let url = `/pjesemarres?page=${args.page}&sort=emer:asc`;

        if (args) {
          url += queryParticipantValue(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['pjesmarres'],
    }),
    updateParticipant: builder.mutation<void, PjesemarresProps>({
      query: ({ id, ...arg }) => {
        return {
          url: `/pjesemarres/${id}`,
          method: 'PUT',
          body: arg,
        };
      },
      invalidatesTags: ['pjesmarres'],
    }),
    getAllSimplifiedParticipants: builder.query<
      PaginationReturn<SimplifiedParticipantsProps>,
      ParticipantsFiltersProps
    >({
      query: args => {
        let url = `/pjesemarres/simplified?page=${args.page}&sort=emer:asc`;
        if (args) {
          url += queryParticipantValue(args);
        }

        return { url, method: 'GET' };
      },
      providesTags: ['pjesmarres'],
    }),
  }),
});

export const {
  useGetaAllParticipantsQuery,
  useCreateParticipantsMutation,
  useUpdateParticipantMutation,
  useLazyGetAllSimplifiedParticipantsQuery,
} = pjesmarresApi;

function queryParticipantValue(args: ParticipantsFiltersProps) {
  let url = '';
  if (args.size) {
    url += `&size=${args.size}`;
  }
  if (args.emri) {
    url += `&filter=emer:ilike:${args.emri}`;
  }
  if (args.mbiemri) {
    url += `&filter=mbiemer:ilike:${args.mbiemri}`;
  }
  if (args.funksioni) {
    url += `&filter=funksioni:eq:${args.funksioni}`;
  }
  if (args.institucioniId) {
    url += `&filter=institucioniId:eq:${args.institucioniId}`;
  }
  return url;
}
