import { ParamsProps } from '../../../../redux/features/universiteti/universityApi';

export const getUniversityUrl = (params: ParamsProps) => {
  const { size, emri, aprovuar, llojiUniversiteti, page } = params;
  let queryString = `/universiteti?size=${size}&page=${page}`;

  if (emri) {
    queryString += `&filter=emri:ilike:${emri}`;
  }

  if (aprovuar !== undefined) {
    queryString += `&filter=aprovuar:eq:${aprovuar}`;
  }

  if (llojiUniversiteti !== undefined) {
    queryString += `&filter=llojiUniversiteti:eq:${llojiUniversiteti}`;
  }

  return queryString;
};
