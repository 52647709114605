import { TrainingAttendanceQueryProps } from '../../../../redux/features/trajnim-prezenca/trajnim-prezenca-api';

export function getTrajnimiPrezencaUrl(args: TrainingAttendanceQueryProps) {
  let url = '';

  if (args.size) {
    url += `&size=${args.size}`;
  }
  if (args.vitiAkademikId) {
    url += `&filter=vitiAkademikId:eq:${args.vitiAkademikId}`;
  }
  if (args.trajnimiId) {
    url += `&filter=trajnimiId:eq:${args.trajnimiId}`;
  }
  if (args.pjesemarresId) {
    url += `&filter=pjesemarresId:eq:${args.pjesemarresId}`;
  }

  if (args.dataFilterFillimi) {
    url += `&filter=data:gte:${args.dataFilterFillimi}`;
  }
  if (args.dataFilterMbarimi) {
    url += `&filter=data:lte:${args.dataFilterMbarimi}`;
  }

  return url;
}
