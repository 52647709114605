import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  CreateVleresimGjyq,
  VleresimeAdminResponse,
  VleresimeGjyqParams,
  VleresimeGjyqResponse,
  VleresimeParams,
} from '../../../interfaces/vleresime.interface';
import { PaginationReturn } from '../../../interfaces/pagination';
import {
  getVleresimeApiUrl,
  getVleresimeGjyqApiUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-vleresime-url';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';
import { StudentsResponse } from './vleresimiApi';

const { test } = getConfig();

type VleresimeType = PaginationReturn<VleresimeGjyqResponse>;
type CreateVleresim = UpdateKlasaStudent<CreateVleresimGjyq>;

export const vleresimeGjyqApi = createApi({
  reducerPath: 'vleresimeGjyq',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + '/',
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['getAllVleresimeGjyq', 'getAllUnEvalStudents'],
  endpoints: builder => ({
    getAllVleresimeGjyq: builder.query<VleresimeType, VleresimeGjyqParams>({
      query: args => ({
        url: getVleresimeGjyqApiUrl(args),
        method: 'GET',
      }),
      providesTags: ['getAllVleresimeGjyq'],
    }),
    getAllVleresimeAdmin: builder.query<
      VleresimeAdminResponse,
      VleresimeGjyqParams
    >({
      query: args => ({
        url: getVleresimeGjyqApiUrl(args, '/total', ''),
        method: 'GET',
      }),
      providesTags: ['getAllVleresimeGjyq'],
    }),
    getAllUnevaluatedMockStudents: builder.query<
      StudentsResponse,
      VleresimeParams
    >({
      query: args => ({
        url: getVleresimeApiUrl(args, 'gjyq'),
        method: 'GET',
      }),
      providesTags: ['getAllUnEvalStudents'],
    }),
    createVleresimeGjyq: builder.mutation<number, CreateVleresim>({
      query: ({ body, id }) => ({
        url: `/vleresime-gjyq/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['getAllVleresimeGjyq', 'getAllUnEvalStudents'],
    }),
    updateVleresimeGjyq: builder.mutation<number, CreateVleresim>({
      query: ({ body, id }) => ({
        url: `/vleresime-gjyq/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['getAllVleresimeGjyq'],
    }),
  }),
});

export const {
  useGetAllVleresimeGjyqQuery,
  useGetAllVleresimeAdminQuery,
  useLazyGetAllUnevaluatedMockStudentsQuery,
  useCreateVleresimeGjyqMutation,
  useUpdateVleresimeGjyqMutation,
} = vleresimeGjyqApi;
