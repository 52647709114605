import React from 'react';
import { Form, InputNumber } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';

const InputNumberField = (props: FormField) => {
  return (
    <Form.Item name={props.name} rules={props?.rules}>
      <InputNumber
        placeholder={props.placeholder}
        {...props.otherprops}
        disabled={props.disabled}
        {...props}
      />
    </Form.Item>
  );
};

export default InputNumberField;
