import React from 'react';
import { Modal, Tabs } from 'antd';
import { settingsModalItemsData } from '../common/tab-items/settings-modal.items';
import ModalTitle from '../common/common-componnets/ModalTitle';
import { CiSettings } from 'react-icons/ci';
interface UserModalProps {
  openSettingsModal: boolean;
  setOpenSettingsModal: React.Dispatch<boolean>;
}
const SettingsModal = ({
  openSettingsModal,
  setOpenSettingsModal,
}: UserModalProps) => {
  return (
    <>
      <Modal
        title={
          <ModalTitle
            title="Account Settings"
            icon={<CiSettings className="h-8 w-8" />}
            onClose={() => setOpenSettingsModal(false)}
          />
        }
        closeIcon={null}
        centered
        open={openSettingsModal !== null}
        footer={null}
        onCancel={() => setOpenSettingsModal(false)}
        className="modalStyle flex"
        width={700}
      >
        <Tabs
          tabPosition="left"
          items={settingsModalItemsData}
          className="mt-6"
        />
      </Modal>
    </>
  );
};

export default SettingsModal;
