import React, { JSX } from 'react';
import {
  BookOutlined,
  CalendarOutlined,
  DiffOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  allAnetarKomisioni,
  allFormimiFillestar,
} from './all-anetar-komisioni';
import { ROLES } from './enums/roles';
import { ReactComponent as Apply } from '../assets/svgIcons/cil_school.svg';
import { ReactComponent as Subject } from '../assets/svgIcons/subject.svg';
import { FaSortAmountDown } from 'react-icons/fa';
import { RiLockPasswordFill, RiNewspaperLine } from 'react-icons/ri';
import { MdAssessment, MdOutlineTopic, MdViewModule } from 'react-icons/md';
import { BsListCheck } from 'react-icons/bs';
import { GoLaw } from 'react-icons/go';
import { VscLaw } from 'react-icons/vsc';
import { AiOutlineAudit } from 'react-icons/ai';
import { TbReportSearch } from 'react-icons/tb';

interface SidebarDataProps {
  key: string;
  label: string;
  icon?: JSX.Element;
}

export interface SidebarMenuOptionsProps extends SidebarDataProps {
  icon: JSX.Element;
  roles: ROLES[];
  children?: SidebarDataProps[];
}

const {
  admin,
  konkurrent,
  anetarShm,
  specialistFv,
  pjesmarres,
  specialistFF,
  pedagog,
  student,
} = ROLES;

const administrationChildren = (role?: ROLES) => {
  if (role === anetarShm) {
    return [
      {
        key: '/administrim-konkurrim',
        label: 'Konkurrime',
      },
    ];
  } else if (role === specialistFF) {
    return [
      {
        key: '/administrim-formimiFillestar',
        label: 'Formimi Fillestar',
      },
    ];
  } else if (role === specialistFv) {
    return [
      {
        key: '/administrim-formimiVazhdues',
        label: 'Formimi Vazhdues',
      },
    ];
  } else if (role === admin) {
    return [
      {
        key: '/administrim-konkurrim',
        label: 'Konkurrime',
      },
      {
        key: '/administrim-formimiFillestar',
        label: 'Formimi Fillestar',
      },
      {
        key: '/administrim-formimiVazhdues',
        label: 'Formimi Vazhdues',
      },
    ];
  }
  return [];
};

export const sidebarMenuOptions = (role?: ROLES): SidebarMenuOptionsProps[] => {
  return [
    {
      key: '#',
      icon: <SettingOutlined />,
      label: 'Administrimi',
      roles: [admin, anetarShm, specialistFF, specialistFv],
      children: administrationChildren(role),
    },
    {
      key: '/lende-akademike',
      icon: <Subject className="h-5 w-5" />,
      label: 'Lëndë',
      roles: [...allFormimiFillestar, student],
      children: [
        {
          key: '/lende-mesimore',
          label: 'Lëndë Mësimore',
          icon: <Subject className="h-5 w-5" />,
        },
        {
          key: '/gjyqe-imituese',
          label: 'Gjyqe imituese',
          icon: <GoLaw className="h-5 w-5" />,
        },
        {
          key: '/klinike-ligjore',
          label: 'Klinikë ligjore',
          icon: <VscLaw className="h-5 w-5" />,
        },
      ],
    },
    {
      key: '/faza1',
      icon: <FileDoneOutlined />,
      label: 'Faza Aktive',
      roles: [admin, anetarShm],
    },
    {
      key: '/orari',
      icon: <ScheduleOutlined />,
      label: 'Orari',
      roles: [admin, pedagog, specialistFF, student],
    },
    {
      key: '/konkurrime',
      icon: <Apply className="h-5 w-5" />,
      label: 'Konkurrime',
      roles: [konkurrent],
    },
    {
      key: '/rezultateFaza',
      icon: <Apply className="h-5 w-5" />,
      label: 'Rezultate faza',
      roles: [konkurrent],
    },
    {
      key: '/program-tematik',
      icon: <BookOutlined className="h-5 w-5" />,
      label: 'Program Tematik',
      roles: [pjesmarres],
    },
    {
      key: '/propozo-trajnim',
      icon: <DiffOutlined className="h-5 w-5" />,
      label: 'Propozo Trajnim',
      roles: [pjesmarres],
    },
    {
      key: '/kalendari',
      icon: <CalendarOutlined className="h-5 w-5" />,
      label: 'Kalendari',
      roles: [pjesmarres],
    },

    {
      key: '/roli-trajnues',
      icon: <BookOutlined className="h-5 w-5" />,
      label: 'Ekspert/Lehtësues',
      roles: [pjesmarres],
    },
    {
      key: '/listeprezenca-per-pjesemarres',
      icon: <FileProtectOutlined className="h-5 w-5" />,
      label: 'Listëprezenca',
      roles: [pjesmarres],
    },
    {
      key: '/verifikimKonkurrime',
      icon: <Apply className="h-5 w-5" />,
      label: 'Konkurrime',
      roles: [...allAnetarKomisioni, admin],
    },
    {
      key: '*',
      icon: <Apply className="h-5 w-5" />,
      label: 'Trajnime',
      children: [
        {
          key: '/trajnime-program-tematik',
          label: 'Program Tematik',
          icon: <MdOutlineTopic />,
        },
        {
          key: '/propozime',
          label: 'Propozime',
          icon: <RiNewspaperLine />,
        },
        {
          key: '/trajnime-kalendari',
          label: 'Kalendari',
          icon: <CalendarOutlined />,
        },
        {
          key: '/liste-prezenca-trajnimet',
          label: 'Listë Prezenca',
          icon: <BsListCheck className="h-5 w-5" />,
        },
      ],
      roles: [specialistFv],
    },
    {
      key: '/rankime',
      icon: <FaSortAmountDown className="h-5 w-5" />,
      label: 'Rankime',
      roles: [anetarShm, admin],
    },
    {
      key: '/ndryshoFjalekalimin',
      label: 'Ndrysho Fjalekalimin',
      roles: [],
      icon: <RiLockPasswordFill className="h-5 w-5" />,
    },
    {
      key: '/modulet',
      icon: <MdViewModule className="h-5 w-5" />,
      label: 'Zgjidh Modulin',
      roles: [],
    },
    {
      key: '/list-prezenca',
      icon: <BsListCheck className="h-5 w-5" />,
      label: 'Listë prezenca',
      roles: [...allFormimiFillestar, student],
    },
    {
      key: '/evidenca',
      icon: <BsListCheck className="h-5 w-5" />,
      label: 'Evidenca',
      roles: [pedagog, specialistFF],
    },
    {
      key: '/vleresime',
      icon: <MdAssessment className="h-5 w-5" />,
      label: 'Vlerësime',
      roles: [pedagog, admin, specialistFF],
      children: [
        ...(role !== admin
          ? [
              {
                key: '/vleresime-lende-mesimore',
                icon: <Subject className="h-5 w-5" />,
                label: 'Lëndë Mësimore',
              },
            ]
          : []),
        ...(role !== specialistFF
          ? [
              {
                key: '/vleresime-gjyqe-imituese',
                icon: <GoLaw className="h-5 w-5" />,
                label: 'Gjyqe imituese',
              },
            ]
          : []),
        {
          key: '/vleresime-klinike-ligjore',
          icon: <VscLaw className="h-5 w-5" />,
          label: 'Klinikë ligjore',
        },
      ],
    },
    {
      key: '/audit',
      icon: <AiOutlineAudit className="h-5 w-5" />,
      label: 'Audit',
      roles: [admin],
    },
    {
      key: '/raporte',
      icon: <TbReportSearch className="h-5 w-5" />,
      label: 'Raporte',
      roles: [pedagog, specialistFF],
    },
  ];
};
