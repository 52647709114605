import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AcademicYear,
  CompetitionAcademicYear,
  CompetitionAcademicYearProps,
  CompetitionYear,
  CompetitionYearCreate,
  CompetitionYearProps,
} from '../../../interfaces/CompetitionYear';
import {
  getCompetitionYearUrl,
  getCompetitionAcademicYearUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-competition-year-url';
import { PaginationReturn } from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';
import { UsersParams } from '../auth/authApi';
import { getAcademicApiUrl } from '../../../components/common/common-functions/commonApiUrl/get-academic-year';
const { test } = getConfig();

export const vitiAkademikApi = createApi({
  reducerPath: 'viti-akademik',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: [
    'AcademicYear',
    'GetAllAcademicYears',
    'CompetitionYear',
    'GetAllCompetitionYears',
  ],
  endpoints: builder => ({
    getAllCompetitionYears: builder.query<
      PaginationReturn<CompetitionYear>,
      CompetitionYearProps
    >({
      query: args => ({
        method: 'GET',
        url: getCompetitionYearUrl(args),
      }),
      providesTags: ['GetAllCompetitionYears'],
    }),
    getAllCompetitionAcademicYears: builder.query<
      CompetitionAcademicYear[],
      CompetitionAcademicYearProps
    >({
      query: args => ({
        method: 'GET',
        url: getCompetitionAcademicYearUrl(args),
      }),
      providesTags: ['GetAllCompetitionYears'],
    }),
    createCompetitionYear: builder.mutation<
      { id: number },
      CompetitionYearCreate
    >({
      query: data => ({
        url: `/viti-konkurrimit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllCompetitionYears'],
    }),
    getSingleCompetitionYear: builder.query<CompetitionYear, number>({
      query: id => ({
        url: `/viti-konkurrimit/${id}`,
        method: 'GET',
      }),
      providesTags: ['CompetitionYear'],
    }),
    updateCompetitionYear: builder.mutation<{ id: number }, CompetitionYear>({
      query: args => ({
        url: `/viti-konkurrimit/${args.id}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: ['GetAllCompetitionYears', 'CompetitionYear'],
    }),
    deleteCompetitionYear: builder.mutation<number, number>({
      query: id => ({
        url: `/viti-konkurrimit/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllCompetitionYears', 'CompetitionYear'],
    }),
    getAllAcademicYear: builder.query<
      PaginationReturn<AcademicYear>,
      Partial<UsersParams>
    >({
      query: args => ({
        url: getAcademicApiUrl(args),
        method: 'GET',
      }),
      providesTags: ['GetAllAcademicYears'],
    }),
    createAcademicYear: builder.mutation<void, AcademicYear>({
      query: data => ({
        url: `/viti-akademik`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'GetAllAcademicYears',
        'AcademicYear',
        'GetAllCompetitionYears',
      ],
    }),
    updateAcademicYear: builder.mutation<{ id: number }, AcademicYear>({
      query: args => ({
        url: `/viti-akademik/${args.id}`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: [
        'GetAllAcademicYears',
        'AcademicYear',
        'GetAllCompetitionYears',
      ],
    }),
    deleteAcademicYear: builder.mutation<number, number>({
      query: id => ({
        url: `/viti-akademik/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetAllAcademicYears',
        'AcademicYear',
        'GetAllCompetitionYears',
      ],
    }),
    mbyllVitAkademik: builder.mutation<string, number>({
      query: id => ({
        url: `/viti-akademik/${id}/perfundo`,
        method: 'PUT',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: [
        'AcademicYear',
        'GetAllAcademicYears',
        'CompetitionYear',
        'GetAllCompetitionYears',
      ],
    }),
  }),
});

export const {
  useCreateAcademicYearMutation,
  useUpdateAcademicYearMutation,
  useDeleteAcademicYearMutation,
  useGetAllCompetitionYearsQuery,
  useLazyGetAllCompetitionYearsQuery,
  useGetAllCompetitionAcademicYearsQuery,
  useLazyGetAllAcademicYearQuery,
  useGetAllAcademicYearQuery,
  useCreateCompetitionYearMutation,
  useUpdateCompetitionYearMutation,
  useLazyGetSingleCompetitionYearQuery,
  useDeleteCompetitionYearMutation,
  useMbyllVitAkademikMutation,
} = vitiAkademikApi;
