import { AuditUrlType } from '../../../../interfaces/audit-interface';

export const getAuditUrl = (params: AuditUrlType) => {
  const { page, size, actionName, actionType, roli, from, to } = params;
  let queryString = `/audit?size=${size}&page=${page}`;

  if (actionName) {
    queryString += `&filter=actionName:eq:${actionName}`;
  }
  if (actionType) {
    queryString += `&filter=actionType:eq:${actionType}`;
  }
  if (roli) {
    queryString += `&filter=roli:eq:${roli}`;
  }
  if (from) {
    queryString += `&filter=data:gte:${from}`;
  }
  if (to) {
    queryString += `&filter=data:lte:${to}`;
  }

  return queryString;
};
