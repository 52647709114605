import getConfig from '../../../helpers/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SubjectData } from '../../../interfaces/profili-interface';
import {
  PaginationProps,
  PaginationReturn,
} from '../../../interfaces/pagination';
import { getLendaApiUrl } from '../../../components/common/common-functions/commonApiUrl/get-lenda-url';
import { getPrepareHeaders } from '../get-prepare-headers';
import { LlojiLenda } from '../../../constants/enums/lloji-lenda';
import { VitiStudimit } from '../../../constants/enums/vitiStudimit';
import { UpdateProps } from '../../../interfaces/common-interfaces';

const { test } = getConfig();

export interface LendaApiProps extends PaginationProps {
  emri?: string;
  lendeKonkurimi?: boolean;
  sort?: 'asc' | 'desc';
  llojiLenda?: LlojiLenda;
  vitiAkademikId?: number;
  vitiStudimit?: VitiStudimit;
  emriLenda?: string;
  roli?: string;
  pedagogu?: number;
  lendeGjyqKlinikaId?: number;
  lendaAkademikeId?: number;
}

export const lendaApi = createApi({
  reducerPath: 'lenda',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Subject', 'GetAllSubjects'],
  endpoints: builder => ({
    getAllSubjects: builder.query<PaginationReturn<SubjectData>, LendaApiProps>(
      {
        query: getLendaApiUrl,
        providesTags: ['GetAllSubjects'],
      },
    ),
    createSubject: builder.mutation<number, SubjectData>({
      query: data => ({
        url: `/lenda`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GetAllSubjects'],
    }),
    getSingleSubject: builder.query<SubjectData, number>({
      query: id => ({
        url: `/lenda/${id}`,
        method: 'GET',
      }),
      providesTags: ['Subject'],
    }),
    updateSubject: builder.mutation<number, UpdateProps<SubjectData>>({
      query: args => ({
        url: `/lenda/${args.id}`,
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['GetAllSubjects', 'Subject'],
    }),
    deleteSubject: builder.mutation<string, number>({
      query: id => ({
        url: `/lenda/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['GetAllSubjects', 'Subject'],
    }),
  }),
});

export const {
  useGetAllSubjectsQuery,
  useLazyGetAllSubjectsQuery,
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
  useLazyGetSingleSubjectQuery,
  useDeleteSubjectMutation,
} = lendaApi;
