import { ListePrezencaParams } from '../../../../interfaces/liste-prezenca.interface';

export const getListePrezenceUrl = (
  params: ListePrezencaParams,
  url = 'liste-prezenca',
) => {
  const {
    klasa,
    vitiAkademik,
    page,
    size,
    studenti,
    lendaAkademike,
    pedagogu,
    tema,
    lendeGjyqId,
  } = params;
  let queryString = `/${url}?page=${page}&size=${size}`;
  if (vitiAkademik && vitiAkademik > -1) {
    queryString += `&filter=vitiAkademikId:eq:${vitiAkademik}`;
  }
  if (lendaAkademike && lendaAkademike > -1) {
    queryString += `&filter=lendaAkademikeId:eq:${lendaAkademike}`;
  }
  if (lendeGjyqId && lendeGjyqId > -1) {
    queryString += `&filter=lendeGjyqId:eq:${lendeGjyqId}`;
  }
  if (klasa && klasa > -1) {
    queryString += `&filter=klasaId:eq:${klasa}`;
  }
  if (studenti && studenti > -1) {
    queryString += `&filter=studentiId:eq:${studenti}`;
  }
  if (pedagogu && pedagogu > -1) {
    queryString += `&filter=pedagogId:eq:${pedagogu}`;
  }
  if (tema && tema > -1) {
    queryString += `&filter=lendaTemaId:eq:${tema}`;
  }
  return queryString;
};

export const eksportEvidencaUrl = (params: any, url = 'evidenca/eksport') => {
  const { klasa, lendaAkademikeId, pedagogu, lendeGjyqId } = params;
  let queryString = `/${url}?page=0`;

  if (pedagogu && pedagogu > -1) {
    queryString += `&filter=pedagogId:eq:${pedagogu}`;
  }

  if (lendaAkademikeId && lendaAkademikeId > -1) {
    queryString += `&filter=lendaAkademikeId:eq:${lendaAkademikeId}`;
  }

  if (lendeGjyqId && lendeGjyqId > -1) {
    queryString += `&filter=lendeGjyqId:eq:${lendeGjyqId}`;
  }

  if (klasa && klasa > -1) {
    queryString += `&filter=klasaId:eq:${klasa}`;
  }

  return queryString;
};
