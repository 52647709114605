import getConfig from '../../../helpers/config';
import { PaginationReturn } from '../../../interfaces/pagination';
import { getPrepareHeaders } from '../get-prepare-headers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  getAcademicSubjectPerPedagogUrl,
  getAcademikSubjectUrl,
} from '../../../components/common/common-functions/commonApiUrl/get-academik-subject-url';
import {
  CreateLendaAkademikeProps,
  KlasaLendaResponse,
  LendaAkademikeByIdProps,
  LendaAkademikeProps,
  LendaTemaParams,
  LendaTemaResponse,
  LendaTemat,
  LendaTematProps,
  MaterialsPerTopic,
  PedagoguDetailsProps,
  UpdateDetyraPedagog,
  UpdateLendaBody,
  UpdateMaterialPedagog,
  UpdatePedagogPerSubject,
} from '../../../interfaces/lenda-akademike.interface';
import { LendaApiProps } from './lendaApi';
import { UpdateKlasaStudent } from '../../../interfaces/klasa-interface';
import { getTopicUrl } from '../../../components/common/common-functions/commonApiUrl/get-topic-url';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';

const { test } = getConfig();

type Materials = PaginationReturn<MaterialsPerTopic>;
type FormDataType = UpdateKlasaStudent<FormData>;
type AcademicSubject = PaginationReturn<LendaAkademikeProps>;

interface MaterialsProps {
  id: number;
  page: number;
  size: number;
}
export const lendeVitiAkademikApi = createApi({
  reducerPath: 'lendeVitiAkademik',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['allAcademicSubjects'],
  endpoints: builder => ({
    getAllAcademicSubjects: builder.query<AcademicSubject, LendaApiProps>({
      query: args => ({
        url: getAcademikSubjectUrl(args),
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getAcademicSubjectById: builder.query<LendaAkademikeByIdProps, number>({
      query: id => ({
        url: `/lenda-akademike/${id}`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getKlasaLenda: builder.query<KlasaLendaResponse, number>({
      query: id => ({
        url: `/lenda-akademike/${id}/klasaLenda`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getLendaAkademikePerPedagog: builder.query<KlasaLendaResponse, number>({
      query: id => ({
        url: `/lenda-akademike/${id}/per-pedagog`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getKlasaLendaPerPedagog: builder.query<KlasaLendaResponse, number>({
      query: id => ({
        url: `/lenda-akademike/${id}/klasa-per-pedagog`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getTemaPerLende: builder.query<LendaTemaResponse, LendaTemaParams>({
      query: args => ({
        url: getTopicUrl(args, ''),
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getTemaPerLendeStudent: builder.query<LendaTemaResponse, LendaTemaParams>({
      query: args => ({
        url: getTopicUrl(args, '/per-student'),

        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getTemaPerLendeAdmin: builder.query<LendaTemaResponse, LendaTemaParams>({
      query: args => ({
        url: getTopicUrl(args, '/per-lende-akademike'),

        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    addAcademicSubject: builder.mutation<number, CreateLendaAkademikeProps>({
      query: body => ({
        url: '/lenda-akademike',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateAcademicSubject: builder.mutation<
      number,
      UpdateKlasaStudent<UpdateLendaBody>
    >({
      query: ({ body, id }) => ({
        url: `/lenda-akademike/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateTopicsPerSubject: builder.mutation<
      number,
      UpdateKlasaStudent<LendaTematProps[]>
    >({
      query: ({ body, id }) => ({
        url: `/lenda-akademike/${id}/lenda-tema`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateSingleTopic: builder.mutation<number, UpdateKlasaStudent<LendaTemat>>(
      {
        query: ({ body, id }) => ({
          url: `/lenda-tema/${id}`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: ['allAcademicSubjects'],
      },
    ),
    updateSingleTeacher: builder.mutation<
      number,
      UpdateKlasaStudent<PedagoguDetailsProps>
    >({
      query: ({ body, id }) => ({
        url: `/lenda-pedagog/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateTeachersPerSuubject: builder.mutation<
      number,
      UpdateKlasaStudent<UpdatePedagogPerSubject[]>
    >({
      query: ({ body, id }) => ({
        url: `lenda-akademike/${id}/pedagog`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    deleteAcademicSubject: builder.mutation<string, number>({
      query: id => ({
        url: `/lenda-akademike/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    getAcademicSubjectForPedagog: builder.query<AcademicSubject, LendaApiProps>(
      {
        query: args => ({
          url: getAcademicSubjectPerPedagogUrl(args),
          method: 'GET',
        }),
        providesTags: ['allAcademicSubjects'],
      },
    ),
    getAcademicSubjectForStudent: builder.query<AcademicSubject, LendaApiProps>(
      {
        query: args => ({
          url: getAcademicSubjectPerPedagogUrl(args),
          method: 'GET',
        }),
        providesTags: ['allAcademicSubjects'],
      },
    ),
    getDetyraPerStudent: builder.query<Blob, number>({
      query: id => ({
        url: `/detyra/${id}/dokument-per-student`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getDetyraPerPedagog: builder.query<Blob, number>({
      query: id => ({
        url: `/detyra/${id}/dokument-per-pedagog`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    addTopicAssignment: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `/lenda-tema/${id}/detyra`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    addTopicStudent: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `/detyra/${id}/ngarko-zgjidhje`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateTopicStudent: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `/detyra-student/${id}/dokument`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateTopicAssignment: builder.mutation<
      number,
      UpdateKlasaStudent<UpdateDetyraPedagog>
    >({
      query: ({ id, body }) => ({
        url: `/detyra/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateDocument: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `/detyra/${id}/dokument`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    getMaterialsPerTopic: builder.query<Materials, MaterialsProps>({
      query: ({ id, size, page }) => ({
        url: `/lenda-tema/${id}/materiale/per-pedagog?page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    uploadMaterialsPedagog: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `lenda-tema/${id}/materiale`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),

    updateMaterialPerTopic: builder.mutation<
      number,
      UpdateKlasaStudent<UpdateMaterialPedagog>
    >({
      query: ({ id, body }) => ({
        url: `/materiale/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    updateMaterialDocumentPerTopic: builder.mutation<number, FormDataType>({
      query: ({ id, body }) => ({
        url: `/materiale/${id}/dokument`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    exportDocumentPerTopicPedagog: builder.query<Blob, number>({
      query: id => ({
        url: `/materiale/${id}/dokument/per-pedagog`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    exportDocumentPerTopicStudent: builder.query<Blob, number>({
      query: id => ({
        url: `/materiale/${id}/dokument/per-student`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    exportDocumentPerTopicAdmin: builder.query<Blob, number>({
      query: id => ({
        url: `/materiale/${id}/dokument`,
        method: 'GET',
        responseHandler: handleDownloadResponse,
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    deleteMaterialPerTopic: builder.mutation<string, number>({
      query: id => ({
        url: `/materiale/${id}`,
        method: 'DELETE',
        responseHandler: response => response.text(),
      }),
      invalidatesTags: ['allAcademicSubjects'],
    }),
    getMaterialsPerTopicAdmin: builder.query<Materials, MaterialsProps>({
      query: ({ id, size, page }) => ({
        url: `/lenda-tema/${id}/materiale?page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
    getMaterialsPerTopicStudent: builder.query<Materials, MaterialsProps>({
      query: ({ id, size, page }) => ({
        url: `/lenda-tema/${id}/materiale/per-student?page=${page}&size=${size}`,
        method: 'GET',
      }),
      providesTags: ['allAcademicSubjects'],
    }),
  }),
});

export const {
  useGetAllAcademicSubjectsQuery,
  useLazyGetAllAcademicSubjectsQuery,
  useGetAcademicSubjectByIdQuery,
  useLazyGetAcademicSubjectByIdQuery,
  useDeleteAcademicSubjectMutation,
  useUpdateAcademicSubjectMutation,
  useAddAcademicSubjectMutation,
  useGetAcademicSubjectForPedagogQuery,
  useLazyGetAcademicSubjectForPedagogQuery,
  useGetAcademicSubjectForStudentQuery,
  useGetKlasaLendaQuery,
  useGetKlasaLendaPerPedagogQuery,
  useGetTemaPerLendeQuery,
  useLazyGetTemaPerLendeQuery,
  useGetTemaPerLendeStudentQuery,
  useGetTemaPerLendeAdminQuery,
  useLazyGetDetyraPerPedagogQuery,
  useLazyGetDetyraPerStudentQuery,
  useUpdateTopicsPerSubjectMutation,
  useUpdateSingleTopicMutation,
  useUpdateTeachersPerSuubjectMutation,
  useUpdateSingleTeacherMutation,
  useAddTopicAssignmentMutation,
  useAddTopicStudentMutation,
  useUpdateTopicAssignmentMutation,
  useUpdateDocumentMutation,
  useUpdateTopicStudentMutation,
  useUploadMaterialsPedagogMutation,
  useGetMaterialsPerTopicQuery,
  useDeleteMaterialPerTopicMutation,
  useUpdateMaterialDocumentPerTopicMutation,
  useUpdateMaterialPerTopicMutation,
  useLazyExportDocumentPerTopicPedagogQuery,
  useGetMaterialsPerTopicAdminQuery,
  useGetMaterialsPerTopicStudentQuery,
  useLazyExportDocumentPerTopicStudentQuery,
  useLazyExportDocumentPerTopicAdminQuery,
} = lendeVitiAkademikApi;
