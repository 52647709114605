import { Divider } from 'antd';
import React, { ReactNode } from 'react';
import { ReactComponent as Close } from '../../../assets/svgIcons/x-mark.svg';
interface ModalTitleProps {
  title: string;
  icon?: ReactNode;
  onClose?: () => void;
}

export default function ModalTitle({ title, icon, onClose }: ModalTitleProps) {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span>{icon}</span>
          {title}
        </div>
        <button onClick={onClose}>
          <Close className="h-8 w-8 text-gray-400" />
        </button>
      </div>
      <Divider className="mt-2.5 h-px w-full bg-gray-300" />
    </div>
  );
}
