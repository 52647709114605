import React from 'react';
import { Button, Form, TimePicker, Upload } from 'antd';
import { FormField } from '../../../interfaces/FormInterfaces';
import { UploadOutlined } from '@ant-design/icons';

const AntdUpload = (props: FormField) => {
  const beforeUpload = () => {
    return false;
  };

  return (
    <Form.Item name={props.name} rules={props?.rules}>
      <Upload {...props.otherprops} beforeUpload={beforeUpload}>
        <Button icon={<UploadOutlined />}>{props.placeholder}</Button>
      </Upload>
    </Form.Item>
  );
};

export default AntdUpload;
