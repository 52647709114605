import React from 'react';
import { FormField } from '../../interfaces/FormInterfaces';
import InputField from './FormFields/Input';
import InputPassword from './FormFields/InputPassword';
import AntdSelect from './FormFields/AntdSelect';
import { FORM_FIELD } from '../../utilities/enums';
import InputTextArea from './FormFields/InputTextArea';
import InputNumberField from './FormFields/InputNumberField';
import CheckboxField from './FormFields/CheckboxField';
import AntdDatePicker from './FormFields/DatePicker';
import AntdTimePicker from './FormFields/TimePicker';
import AntdUpload from './FormFields/Upload';

const FormFieldRender = ({ data }: { data: FormField }) => {
  const FormComponent = () => {
    switch (data.type) {
      case FORM_FIELD.INPUT:
        return <InputField {...data} />;
      case FORM_FIELD.PASSWORD:
        return <InputPassword {...data} />;
      case FORM_FIELD.ANTD_SELECT:
        return <AntdSelect {...data} />;
      case FORM_FIELD.TEXT_AREA:
        return <InputTextArea {...data} />;
      case FORM_FIELD.INPUT_NUMBER:
        return <InputNumberField {...data} />;
      case FORM_FIELD.CHECKBOX:
        return <CheckboxField {...data} />;
      case FORM_FIELD.DATE:
        return <AntdDatePicker {...data} />;
      case FORM_FIELD.TIME:
        return <AntdTimePicker {...data} />;
      case FORM_FIELD.UPLOAD:
        return <AntdUpload {...data} />;
      default:
        break;
    }
  };
  return FormComponent() as JSX.Element;
};

export default FormFieldRender;
