import { StatusiKonkurrent } from '../../../constants/enums/statusi-konkurimit';
import { FormField } from '../../../interfaces/FormInterfaces';
const {
  refuzuar,
  kerkesePerDokument,
  regjistruar,
  neVerifikim,
  new: statusNew,
  faza1,
  faza2,
  aplikant,
} = StatusiKonkurrent;

export const userFormFields: FormField[] = [
  {
    name: ['user', 'name'],
    label: 'Name',
    rules: [{ required: true }],
  },
  {
    name: ['user', 'email'],
    label: 'Email',
    rules: [{ type: 'email' }],
  },
  {
    name: ['user', 'age'],
    label: 'Age',
    rules: [{ type: 'number', min: 0, max: 99 }],
  },
  { name: ['user', 'website'], label: 'Website' },
  { name: ['user', 'introduction'], label: 'Introduction' },
];

export const cardItems = [
  {
    label: 'Prano',
    key: regjistruar,
  },
  {
    label: 'Refuzo',
    key: refuzuar,
  },
  {
    label: 'Kerkese per dokument',
    key: kerkesePerDokument,
  },
];

export const aplikimiItems: FormField[] = [
  { label: 'Mesatarja e përgjithshme', name: 'allSubjectsAverage' },
  { label: 'Mesatarja e lëndëve kryesore', name: 'mainSubjectsAverage' },
  { label: 'Data e diplomimit', name: 'graduationDate' },
  { label: 'Numri i dosjes', name: 'fileNumber' },
  { label: 'Kodi Skantron', name: 'scantronCode' },
  { label: 'Vjetërsia në punë vite', name: 'yearsOfWorkExperience' },
  { label: 'Vjetërsia në punë muaj', name: 'monthsOfWorkExperience' },
  { label: 'Eksperienca në punë', name: 'workExperience' },
];

export const userItems = [
  {
    label: 'Aktiv',
    key: '1',
  },
  {
    label: 'Jo aktiv',
    key: '2',
  },
];

export const universityItems = [
  {
    label: 'Po',
    key: '1',
  },
  {
    label: 'Jo',
    key: '2',
  },
];
export const replaceUnderscore = (value?: string) => value?.replace(/_/g, ' ');
export const statusItems = [
  {
    label: statusNew,
    key: statusNew,
  },
  {
    label: replaceUnderscore(neVerifikim),
    key: neVerifikim,
  },
  {
    label: aplikant,
    key: aplikant,
  },
  {
    label: refuzuar,
    key: refuzuar,
  },
  {
    label: regjistruar,
    key: regjistruar,
  },
  {
    label: replaceUnderscore(faza1),
    key: faza1,
  },
  {
    label: replaceUnderscore(faza2),
    key: faza2,
  },
];

export const activityStatusOptions = [
  {
    label: 'Vit Konkurrimi',
    key: 'VITI_KONKURRIMIT',
  },
  {
    label: 'Vit akademik',
    key: 'VITI_AKADEMIK',
  },
];

export const formItemStyle = { width: '220px', height: '32px' };

export const lendeKonkurrimiItems = [
  {
    label: 'Lende konkurrimi',
    key: '1',
  },
  {
    label: 'Lende jo konkurrimi',
    key: '2',
  },
];
