import {
  CompetitionYearProps,
  CompetitionAcademicYearProps,
} from '../../../../interfaces/CompetitionYear';

export const getCompetitionYearUrl = (args: CompetitionYearProps) => {
  const { vitiFillimit, vitiMbarimit, aktiv, page, size } = args;

  let url = `/viti-konkurrimit?size=${size}&page=${page}`;

  if (vitiFillimit) {
    url += `&filter=vitiFillimit:eq:${vitiFillimit}`;
  }

  if (vitiMbarimit) {
    url += `&filter=vitiMbarimit:eq:${vitiMbarimit}`;
  }

  if (aktiv !== undefined) {
    url += `&filter=aktiv:eq:${aktiv}`;
  }

  return url;
};
export const getCompetitionAcademicYearUrl = (
  args: CompetitionAcademicYearProps,
) => {
  const { vitiFillimit, vitiMbarimit, aktiv, vitKonkurrim } = args;
  let url = '/viti-konkurrimit/te-gjitha-vitet-akademike-konkurrimi';
  if (vitiFillimit) {
    url += `?filter=vitiFillimit:eq:${vitiFillimit}`;
  }

  if (vitiMbarimit) {
    url += `${vitiFillimit ? '&' : '?'}filter=vitiMbarimit:eq:${vitiMbarimit}`;
  }

  if (aktiv !== undefined) {
    const separator = vitiFillimit || vitiMbarimit ? '&' : '?';
    url += `${separator}filter=aktiv:eq:${aktiv}`;
  }
  if (vitKonkurrim !== undefined) {
    const separator =
      vitiFillimit || vitiMbarimit || aktiv !== undefined ? '&' : '?';
    url += `${separator}tipi=${vitKonkurrim}`;
  }
  return url;
};
