import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getConfig from '../../../helpers/config';
import { getPrepareHeaders } from '../get-prepare-headers';
import { handleDownloadResponse } from '../../../components/common/common-functions/handle-response';
const { test } = getConfig();

export interface TrainingMaterialProps {
  file: string;
  llojDokumentTrajnimi: string;
}

export interface TrainingMaterialsProps {
  idDokumentTrajnimi: number;
  llojiDokumentTrajnimi: string;
  perdorues: {
    id: number;
    emri: string;
    mbiemri: string;
  };
  dokument: {
    id: number;
    emri: string;
    kohaUpload: string | Date;
  };
}

export const dokumentTrajnimiApi = createApi({
  reducerPath: 'dokumentTrajnimiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: test.baseUrl + `/`,
    prepareHeaders: getPrepareHeaders,
  }),
  tagTypes: ['Dokument'],

  endpoints: builder => ({
    getTrainingMaterial: builder.query<Blob, { id: number }>({
      query: arg => {
        return {
          url: `/dokument-trajnimi/${arg.id}`,
          method: 'GET',
          responseHandler: handleDownloadResponse,
          invalidatesTags: ['Dokument'],
        };
      },
    }),
    uploadTrainingMaterial: builder.mutation<number, unknown>({
      query: ({ id, ...body }) => {
        return {
          url: `/dokument-trajnimi/${id}`,
          method: 'POST',
          body: body.body,
        };
      },

      invalidatesTags: ['Dokument'],
    }),
    uploadTrainingMaterialForTraining: builder.mutation<number, unknown>({
      query: ({ id, ...body }) => {
        return {
          url: `/dokument-trajnimi/${id}/per-trajnues`,
          method: 'POST',
          body: body.body,
        };
      },

      invalidatesTags: ['Dokument'],
    }),
    deleteTrainingMaterial: builder.mutation<void, number>({
      query: id => ({
        url: `dokument-trajnimi/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Dokument'],
    }),
    deleteTrainingMaterialForTrainee: builder.mutation<void, number>({
      query: id => ({
        url: `dokument-trajnimi/${id}/per-trajnues`,
        method: 'DELETE',
      }),
    }),
    getTrainingDocumentById: builder.query<
      TrainingMaterialsProps[],
      { id: number }
    >({
      query: args => {
        return {
          url: `/trajnimi/${args.id}/dokument`,
          method: 'GET',
        };
      },
      providesTags: ['Dokument'],
    }),
  }),
});

export const {
  useLazyGetTrainingMaterialQuery,
  useUploadTrainingMaterialMutation,
  useUploadTrainingMaterialForTrainingMutation,
  useDeleteTrainingMaterialMutation,
  useGetTrainingDocumentByIdQuery,
  useDeleteTrainingMaterialForTraineeMutation,
} = dokumentTrajnimiApi;
